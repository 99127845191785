import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const AboutMeItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const AboutMeData = styled.p`
  font-size: var(--font-size-small);
  font-weight: 300;
  color: var(--text-color);
  text-align: left;
`;
