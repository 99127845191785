import { progamersApi } from "@config/axios.config";
import { BaseResponse } from "@interfaces/base";
import {
  ICreateTeamRequest,
  IInviteMemberRequest,
  ITeam,
  ITeamMembers,
  ITeamNotificationUser,
  IUpdateTeamRequest,
} from "@interfaces/team.interface";

export default function teamService() {
  const postCreate = async (data: ICreateTeamRequest): Promise<ITeam> => {
    const result = await progamersApi.post("/teams", data);
    return result.data;
  };

  const putUpdate = async (
    teamId: string,
    data: IUpdateTeamRequest
  ): Promise<ITeam> => {
    const result = await progamersApi.put(`/teams/${teamId}`, data);
    return result.data;
  };

  const getProfileTeam = async (
    teamId: string
  ): Promise<BaseResponse<ITeam>> => {
    const result = await progamersApi.get(`/teams/${teamId}`);
    return result.data;
  };

  const postSendInvite = async (teamId: string, data: IInviteMemberRequest) => {
    await progamersApi.post(`/teams/${teamId}/invite`, data);
  };

  const getInviteNotificationsUser = async (): Promise<
    ITeamNotificationUser[]
  > => {
    const result = await progamersApi.get("/teams/invitations/user");
    return result.data;
  };

  const patchAcceptionInvitation = async (
    teamId: string,
    invitationId: string
  ) => {
    await progamersApi.patch(
      `/teams/${teamId}/invitations/${invitationId}/accept`
    );
  };

  const patchDeclineInvitation = async (
    teamId: string,
    invitationId: string
  ) => {
    await progamersApi.patch(
      `/teams/${teamId}/invitations/${invitationId}/decline`
    );
  };

  const getTeamMembers = async (
    teamId: string
  ): Promise<BaseResponse<ITeamMembers>[]> => {
    const { data } = await progamersApi.get(`teams/${teamId}/members`);
    return data;
  };

  const getTeamByIdPublic = async (
    teamId: string
  ): Promise<BaseResponse<ITeam>> => {
    const result = await progamersApi.get(`public/teams/${teamId}`);
    return result.data;
  };

  const getTeamMembersByIdPublic = async (
    teamId: string
  ): Promise<BaseResponse<ITeamMembers>[]> => {
    const { data } = await progamersApi.get(`public/teams/${teamId}/members`);
    return data;
  };

  return {
    postCreate,
    getProfileTeam,
    postSendInvite,
    getInviteNotificationsUser,
    patchAcceptionInvitation,
    patchDeclineInvitation,
    getTeamMembers,
    getTeamByIdPublic,
    getTeamMembersByIdPublic,
    putUpdate,
  };
}
