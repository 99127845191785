import { Genders } from "@interfaces/user.interface";
import * as yup from "yup";

export const UserUpdateSchema = yup
  .object({
    name: yup.string().required("Campo obrigatório"),
    nick: yup.string().required("Campo obrigatório"),
    phone: yup.string().required("Campo obrigatório"),
    gender: yup.mixed<Genders>().oneOf(Object.values(Genders)).required(),
    cpf: yup.string().optional().required("Campo obrigatório"),
  })
  .required();
