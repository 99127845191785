import { User2 } from "lucide-react";
import * as S from "./styles";
import {
  ITeamNotificationUser,
  TeamNotificationUserStatus,
} from "@interfaces/team.interface";
import useTeam from "@hooks/useTeam";

type Props = {
  onMouseLeave: any;
  notificationList: ITeamNotificationUser[] | undefined;
};

const NotificationArea: React.FC<Props> = ({
  onMouseLeave,
  notificationList,
}) => {
  const { acceptionInvitation, declineInvitation } = useTeam();

  return (
    <S.DropdownContainerWrapper onMouseLeave={onMouseLeave}>
      <S.DropdownHeader>
        <S.DropdownTitle>Notificações</S.DropdownTitle>
      </S.DropdownHeader>
      {notificationList
        ?.filter((item) => item.status === TeamNotificationUserStatus.PENDING)
        .map((item) => (
          <S.DropdownItemContainer>
            {item.status === TeamNotificationUserStatus.PENDING ? (
              <S.PrimaryNotification>
                <S.UserAreaContainer>
                  <S.UserIcon>
                    <User2 className="user_icon" />
                  </S.UserIcon>
                  <S.UserTextContainer>
                    <S.UserName>{item.userWhoInvited.nick}</S.UserName>
                    <S.UserTextDescription>
                      Está lhe convidando para entrar no time {item.team.name}!
                    </S.UserTextDescription>
                  </S.UserTextContainer>
                </S.UserAreaContainer>
                <S.ButtonsContainer>
                  <S.ButtonAccept
                    onClick={() => acceptionInvitation(item.team_id, item.id)}
                  >
                    Aceitar
                  </S.ButtonAccept>
                  <S.ButtonDeny
                    onClick={() => declineInvitation(item.team_id, item.id)}
                  >
                    Negar
                  </S.ButtonDeny>
                </S.ButtonsContainer>
              </S.PrimaryNotification>
            ) : (
              <S.SecundaryNotification>
                <S.UserAreaContainer>
                  <S.UserIcon>
                    <User2 className="user_icon" />
                  </S.UserIcon>
                  <S.UserTextContainer>
                    <S.UserName>{item.role}</S.UserName>
                    <S.UserTextDescription>
                      Acabou de entrar no time $team_name
                    </S.UserTextDescription>
                  </S.UserTextContainer>
                </S.UserAreaContainer>
              </S.SecundaryNotification>
            )}
          </S.DropdownItemContainer>
        ))}
      {/* <S.DropdownItemContainer>

      </S.DropdownItemContainer> */}
    </S.DropdownContainerWrapper>
  );
};

export default NotificationArea;
