import Modal from "react-modal";
import { FileText, X } from "lucide-react";
import {
  StylesModal,
  ModalContainerWrapper,
  ModalHeaderContainer,
  ModalTitleContainer,
  ModalTitle,
  ModalSubTitle,
  ModalIconCloseContainer,
  ModalContainerFooter,
  ModalContainerButton,
  ModalButtonDisagree,
  ModalButtonAccept,
  ContainerBody,
} from "./styles";
import TermoUso from "./TermsUse";
import PoliticaPrivacidade from "./PrivacyPolicyTerms";

interface ModalTermsOfUseProps {
  isOpen: boolean;
  onRequestClose: () => void;
  isCheckedTrue: () => void;
  isCheckedFalse: () => void;
  type: "PrivacyPolicy" | "TermsUse";
}

export function ModalTermsOfUse({
  isOpen,
  onRequestClose,
  isCheckedTrue,
  isCheckedFalse,
  type,
}: ModalTermsOfUseProps) {
  const handleAcceptClickAccept = () => {
    isCheckedTrue();
    onRequestClose();
  };

  const handleAcceptClickDisagree = () => {
    isCheckedFalse();
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={StylesModal}
      contentLabel="Exemplo de Modal"
    >
      <ModalContainerWrapper>
        <ModalHeaderContainer>
          <FileText size={40} color="#e9445f" />
          <ModalTitleContainer>
            <ModalTitle>
              {type === "TermsUse" && "Termos de uso"}
              {type === "PrivacyPolicy" && "Política de Privacidade"}
            </ModalTitle>
          </ModalTitleContainer>
          <ModalIconCloseContainer>
            <X
              onClick={onRequestClose}
              className="modal-icon-close"
              size={22}
            />
          </ModalIconCloseContainer>
        </ModalHeaderContainer>
        <ContainerBody>
          {type === "TermsUse" && <TermoUso />}
          {type === "PrivacyPolicy" && <PoliticaPrivacidade />}
        </ContainerBody>
        <ModalContainerFooter>
          <ModalContainerButton>
            <ModalButtonDisagree onClick={handleAcceptClickDisagree}>
              Discordo
            </ModalButtonDisagree>
            <ModalButtonAccept onClick={handleAcceptClickAccept}>
              Aceito
            </ModalButtonAccept>
          </ModalContainerButton>
        </ModalContainerFooter>
      </ModalContainerWrapper>
    </Modal>
  );
}
