import styled from "styled-components";
import Carousel from "react-multi-carousel";
import { device } from "@utils/index";

export const ProfileWrapper = styled.div`
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
  flex-direction: column;

  @media (max-width: 1060px) {
    flex-direction: column-reverse;
  }
`;

export const Title = styled.p`
  display: flex;
  align-items: center;

  gap: 0.5rem;
  margin-bottom: 0.8rem;

  color: var(--text-color);
  font-size: var(--font-size-large);
  font-weight: 700;

  .titleIcon {
    cursor: pointer;
    color: var(--layout-color);
  }
  @media (max-width: 768px) {
    font-size: 16px;
    width: 150px;
    margin-left: 460px;
  }
  @media (max-width: 425px) {
    font-size: 16px;
    width: 150px;
    margin-left: 100px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
    width: 150px;
    margin-left: 120px;
  }
  @media (max-width: 320px) {
    font-size: 16px;
    width: 150px;
    margin-left: 135px;
  }
`;

export const TitleCampeonato = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  gap: 0.5rem;
  margin-bottom: 0.8rem;

  color: var(--text-color);
  font-size: var(--font-size-large);
  font-weight: 700;

  @media (max-width: 680px) {
    justify-content: center;
  }
`;

export const ContainerButtons = styled.div`
  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 45px;
      height: 30px;

      border: 1px solid #eaecee;
      border-radius: 7px;
      background: transparent;
      transition: all ease-in-out 0.3s;

      &:hover {
        background-color: #eaecee;
        transition: all ease-in-out 0.3s;

        > svg path {
          color: #000;
          transition: all ease-in-out 0.3s;
        }
      }

      .buttonsArrowIcons {
        color: #eaecee;
      }
    }
  }
`;

export const StatisticsWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  width: 100%;
  justify-content: space-between;

  border-radius: 7px;
  margin-bottom: 2rem;
  padding: 1.5rem;

  opacity: 1;
  background: #0c1a27 0% 0% no-repeat padding-box;

  @media ${device.tablet} {
    flex-direction: column;
    text-align: center;
  }
`;

export const StatisticsItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .statisticsItemIcon {
    color: var(--layout-color);
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    text-align: center;
  }

  @media (max-width: 1060px) {
    flex-direction: row;
    text-align: start;
  }
`;

export const StatisticsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatisticsText = styled.p`
  color: var(--text-color);
  font-size: var(--font-size-large);
  font-weight: 500;

  @media ${device.desktopSmall} {
  }
`;

export const StatisticsDescription = styled.p`
  color: var(--text-color);
  font-size: var(--font-size-base);
  font-weight: 300;

  @media ${device.desktopSmall} {
  }
`;

export const CardCarouselWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

export const ContainerCarousel = styled(Carousel)`
  .react-multi-carousel-track {
    display: flex;
    gap: 20px;
  }
`;

export const VideosWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: 1320px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1060px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;

export const NewVideoLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 120px;
  border: 3px dotted var(--layout-color);
  border-radius: 8px;

  color: var(--layout-color);
  text-decoration: none;
  font-size: var(--font-size-large);
`;

export const AboutMeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  min-width: 350px;
  width: auto;

  border-radius: 1rem;

  margin-bottom: 2rem;
  padding: 1rem 2rem;
  background: #0c1a27 0% 0% no-repeat padding-box;

  @media (max-width: 1060px) {
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    min-width: auto;
    padding: 1rem 1rem;
    margin-left: 25px;
  }

  @media (max-width: 450px) {
    padding: 1rem 1rem;
    margin-left: 20px;
  }

  @media (max-width: 375px) {
    padding: 1rem 1rem;
    margin-left: 20px;
  }

  @media (max-width: 320px) {
    padding: 1rem 1rem;
    margin-left: 20px;
  }
`;
