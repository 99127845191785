import { device } from "@utils/index";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const ContainerUserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  > svg {
    cursor: pointer;
  }
`;

export const UserArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media ${device.tablet} {
    margin-right: 1rem;
  }
`;
export const UserIcon = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: var(--box-shadow-default);
  color: var(--text-color);
  background-color: var(--layout-color);
`;

export const UserName = styled.p`
  font-size: 15px;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ContainerButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ContainerLinkAssinar = styled(Link)`
  font-size: 16px;
  background: var(--layout-color);
  color: var(--text-color);
  opacity: 1;
  text-align: center;
  border: 1px solid var(--layout-color);
  padding: 0.5rem 1.5rem;
  cursor: pointer;

  box-shadow: 0rem 0rem 1rem var(--layout-color);
  border-radius: 4px;

  @media ${device.tablet} {
    font-size: 12px;
    padding: 0.25rem 1.25rem;
  }
`;

export const ContainerLinkEntrar = styled.a`
  border-radius: 4px;
  border: 1px solid var(--layout-color);
  padding: 0.5rem 1.5rem;

  opacity: 1;
  transition: 0.2s ease-in;
  color: #fff;
  background-color: var(--layout-color);

  font-size: 16px;

  &:hover {
    box-shadow: var(--box-shadow-default);
    transition: 0.2s ease-in;
    color: var(--text-color);
    background: var(--layout-color);
  }

  @media ${device.tablet} {
    font-size: 12px;
    padding: 0.25rem 1.25rem;
  }
`;

export const ContainerMobileMenuWrapper = styled.div`
  display: none;

  button {
    display: flex;
    align-items: center;
    border: none;

    height: 32px;
    background: transparent;

    > svg {
      width: 34px;
      height: 34px;
      color: var(--layout-color);
    }
  }

  @media ${device.tablet} {
    display: flex;
  }
`;
