import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import DesktopTab from "./tabs/desktop";
import ConsoleTab from "./tabs/console";
import MobileTab from "./tabs/mobile";
import NotebookTab from "./tabs/notebook";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { optionsFetchSetupUserByIdPublic } from "@plugins/reactQuery/user.query-options";
import { useParams } from "react-router-dom";
import {
  IRequestSetup,
  UserEquipmentTypes,
  UserSetupForm,
} from "@interfaces/user.interface";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import userService from "@services/user.service";
import { toast } from "react-toastify";
import Tablet from "./tabs/tablet";

const SetupSettings: React.FC = () => {
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const [tabIndex, setTabIndex] = useState(0);
  const { postSetupUser, putSetupUser } = userService();
  const { data } = useQuery(optionsFetchSetupUserByIdPublic(userId!));

  const mapAba = (index: number) => {
    return (
      {
        0: UserEquipmentTypes.COMPUTER,
        1: UserEquipmentTypes.CONSOLE,
        2: UserEquipmentTypes.NOTEBOOK,
        3: UserEquipmentTypes.SMARTPHONE,
        4: UserEquipmentTypes.TABLET,
      }[index] || UserEquipmentTypes.COMPUTER
    );
  };

  const setupForm: UserSetupForm = {
    CONSOLE: data?.find(
      (item) => item.entity.type === UserEquipmentTypes.CONSOLE
    ),
    COMPUTER: data?.find(
      (item) => item.entity.type === UserEquipmentTypes.COMPUTER
    ),
    NOTEBOOK: data?.find(
      (item) => item.entity.type === UserEquipmentTypes.NOTEBOOK
    ),
    SMARTPHONE: data?.find(
      (item) => item.entity.type === UserEquipmentTypes.SMARTPHONE
    ),
    TABLET: data?.find(
      (item) => item.entity.type === UserEquipmentTypes.TABLET
    ),
  };

  const method = useForm<UserSetupForm>({
    values: {
      CONSOLE: setupForm.CONSOLE,
      COMPUTER: setupForm.COMPUTER,
      NOTEBOOK: setupForm.NOTEBOOK,
      SMARTPHONE: setupForm.SMARTPHONE,
      TABLET: setupForm.TABLET,
    },
  });

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const setupRequest: SubmitHandler<UserSetupForm> = async (values, event) => {
    event?.preventDefault();
    const data: IRequestSetup = {
      ...values[mapAba(tabIndex)]?.entity,
      type: mapAba(tabIndex),
    };

    const isUpdate = data?.id ? true : false;

    if (isUpdate) {
      await putSetupUser(userId!, data?.id!, data!);
    } else {
      await postSetupUser(userId!, data!);
    }

    toast.success("Dados Ataulizados com Sucesso");
    queryClient.invalidateQueries(optionsFetchSetupUserByIdPublic(userId!));
  };

  const handlerSubmitForm = method.handleSubmit(setupRequest);

  return (
    <FormProvider {...method}>
      <Tabs index={tabIndex} onChange={handleTabsChange} variant="unstyled">
        <TabList
          display={"flex"}
          flexDir={"row"}
          gap={"8px"}
          flexWrap={"wrap"}
          justifyContent={{ base: "center", lg: "flex-start" }}
          w={"100%"}
        >
          <Tab
            bgColor={"#293A5E"}
            color={"#ffffff"}
            _selected={{ borderBottom: "3px solid #E94560" }}
            flex={{ base: "1", lg: "none" }}
            w={{ base: "auto", lg: "160px" }}
          >
            Desktop
          </Tab>
          <Tab
            bgColor={"#293A5E"}
            color={"#ffffff"}
            _selected={{ borderBottom: "3px solid #E94560" }}
            flex={{ base: "1", lg: "none" }}
            w={{ base: "auto", lg: "160px" }}
          >
            Console
          </Tab>
          <Tab
            bgColor={"#293A5E"}
            color={"#ffffff"}
            _selected={{ borderBottom: "3px solid #E94560" }}
            flex={{ base: "1", lg: "none" }}
            w={{ base: "auto", lg: "160px" }}
          >
            Notebook
          </Tab>
          <Tab
            bgColor={"#293A5E"}
            color={"#ffffff"}
            _selected={{ borderBottom: "3px solid #E94560" }}
            flex={{ base: "1", lg: "none" }}
            w={{ base: "auto", lg: "160px" }}
          >
            Mobile
          </Tab>
          <Tab
            bgColor={"#293A5E"}
            color={"#ffffff"}
            _selected={{ borderBottom: "3px solid #E94560" }}
            flex={{ base: "1", lg: "none" }}
            w={{ base: "auto", lg: "160px" }}
          >
            Tablete
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <DesktopTab handlerSubmit={handlerSubmitForm} />
          </TabPanel>
          <TabPanel>
            <ConsoleTab handlerSubmit={handlerSubmitForm} />
          </TabPanel>
          <TabPanel>
            <NotebookTab handlerSubmit={handlerSubmitForm} />
          </TabPanel>
          <TabPanel>
            <MobileTab handlerSubmit={handlerSubmitForm} />
          </TabPanel>
          <TabPanel>
            <Tablet handlerSubmit={handlerSubmitForm} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FormProvider>
  );
};

export default SetupSettings;
