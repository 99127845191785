import * as yup from "yup";

export const RegistrationTeamSchema = yup
  .object({
    name: yup.string().required("Nome obrigatório"),
    teamTag: yup.string().required("Tag obrigatória"),
    game_id: yup.string().required("jogo obrigatório"),
    description: yup.string().required("Descrição obrigatória"),
  })
  .required();

export const UpdateTeamSchema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required("Nome obrigatório"),
    teamTag: yup.string().required("Tag obrigatória"),
    description: yup.string().required("Descrição obrigatória"),
  })
  .required();
