import { device } from "@utils/index";
import styled from "styled-components";

export const Title = styled.h2`
  margin-bottom: 2rem;
  color: var(--layout-color);

  font-size: 30px;
  font-weight: var(--font-semi-bold);
  text-align: left;

  @media ${device.tablet} {
    margin-bottom: 1.5rem;
    font-size: var(--font-size-xlarge);
  }
  @media ${device.mobileLarge} {
    margin-bottom: 1rem;
    font-size: var(--font-size-large);
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 0;
  }

  @media ${device.mobileLarge} {
    justify-content: center;
    align-items: center;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 70%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const RegisterForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;

  .ButtonCenter {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  .mobileAvatarInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    max-width: 250px;
  }
  .mobileAvatarInput div {
    display: flex;
    align-items: start;
    justify-content: flex-start;
  }
  @media ${device.mobileLarge} {
    margin-bottom: 0.6rem;
  }

  @media (min-width: 900px) {
    .mobileAvatarInput {
      display: none;
    }
  }
`;

export const FormLabel = styled.label`
  width: 100%;
  margin-bottom: 0.2rem;
  color: var(--text-color);

  text-align: left;
  font-size: var(--font-size-base);
  font-weight: var(--font-semi-bold);

  @media ${device.tablet} {
    font-size: var(--font-size-medium);
  }
`;

export const FormInput = styled.input`
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--text-color);

  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  color: var(--text-color);
  background-color: transparent;
  font-size: var(--font-size-medium);

  @media ${device.mobileLarge} {
    padding: 0.4rem 0.8rem;
    font-size: var(--font-size-small);
  }
`;

export const FormError = styled.p`
  width: 100%;
  margin-top: -10px;
  color: var(--layout-color);

  font-size: 14px;
  text-align: left;

  @media ${device.mobileLarge} {
    margin-bottom: 1px;
    font-size: 12px;
  }
`;

export const FormTextarea = styled.textarea`
  width: 100%;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  outline: none;
  border-radius: 6px;
  border: 1px solid var(--text-color);

  color: var(--text-color);
  background-color: transparent;
  font-size: var(--font-size-medium);

  @media ${device.mobileLarge} {
    padding: 0.4rem 0.8rem;
    font-size: var(--font-size-small);
  }
`;

export const SelectFormWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FormLabelCheckbox = styled.label`
  width: auto;
  color: var(--text-color);

  text-align: left;
  font-size: var(--font-size-base);
  font-weight: var(--font-semi-bold);

  @media ${device.tablet} {
    font-size: var(--font-size-medium);
  }
`;

export const FormInputCheckbox = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 10px;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 30%;

  @media (max-width: 900px) {
    display: none;
    width: 240px;
  }

  @media ${device.mobileLarge} {
    width: 100%;
  }
`;

export const FormTitle = styled.p`
  width: 100%;
  margin-bottom: 0.2rem;
  color: var(--text-color);

  text-align: left;
  font-size: var(--font-size-base);
  font-weight: var(--font-semi-bold);

  @media ${device.tablet} {
    font-size: var(--font-size-medium);
  }
`;

export const InputFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 240px;

  border: 3px dotted var(--text-color);
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.5rem;

  cursor: pointer;
  color: var(--text-color);
  background-color: transparent;
  text-align: center;
  font-size: var(--font-size-medium);

  @media ${device.tablet} {
    height: 200px;
  }

  @media ${device.mobileLarge} {
    font-size: var(--font-size-small);
  }
`;

export const RegisterImgDropZone = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const PrimaryAction = styled.button`
  border: none;
  border-radius: 4px;
  box-shadow: var(--box-shadow-default);
  margin: 1rem 0;
  padding: 0.8rem 2.5rem;

  cursor: pointer;
  color: var(--text-color);
  background: var(--layout-color);

  transition: all 0.2s ease-in;
  text-align: center;
  font-size: var(--font-size-medium);

  &:hover {
    transition: all 0.2s ease-in;
    background: var(--button-hover-color);
  }

  @media ${device.mobileLarge} {
    margin: 0.6rem 0;
    padding: 0.6rem 2rem;
    font-size: var(--font-size-small);
  }

  @media ${device.tablet} {
  }
`;
