import {
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Image,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { InforCardItem } from "@components/infoCard";
import { BaseResponse } from "@interfaces/base";
import {
  IUserEquipament,
  UserEquipmentTypes,
  UserSetupForm,
} from "@interfaces/user.interface";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider, { Settings } from "react-slick";
import { GoDotFill } from "react-icons/go";
import { useParams } from "react-router-dom";
import useUser from "@hooks/useUser";
import useUserPublic from "@hooks/useUserPublic";
import useAuth from "@hooks/useAuth";
import Modal from "@components/template/modal";

type Props = {
  setup?: BaseResponse<IUserEquipament>[];
};

const SetupPresentation: React.FC<Props> = ({ setup }) => {
  const { userId } = useParams();
  const { user } = useUserPublic(userId!);
  const { userAuth } = useUser();
  const { isAuthenticated } = useAuth();
  const modalViewImage = useDisclosure();
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderRefs = useRef<(Slider | null)[]>([]);
  const [imageUrl, setImageUrl] = useState<string>("");

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(0);
  }, [tabIndex]);

  const mapAba = (index: number) => {
    return (
      {
        0: UserEquipmentTypes.COMPUTER,
        1: UserEquipmentTypes.CONSOLE,
        2: UserEquipmentTypes.NOTEBOOK,
        3: UserEquipmentTypes.SMARTPHONE,
        4: UserEquipmentTypes.TABLET,
      }[index] || UserEquipmentTypes.COMPUTER
    );
  };

  const setupForm: UserSetupForm = {
    CONSOLE: setup?.find(
      (item) => item.entity.type === UserEquipmentTypes.CONSOLE
    ),
    COMPUTER: setup?.find(
      (item) => item.entity.type === UserEquipmentTypes.COMPUTER
    ),
    NOTEBOOK: setup?.find(
      (item) => item.entity.type === UserEquipmentTypes.NOTEBOOK
    ),
    SMARTPHONE: setup?.find(
      (item) => item.entity.type === UserEquipmentTypes.SMARTPHONE
    ),
    TABLET: setup?.find(
      (item) => item.entity.type === UserEquipmentTypes.TABLET
    ),
  };

  const setupActive = setupForm?.[mapAba(tabIndex)];

  const next = () => {
    sliderRefs.current?.[tabIndex]?.slickNext();
  };

  const previous = () => {
    sliderRefs.current?.[tabIndex]?.slickPrev();
  };

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const settings: Settings = {
    fade: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setActiveIndex(next),
    customPaging: (i) =>
      i === activeIndex ? (
        <GoDotFill color="#E94560" />
      ) : (
        <GoDotFill color="#df909e" />
      ),
  };

  const openModalViwImage = (url: string) => {
    setImageUrl(url);
    modalViewImage.onOpen();
  };

  const itHasSetup =
    isAuthenticated && setup?.length === 0 && userAuth?.id === user?.id;

  if (userAuth?.id !== user?.id && setup?.length === 0) return;

  return (
    <>
      <Flex flexDir={"column"} w={"100%"} gap={"25px"}>
        <Text fontWeight={"bold"} fontSize={"2rem"}>
          Setup
        </Text>
        <Flex
          w={"100%"}
          bg={"#0D2134"}
          p={"10px 3px 10px 30px"}
          boxShadow="3px 3px 10px rgba(0, 0, 0, 0.4)"
        >
          {itHasSetup ? (
            <Flex h={"200px"} justifyContent={"center"} w={"100%"}>
              <Text
                color="#E94560"
                fontSize={"1.6rem"}
                fontWeight={"bold"}
                alignSelf={"center"}
              >
                Nenhum setup cadastrado. Para cadastrar vá até as configurações.
              </Text>
            </Flex>
          ) : (
            <Tabs
              index={tabIndex}
              onChange={handleTabsChange}
              variant="unstyled"
              w={"100%"}
            >
              <TabList
                display={"flex"}
                flexDir={"row"}
                gap={"8px"}
                flexWrap={"wrap"}
                justifyContent={{ base: "center", lg: "flex-start" }}
                w={"100%"}
              >
                <Tab
                  bgColor={"#293A5E"}
                  color={"#ffffff"}
                  _selected={{ borderBottom: "3px solid #E94560" }}
                  flex={{ base: "1", lg: "none" }}
                  w={{ base: "auto", lg: "160px" }}
                  isDisabled={!setupForm.COMPUTER}
                >
                  Desktop
                </Tab>
                <Tab
                  bgColor={"#293A5E"}
                  color={"#ffffff"}
                  _selected={{ borderBottom: "3px solid #E94560" }}
                  flex={{ base: "1", lg: "none" }}
                  w={{ base: "auto", lg: "160px" }}
                  isDisabled={!setupForm.CONSOLE}
                >
                  Console
                </Tab>
                <Tab
                  bgColor={"#293A5E"}
                  color={"#ffffff"}
                  _selected={{ borderBottom: "3px solid #E94560" }}
                  flex={{ base: "1", lg: "none" }}
                  w={{ base: "auto", lg: "160px" }}
                  isDisabled={!setupForm.NOTEBOOK}
                >
                  Notebook
                </Tab>
                <Tab
                  bgColor={"#293A5E"}
                  color={"#ffffff"}
                  _selected={{ borderBottom: "3px solid #E94560" }}
                  flex={{ base: "1", lg: "none" }}
                  w={{ base: "auto", lg: "160px" }}
                  isDisabled={!setupForm.SMARTPHONE}
                >
                  Mobile
                </Tab>
                <Tab
                  bgColor={"#293A5E"}
                  color={"#ffffff"}
                  _selected={{ borderBottom: "3px solid #E94560" }}
                  flex={{ base: "1", lg: "none" }}
                  w={{ base: "auto", lg: "160px" }}
                  isDisabled={!setupForm.TABLET}
                >
                  Tablete
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={"15px 0px"}>
                  <Flex w={"100%"} flexDir={"column"}>
                    <Flex gap={"20px"} flexDir={{ base: "column", lg: "row" }}>
                      <Flex
                        minW={{ base: "auto", lg: "500px" }}
                        flexDir={"column"}
                        gap={"20px"}
                        flex={"1"}
                      >
                        {setupActive?.files.length! > 1 && (
                          <Slider
                            ref={(ref) => (sliderRefs.current[0] = ref)}
                            {...settings}
                          >
                            {setupActive?.files.map((item) => (
                              <Image
                                key={item.id}
                                src={item.url}
                                maxH={"530px"}
                                objectFit={"contain"}
                                onClick={() => openModalViwImage(item.url)}
                              />
                            ))}
                          </Slider>
                        )}
                        {setupActive?.files.length! === 1 && (
                          <Image
                            src={setupActive?.files[0].url}
                            maxH={"530px"}
                            objectFit={"contain"}
                            onClick={() =>
                              openModalViwImage(setupActive?.files[0].url!)
                            }
                          />
                        )}
                      </Flex>
                      <Flex
                        flexDir={"column"}
                        gap={"18px"}
                        bg={"#0C1A27"}
                        p={"30px"}
                        w={{ base: "auto", lg: "400px" }}
                      >
                        <InforCardItem
                          keyProp="Gabinete"
                          value={setupActive?.entity.cabinet}
                        />
                        <InforCardItem
                          keyProp="Processador"
                          value={setupActive?.entity.cpu}
                        />
                        <InforCardItem
                          keyProp="Placa de Vídeo"
                          value={setupActive?.entity.graphicsCard}
                        />
                        <InforCardItem
                          keyProp="Sistema Operacional"
                          value={setupActive?.entity.os}
                        />
                        <InforCardItem
                          keyProp="Memória"
                          value={setupActive?.entity.memoryRam}
                        />
                        <InforCardItem
                          keyProp="HD/SSD"
                          value={setupActive?.entity.storage}
                        />
                        <InforCardItem
                          keyProp="Cooler"
                          value={setupActive?.entity.cooler}
                        />
                        <InforCardItem
                          keyProp="Monitor"
                          value={setupActive?.entity.monitor}
                        />
                        <InforCardItem
                          keyProp="Mouse"
                          value={setupActive?.entity.mouse}
                        />
                        <InforCardItem
                          keyProp="Teclado"
                          value={setupActive?.entity.keyboard}
                        />
                        <InforCardItem
                          keyProp="Headset"
                          value={setupActive?.entity.headphone}
                        />
                        <InforCardItem
                          keyProp="Mousepad"
                          value={setupActive?.entity.mousePad}
                        />
                        <InforCardItem
                          keyProp="Microfone"
                          value={setupActive?.entity.microphone}
                        />
                      </Flex>
                    </Flex>
                    <Flex>
                      <Button
                        onClick={previous}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowBack color="#FFFFFF" size={"30px"} />
                      </Button>
                      <Button
                        onClick={next}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowForward color="#FFFFFF" size={"30px"} />
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel p={"15px 0px"}>
                  <Flex w={"100%"} flexDir={"column"}>
                    <Flex gap={"20px"} flexDir={{ base: "column", lg: "row" }}>
                      <Flex
                        minW={{ base: "auto", lg: "500px" }}
                        flexDir={"column"}
                        gap={"20px"}
                        flex={"1"}
                      >
                        {setupActive?.files.length! > 1 && (
                          <Slider
                            ref={(ref) => (sliderRefs.current[1] = ref)}
                            {...settings}
                          >
                            {setupActive?.files.map((item) => (
                              <Image
                                key={item.id}
                                src={item.url}
                                maxH={"530px"}
                                objectFit={"contain"}
                                onClick={() => openModalViwImage(item.url)}
                              />
                            ))}
                          </Slider>
                        )}
                        {setupActive?.files.length! === 1 && (
                          <Image
                            src={setupActive?.files[0].url}
                            maxH={"530px"}
                            objectFit={"contain"}
                            onClick={() =>
                              openModalViwImage(setupActive?.files[0].url!)
                            }
                          />
                        )}
                      </Flex>
                      <Flex
                        flexDir={"column"}
                        gap={"18px"}
                        bg={"#0C1A27"}
                        p={"30px"}
                        w={{ base: "auto", lg: "400px" }}
                      >
                        <InforCardItem
                          keyProp="Marca/Modelo"
                          value={setupActive?.entity.brand_model}
                        />
                        <InforCardItem
                          keyProp="Sistema Operacional"
                          value={setupActive?.entity.os}
                        />
                        <InforCardItem
                          keyProp="HD/SSD"
                          value={setupActive?.entity.storage}
                        />
                        <InforCardItem
                          keyProp="Monitor"
                          value={setupActive?.entity.monitor}
                        />
                        <InforCardItem
                          keyProp="Controle"
                          value={setupActive?.entity.controll}
                        />
                        <InforCardItem
                          keyProp="Teclado"
                          value={setupActive?.entity.keyboard}
                        />
                        <InforCardItem
                          keyProp="Headset"
                          value={setupActive?.entity.headphone}
                        />
                      </Flex>
                    </Flex>
                    <Flex>
                      <Button
                        onClick={previous}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowBack color="#FFFFFF" size={"30px"} />
                      </Button>
                      <Button
                        onClick={next}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowForward color="#FFFFFF" size={"30px"} />
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel p={"15px 0px"}>
                  <Flex w={"100%"} flexDir={"column"}>
                    <Flex gap={"20px"} flexDir={{ base: "column", lg: "row" }}>
                      <Flex
                        minW={{ base: "auto", lg: "500px" }}
                        flexDir={"column"}
                        gap={"20px"}
                        flex={"1"}
                      >
                        {setupActive?.files.length! > 1 && (
                          <Slider
                            ref={(ref) => (sliderRefs.current[2] = ref)}
                            {...settings}
                          >
                            {setupActive?.files.map((item) => (
                              <Image
                                key={item.id}
                                src={item.url}
                                maxH={"530px"}
                                objectFit={"contain"}
                                onClick={() => openModalViwImage(item.url)}
                              />
                            ))}
                          </Slider>
                        )}
                        {setupActive?.files.length! === 1 && (
                          <Image
                            src={setupActive?.files[0].url}
                            maxH={"530px"}
                            objectFit={"contain"}
                            onClick={() =>
                              openModalViwImage(setupActive?.files[0].url!)
                            }
                          />
                        )}
                      </Flex>
                      <Flex
                        flexDir={"column"}
                        gap={"18px"}
                        bg={"#0C1A27"}
                        p={"30px"}
                        w={{ base: "auto", lg: "400px" }}
                      >
                        <InforCardItem
                          keyProp="Marca/Modelo"
                          value={setupActive?.entity.brand_model}
                        />
                        <InforCardItem
                          keyProp="Processador"
                          value={setupActive?.entity.cpu}
                        />
                        <InforCardItem
                          keyProp="Placa de Vídeo"
                          value={setupActive?.entity.graphicsCard}
                        />
                        <InforCardItem
                          keyProp="Sistema Operacional"
                          value={setupActive?.entity.os}
                        />
                        <InforCardItem
                          keyProp="Memória"
                          value={setupActive?.entity.memoryRam}
                        />
                        <InforCardItem
                          keyProp="HD/SSD"
                          value={setupActive?.entity.storage}
                        />
                        <InforCardItem
                          keyProp="Monitor"
                          value={setupActive?.entity.monitor}
                        />
                        <InforCardItem
                          keyProp="Mouse"
                          value={setupActive?.entity.mouse}
                        />
                        <InforCardItem
                          keyProp="Teclado"
                          value={setupActive?.entity.keyboard}
                        />
                        <InforCardItem
                          keyProp="Headset"
                          value={setupActive?.entity.headphone}
                        />
                        <InforCardItem
                          keyProp="Mousepad"
                          value={setupActive?.entity.mousePad}
                        />
                        <InforCardItem keyProp="Microfone" value={""} />
                      </Flex>
                    </Flex>
                    <Flex>
                      <Button
                        onClick={previous}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowBack color="#FFFFFF" size={"30px"} />
                      </Button>
                      <Button
                        onClick={next}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowForward color="#FFFFFF" size={"30px"} />
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel p={"15px 0px"}>
                  <Flex w={"100%"} flexDir={"column"}>
                    <Flex gap={"20px"} flexDir={{ base: "column", lg: "row" }}>
                      <Flex
                        minW={{ base: "auto", lg: "500px" }}
                        flexDir={"column"}
                        gap={"20px"}
                        flex={"1"}
                      >
                        {setupActive?.files.length! > 1 && (
                          <Slider
                            ref={(ref) => (sliderRefs.current[3] = ref)}
                            {...settings}
                          >
                            {setupActive?.files.map((item) => (
                              <Image
                                key={item.id}
                                src={item.url}
                                maxH={"530px"}
                                objectFit={"contain"}
                                onClick={() => openModalViwImage(item.url)}
                              />
                            ))}
                          </Slider>
                        )}
                        {setupActive?.files.length! === 1 && (
                          <Image
                            src={setupActive?.files[0].url}
                            maxH={"530px"}
                            objectFit={"contain"}
                            onClick={() =>
                              openModalViwImage(setupActive?.files[0].url!)
                            }
                          />
                        )}
                      </Flex>
                      <Flex
                        flexDir={"column"}
                        gap={"18px"}
                        bg={"#0C1A27"}
                        p={"30px"}
                        w={{ base: "auto", lg: "400px" }}
                      >
                        <InforCardItem
                          keyProp="Marca/Modelo"
                          value={setupActive?.entity.brand_model}
                        />
                        <InforCardItem
                          keyProp="Processador"
                          value={setupActive?.entity.cpu}
                        />
                        <InforCardItem
                          keyProp="Sistema Operacional"
                          value={setupActive?.entity.os}
                        />
                        <InforCardItem
                          keyProp="Memória"
                          value={setupActive?.entity.memoryRam}
                        />
                        <InforCardItem
                          keyProp="HD/SSD"
                          value={setupActive?.entity.storage}
                        />
                        <InforCardItem
                          keyProp="Headset"
                          value={setupActive?.entity.headphone}
                        />
                      </Flex>
                    </Flex>
                    <Flex>
                      <Button
                        onClick={previous}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowBack color="#FFFFFF" size={"30px"} />
                      </Button>
                      <Button
                        onClick={next}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowForward color="#FFFFFF" size={"30px"} />
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
                <TabPanel p={"15px 0px"}>
                  <Flex w={"100%"} flexDir={"column"}>
                    <Flex gap={"20px"} flexDir={{ base: "column", lg: "row" }}>
                      <Flex
                        minW={{ base: "auto", lg: "500px" }}
                        flexDir={"column"}
                        gap={"20px"}
                        flex={"1"}
                      >
                        {setupActive?.files.length! > 1 && (
                          <Slider
                            ref={(ref) => (sliderRefs.current[4] = ref)}
                            {...settings}
                          >
                            {setupActive?.files.map((item) => (
                              <Image
                                key={item.id}
                                src={item.url}
                                maxH={"530px"}
                                objectFit={"contain"}
                                onClick={() => openModalViwImage(item.url)}
                              />
                            ))}
                          </Slider>
                        )}
                        {setupActive?.files.length! === 1 && (
                          <Image
                            src={setupActive?.files[0].url}
                            maxH={"530px"}
                            objectFit={"contain"}
                            onClick={() =>
                              openModalViwImage(setupActive?.files[0].url!)
                            }
                          />
                        )}
                      </Flex>
                      <Flex
                        flexDir={"column"}
                        gap={"18px"}
                        bg={"#0C1A27"}
                        p={"30px"}
                        w={{ base: "auto", lg: "400px" }}
                      >
                        <InforCardItem
                          keyProp="Marca/Modelo"
                          value={setupActive?.entity.brand_model}
                        />
                        <InforCardItem
                          keyProp="Sistema Operacional"
                          value={setupActive?.entity.os}
                        />
                        <InforCardItem
                          keyProp="Memória"
                          value={setupActive?.entity.memoryRam}
                        />
                        <InforCardItem
                          keyProp="HD/SSD"
                          value={setupActive?.entity.storage}
                        />
                        <InforCardItem
                          keyProp="Monitor"
                          value={setupActive?.entity.monitor}
                        />
                        <InforCardItem
                          keyProp="Teclado"
                          value={setupActive?.entity.keyboard}
                        />
                        <InforCardItem
                          keyProp="Headset"
                          value={setupActive?.entity.headphone}
                        />
                      </Flex>
                    </Flex>
                    <Flex>
                      <Button
                        onClick={previous}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowBack color="#FFFFFF" size={"30px"} />
                      </Button>
                      <Button
                        onClick={next}
                        variant={"ghost"}
                        p={"0px"}
                        _hover={{ backgroundColor: "none" }}
                      >
                        <IoIosArrowForward color="#FFFFFF" size={"30px"} />
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </Flex>
      </Flex>
      <Modal
        title=""
        isOpen={modalViewImage.isOpen}
        handlerCancel={modalViewImage.onClose}
      >
        <Image src={imageUrl} maxH={"530px"} objectFit={"contain"} />
      </Modal>
    </>
  );
};

export default SetupPresentation;
