import styled from "styled-components";

export const ContatoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 2rem 0;
`;

export const ContatoTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: var(--layout-color);
  margin-bottom: 2rem;
`;

export const ContatoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContatoImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const ContatoImg = styled.img`
  width: 40px;
`;

export const ContatoLink = styled.a`
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;
