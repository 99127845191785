import InstagramLogo from "@assets/ativos/svg/InstagramWhite.svg";
import WhatsAppLogo from "@assets/ativos/svg/WhatsAppWhite.svg";
import TwitchLogo from "@assets/ativos/svg/TwitchWhite.svg";
import DiscordLogo from "@assets/ativos/svg/DiscordWhite.svg";

import {
  INSTAGRAM_URL,
  WHATSAPP_URL,
  TWITCH_URL,
  DISCORD_URL,
} from "@utils/const";

import { SideBarContainer, SideBarButton } from "./styles";

export default function Sidebar() {
  return (
    <SideBarContainer>
      <SideBarButton
        href={INSTAGRAM_URL}
        target="_blank "
        rel="noopener noreferrer"
      >
        <img src={InstagramLogo} alt="Instagram" />
      </SideBarButton>
      <SideBarButton
        href={WHATSAPP_URL}
        target="_blank "
        rel="noopener noreferrer"
      >
        <img src={WhatsAppLogo} alt="Whatsapp" />
      </SideBarButton>
      <SideBarButton
        href={TWITCH_URL}
        target="_blank "
        rel="noopener noreferrer"
      >
        <img src={TwitchLogo} alt="Twitch" />
      </SideBarButton>
      <SideBarButton
        href={DISCORD_URL}
        target="_blank "
        rel="noopener noreferrer"
      >
        <img src={DiscordLogo} alt="Discord" />
      </SideBarButton>
    </SideBarContainer>
  );
}
