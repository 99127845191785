import styled from "styled-components";
import { device } from "../../utils/index";

export const Container = styled.div`
  width: 100%;

  .tituloCampeonato {
    margin-top: 70px;
    color: var(--text-color);
    text-align: center;
    font-size: var(--font-size-xlarge);
    font-weight: var(--font-bold);
  }

  button.inscrever,
  button.jogar {
    align-self: center;
    justify-self: center;

    box-shadow: var(--box-shadow-default);
    border: none;
    border-radius: 5px;

    padding: 15px 30px 15px 30px;
    margin-top: 50px;

    color: var(--text-color);
    background: var(--layout-color);
    transition: all ease-in-out 0.3s;

    text-align: center;
    font-size: var(--font-size-medium);

    &:hover {
      transform: scale(1.1);
      transition: all ease-in-out 0.3s;
    }
  }

  .tempoInscrever,
  .emAndamento {
    margin-top: 50px;
    margin-bottom: 70px;
    color: var(--text-color);
    font-size: var(--font-size-medium);
  }

  .emAndamento {
    color: #00ec98;
  }

  .bar {
    display: flex;
    text-align: center;
    gap: 20px;
    overflow-x: auto;
    margin: 2rem 2rem 0 2rem;
    color: var(--text-color);
    border-bottom: 1px solid #e94560;
  }

  .details,
  .participants {
    font-size: var(--font-size-xlarge);
    cursor: pointer;
  }

  .activeBar {
    border-bottom: 2px solid #e94560;
  }

  @media ${device.tablet} {
    .details,
    .participants {
      font-size: var(--font-size-base);
    }

    .bar {
      gap: 10px;
    }
  }

  @media ${device.mobileLarge} {
    .tituloCampeonato {
      font-size: var(--font-size-large);
    }

    button.inscrever,
    button.jogar {
      margin-top: 25px;
      padding: 10px 25px 10px 25px;
      font-size: var(--font-size-small);
    }

    .tempoInscrever,
    .emAndamento {
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: var(--font-size-small);
    }

    .details,
    .participants {
      font-size: var(--font-size-small);
    }
  }

  @media ${device.mobileMedium} {
    .tempoInscrever,
    .emAndamento {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 180px;
    }
  }
`;

export const InfoCampeonatoContainer = styled.div`
  display: flex;
  gap: 2rem;
  padding-top: 14px;
  margin: 0 2rem 0 2rem;

  .left {
    flex: 6;
    color: var(--text-color);
  }

  .infoTitle {
    margin-bottom: 1rem;
    font-size: 24px;
    font-weight: var(--font-bold);
  }

  .infoContent {
    font-size: var(--font-size-base);
    line-height: 24px;
  }

  .right {
    flex: 4;
  }

  .infoFormato {
    margin-bottom: 1rem;
    color: var(--text-color);
    font-size: 24px;
    font-weight: var(--font-bold);
  }

  .boxHolder {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  .box {
    border-radius: 7px;
    padding-block: 0.5rem;
    color: var(--text-color);
    background: #0c1a27 0% 0% no-repeat padding-box;
    text-align: center;
    font-size: var(--font-size-medium);
  }

  @media ${device.desktopSmall} {
    flex-direction: column-reverse;

    .boxHolder {
      gap: 10px;
      max-width: 400px;
    }
  }

  @media (max-width: 600px) {
    gap: 1.5rem;

    .infoFormato,
    .infoTitle {
      margin-bottom: 0.4rem;
      font-size: var(--font-size-large);
    }
    .infoContent {
      font-size: var(--font-size-medium);
    }
  }

  @media ${device.mobileMedium} {
    .infoFormato,
    .infoTitle {
      margin-bottom: 0.2rem;
      font-size: var(--font-size-base);
    }
    .infoContent {
      font-size: var(--font-size-small);
    }
    .boxHolder {
      grid-template-columns: 1fr;
      max-width: 150px;
    }
    .box {
      font-size: var(--font-size-small);
    }
  }
`;

export const RedLine = styled.div`
  height: 1px;
  background: transparent
    linear-gradient(90deg, #102131 0%, #e94560 78%, #102131 100%) 0% 0%
    no-repeat padding-box;
`;

export const ListUsersContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding-inline: 60px;
  margin-top: 30px;

  @media ${device.tablet} {
    padding-inline: 40px;
    gap: 14px;
  }
`;

export const UsersCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--text-color);
  text-align: center;
  gap: 10px;

  img.userImg {
    display: flex;
    width: 150px;
    height: 150px;

    border-radius: 10px;
  }
  .userName {
    font-size: var(--font-size-base);
    font-weight: bold;
  }

  @media ${device.tablet} {
    img.userImg {
      width: 120px;
      height: 120px;
    }
    .userName {
      font-size: var(--font-size-medium);
    }
  }

  @media ${device.mobileLarge} {
    img.userImg {
      width: 100px;
      height: 100px;
    }
    .userName {
      font-size: var(--font-size-small);
    }
  }
`;
