import HeaderRight from "./components/right";
import HeaderLeft from "./components/left";
import { HeaderContainer } from "./styles";

export default function Header(props) {
  return (
    <HeaderContainer className="container">
      <HeaderLeft active={props.active} />
      <HeaderRight />
    </HeaderContainer>
  );
}
