import { progamersApi } from "@config/axios.config";
import { BaseResponse, PaginationResponse } from "@interfaces/base";
import { IGame } from "@interfaces/game.interface";

export default function gameService() {
  const getGameById = async (id: string): Promise<BaseResponse<IGame>> => {
    const result = await progamersApi.get(`/public/game/${id}`);
    return result.data;
  };

  const getAllGames = async (): Promise<
    PaginationResponse<BaseResponse<IGame>>
  > => {
    const result = await progamersApi.get(`/game`);
    return result.data;
  };

  return { getGameById, getAllGames };
}
