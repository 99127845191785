import { Container, AboutMeItem, AboutMeData } from "./styles";

function InfoCard({ data }) {
  return (
    <Container>
      <AboutMeItem>
        <AboutMeData>{data}</AboutMeData>
      </AboutMeItem>
    </Container>
  );
}

export default InfoCard;
