import { device } from "@utils/index";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 100%;

  margin: 2rem 0;
`;

export const MobileMembersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

export const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  width: 200px;
  height: 220px;
  cursor: pointer;
`;

export const MemberImg = styled.img`
  width: 200px;
  height: 100%;
`;

export const MemberLeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 40px;

  margin-top: -40px;
  background-color: #171a21aa;
  color: var(--text-color);
`;

export const MemberName = styled.p`
  font-size: var(--font-size-medium);
  font-weight: 500;
  color: var(--text-color);
  margin-top: 1rem;
`;

export const NewMemberLink = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border: 3px dotted var(--layout-color);
  border-radius: 8px;
  min-height: 200px;

  color: var(--layout-color);
  text-decoration: none;
  font-size: var(--font-size-medium);
  text-align: center;

  @media ${device.tablet} {
    width: 200px;
  }
`;

export const NewMemberIcon = styled.img`
  width: 75px;
  height: 75px;

  padding: 1rem;
`;

export const NewMemberLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 220px;
`;
