import WhatsApp from "@assets/ativos/svg/WhatsAppFooter.svg";
import Instagram from "@assets/ativos/svg/InstagramFooter.svg";
import Discord from "@assets/ativos/svg/DiscordFooter.svg";
import Twitch from "@assets/ativos/svg/TwitchFooter.svg";

import {
  INSTAGRAM_URL,
  WHATSAPP_URL,
  TWITCH_URL,
  DISCORD_URL,
} from "@utils/const";

import {
  ContatoContainer,
  ContatoTitle,
  ContatoContentWrapper,
  ContatoImgWrapper,
  ContatoImg,
  ContatoLink,
} from "./styles";

function Contato() {
  return (
    <ContatoContainer>
      <ContatoContentWrapper>
        <ContatoTitle>Contato</ContatoTitle>

        <ContatoImgWrapper>
          <ContatoLink href={WHATSAPP_URL}>
            <ContatoImg src={WhatsApp} alt="Whatsapp" />
          </ContatoLink>
          <ContatoLink href={INSTAGRAM_URL}>
            <ContatoImg src={Instagram} alt="Instagram" />
          </ContatoLink>
          <ContatoLink href={DISCORD_URL}>
            <ContatoImg src={Discord} alt="Discord" />
          </ContatoLink>
          <ContatoLink href={TWITCH_URL}>
            <ContatoImg src={Twitch} alt="Twitch" />
          </ContatoLink>
        </ContatoImgWrapper>
      </ContatoContentWrapper>
    </ContatoContainer>
  );
}

export default Contato;
