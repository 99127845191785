import background from "@assets/ativos/png/Header.png";
import * as S from "./styles";

const Welcome = () => {
  return (
    <S.Container>
      <S.BannerWrapper>
        <S.Title>Bem-vindo(a) à Pro Gamers</S.Title>
        {/* <S.Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          pulvinar tincidunt quam, eu vulputate nulla iaculis vel.
        </S.Description> */}
      </S.BannerWrapper>
      <S.WelcomeImage src={background} />
    </S.Container>
  );
};

export default Welcome;
