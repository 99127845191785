import { Flex, Text } from "@chakra-ui/react";

type Props = {
  text: string;
  icon: React.ReactNode;
};

const InforCard: React.FC<Props> = ({ text, icon }) => {
  return (
    <Flex
      w="275px"
      h="98px"
      bg="#0C1A27"
      alignItems="center"
      gap="4"
      justifyContent="start"
      paddingLeft="26px"
      boxShadow="2px 4px 4px 0px #00000040"
    >
      {icon}
      <Text fontSize="1rem" fontWeight="400">
        {text}
      </Text>
    </Flex>
  );
};

export default InforCard;
