import styled from "styled-components";

import { device } from "@utils/index";
import { GoogleLogin } from "@react-oauth/google";

export const Title = styled.h2`
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: var(--layout-color);
  margin-bottom: 2rem;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 500px;
`;

export const SteamButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  border-radius: 12px;
  border: 1px solid #0c1a27;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  margin: 1rem 0;
  margin-bottom: 28px;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  color: var(--text-color);
  background-color: #0c1a27;
  font-size: var(--font-size-base);
  font-weight: 600;
`;

export const SteamIcon = styled.img`
  width: 34px;

  @media ${device.mobileLarge} {
    width: 30px;
  }
`;

export const SteamTitle = styled.p`
  width: 100%;
  padding-left: 2rem;
  text-align: left;

  @media ${device.mobileLarge} {
    padding-left: 1rem;
    font-size: var(--font-size-small);
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 0.6rem 0.8rem;
  margin: 0.3rem 0 0.8rem 0;
  border-radius: 6px;
  border: 1px solid var(--text-color);
  margin-bottom: 1rem;
  font-size: 14px;
  color: var(--text-color);
  background-color: transparent;
`;

export const FormLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  text-align: left;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const FormError = styled.p`
  width: 100%;
  font-size: 14px;
  color: var(--layout-color);
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
`;

export const PrimaryAction = styled.button`
  font-size: 16px;
  background: var(--layout-color);
  color: var(--text-color);
  border: 1px solid var(--layout-color);
  padding: 0.8rem 3rem;
  margin-top: 2rem;
  cursor: pointer;

  box-shadow: var(--box-shadow-default);
  border-radius: 0.5rem;

  @media ${device.tablet} {
    padding: 0.6rem 2.5rem;
  }

  @media ${device.mobileLarge} {
    padding: 0.5rem 2rem;
    margin-top: 1rem;
    font-size: 14px;
  }
`;

export const ForgotPassword = styled.a`
  margin: 1rem 0;
  cursor: pointer;
  color: var(--text-color);
  font-size: 14px;
  text-decoration: underline;
`;

export const RegisterLink = styled.p`
  margin: 1rem 0;

  color: var(--text-color);
  text-align: center;
  font-size: 14px;
`;

export const RegisterSpan = styled.a`
  font-size: 16px;
  color: var(--layout-color);
  text-decoration: underline;
`;

export const FacebookButton = styled.button`
  background-color: #4267b2;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: #365899;
  }

  svg {
    margin-right: 10px;
  }
`;

export const FacebookIcon = styled.svg`
  fill: white;
  width: 24px;
  height: 24px;
`;

export const GoogleButton = styled.button`
  background-color: white;
  color: black;
  font-size: 16px;
  margin-bottom: 16px;
  padding: 10px 20px;

  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: #f1f3f4;
  }

  svg {
    margin-right: 10px;
  }
`;
