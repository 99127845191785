import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 620px;
  height: 500px;
  padding: 2rem;
  background: #102131;
  color: #fff;

  hr {
    margin-top: 1.5rem;
    border-color: #1e354b;
    opacity: 0.3;
  }

  @media (max-width: 540px) {
    width: 300px;
    height: 415px;
  }
`;
export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ModalTitle = styled.h3``;
export const ModalClose = styled.div`
  cursor: pointer;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 540px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const ModalInvitationIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e354b;
  border-radius: 50%;
`;

export const ModalInvitationText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  small {
    color: #acacac;
  }
`;

export const ModalInvitationCopyButton = styled.button`
  background-color: var(--layout-color);
  outline: none;
  border: 0;
  width: 122px;
  height: 50px;
  box-shadow: var(--box-shadow-default);
  color: #fff;

  @media (max-width: 540px) {
    margin-top: 1rem;
  }
`;

export const ModalContentLeftArea = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ModalSearchPlayerSection = styled.div`
  margin-top: 1.5rem;
`;

export const ModalSearchPlayerInput = styled.input`
  width: calc(100% - 50px);
  height: 50px;
  background-color: transparent;
  color: #fff;
  padding-left: 1rem;
  border: 1px solid #ffffff;
  border-right: none;
`;
export const ModalSearchPlayerButton = styled.button`
  width: 50px;
  height: 50px;
  border: 0;
  background-color: var(--layout-color);
  box-shadow: var(--box-shadow-default);
`;
