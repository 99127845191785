import {
  Button,
  ModalBody,
  Modal as ModalChakra,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  isFooter?: boolean;
  handlerConfirm?: () => void;
  handlerCancel: () => void;
  bgColor?: string;
  isHeader?: boolean;
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "full";
};

const Modal: React.FC<Props> = ({
  isOpen,
  title,
  children,
  handlerCancel,
  handlerConfirm,
  confirmButtonText,
  cancelButtonText = "Cancelar",
  isFooter = true,
  size = "2xl",
  bgColor = "#010101",
  isHeader = true,
}) => {
  if (!isOpen) return;

  return (
    <ModalChakra
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handlerCancel}
      size={size}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bgColor={bgColor}
        borderRadius={"12px 12px 0 0"}
        border={"solid 1px #E94560"}
      >
        {isHeader && (
          <ModalHeader
            color="#ffffff"
            fontSize={"20px"}
            fontWeight={"bold"}
            paddingRight={"45px"}
          >
            {title}
          </ModalHeader>
        )}
        <ModalCloseButton color="#ffffff" />
        <ModalBody
          pb={6}
          display={"flex"}
          flexDirection={"column"}
          gap={"30px"}
          color="#ffffff"
        >
          {children}
        </ModalBody>
        {isFooter && (
          <ModalFooter justifyContent={"center"}>
            {handlerConfirm && (
              <Button colorScheme="blue" mr={3} onClick={handlerConfirm}>
                {confirmButtonText}
              </Button>
            )}
            <Button
              width={"103px"}
              height={"30"}
              bg={"#E94560"}
              color={"#fff"}
              fontWeight={"400"}
              _hover={"#E94560"}
              borderRadius={"0"}
              marginTop={"-1rem"}
              onClick={handlerCancel}
            >
              {cancelButtonText}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </ModalChakra>
  );
};

export default Modal;
