import styled from "styled-components";

import { device } from "../../../../utils";

export const ParceirosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 1px solid transparent;
  border-image-slice: 1;
  padding: 20px;
  flex-wrap: wrap;
`;

export const LogoParceiros = styled.img`
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
  margin: 1rem;

  @media (max-width: 1024px) {
    max-height: 55px;
  }

  @media (max-width: 768px) {
    max-height: 75px;
  }
`;

export const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 1;
  max-width: 200px;

  p {
    font-size: 12pt;
    text-align: center;
    margin-top: 0.5rem; // Espaçamento entre a imagem e o texto
    line-height: 1.4; // Melhora a legibilidade em linhas múltiplas
  }
`;

export const ParceirosTitle = styled.p`
  font-size: 20pt;
  text-align: center;
  color: var(--layout-color);
  margin-bottom: 1rem;
  margin-top: 2rem;
  .highlight {
    color: #FFFFFF;
  }
`

export const ParceirosSubtitle = styled.div`
  font-size: 14pt;
  text-align: center;
  color: var(--layout-color);
  margin-bottom: 2rem;
`

export const LogoContainer = styled.div`
display: flex;
justify-content: center;
gap: 6rem;
flex-wrap: wrap;

@media (max-width: 1440px) {
gap: 4rem;
  }

  @media (max-width: 1024px) {
gap: 1rem;
  }
  @media (max-width: 390px) {
gap: 2rem;
  }`