import dayjs from "dayjs";
import ptBr from "dayjs/locale/pt-br";

dayjs.locale(ptBr);

export function dateFormat(date: Date | string) {
  if (!date) {
    return;
  }
  return dayjs(date).format("D[ de ]MMMM[, ]YYYY");
}

export function dateFormat2(date: Date | string) {
  if (!date) {
    return;
  }
  return dayjs(date).format("D[ de ]MMMM[, ]YYYY[,] HH:mm");
}

export function dateTimeFormat(date: Date | string) {
  if (!date) {
    return;
  }
  return dayjs(date).format("D[ de ]MMMM[, ]YYYY[,] HH [horas e] mm [minutos]");
}

// TODO => Refatorar Nome
export function dateTimeFormat2(date: Date | string) {
  if (!date) {
    return;
  }
  return dayjs(date).format("DD/MM/YY");
}
