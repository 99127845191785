import gameService from "@services/game.service";
import teamService from "@services/team.service";
import { queryOptions } from "@tanstack/react-query";

const optionsQuery = () => {
  const { getGameById, getAllGames } = gameService();
  const {
    getProfileTeam,
    getInviteNotificationsUser,
    getTeamMembers,
    getTeamByIdPublic,
    getTeamMembersByIdPublic,
  } = teamService();

  const optionsFetchGameId = (id: string) => {
    return queryOptions({
      queryKey: ["gameById", id],
      queryFn: () => getGameById(id),
      enabled: !!id,
    });
  };

  const optionsFetchGames = () => {
    return queryOptions({ queryKey: ["allGames"], queryFn: getAllGames });
  };

  const optionsFetchTeamMembers = (teamId: string) => {
    return queryOptions({
      queryKey: ["teamMembers", teamId],
      queryFn: () => getTeamMembers(teamId),
      enabled: !!teamId,
    });
  };

  const optionsFetchTeamById = (teamId: string) => {
    return queryOptions({
      queryKey: ["teamProfile", teamId],
      queryFn: () => getProfileTeam(teamId!),
      enabled: !!teamId,
    });
  };

  const optionsFetchTeamByIdPublic = (teamId: string) => {
    return queryOptions({
      queryKey: ["teamByIdPublic", teamId],
      queryFn: () => getTeamByIdPublic(teamId!),
      enabled: !!teamId,
    });
  };

  const optionsFetchTeamMembersByIdPublic = (teamId: string) => {
    return queryOptions({
      queryKey: ["teamMembersByIdPublic", teamId],
      queryFn: () => getTeamMembersByIdPublic(teamId!),
      enabled: !!teamId,
    });
  };

  const optionsFetchTeamNotifications = (isAuthenticated: boolean) => {
    return queryOptions({
      queryKey: ["teamNotificarionUser"],
      queryFn: getInviteNotificationsUser,
      enabled: isAuthenticated,
    });
  };

  return {
    optionsFetchGameId,
    optionsFetchGames,
    optionsFetchTeamMembers,
    optionsFetchTeamById,
    optionsFetchTeamNotifications,
    optionsFetchTeamByIdPublic,
    optionsFetchTeamMembersByIdPublic,
  };
};

export const {
  optionsFetchTeamByIdPublic,
  optionsFetchGameId,
  optionsFetchGames,
  optionsFetchTeamMembers,
  optionsFetchTeamById,
  optionsFetchTeamNotifications,
  optionsFetchTeamMembersByIdPublic,
} = optionsQuery();
