import Layout from "@components/template/layout";
import * as S from "./styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegistrationTeamSchema } from "@validations/teams.schema";
import useTeam from "@hooks/useTeam";
import useGame from "@hooks/useGame";
import { Select } from "@chakra-ui/react";

const CreateTeam = () => {
  const { createTeam } = useTeam();
  const { listGames } = useGame();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(RegistrationTeamSchema),
  });

  return (
    <Layout>
      <S.Title>CRIAR NOVO TIME</S.Title>
      <S.FormContainer>
        <S.LeftWrapper>
          <S.RegisterForm onSubmit={handleSubmit(createTeam)}>
            <S.FormLabel htmlFor="teamName">Nome do time</S.FormLabel>
            <S.FormInput
              id="teamName"
              type="text"
              placeholder="Nome do seu time"
              {...register("name")}
            />
            {isSubmitted && errors.name && (
              <S.FormError>{errors.name?.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="teamTag">Tag do time</S.FormLabel>
            <S.FormInput
              id="teamTag"
              type="text"
              placeholder="Tag do time"
              {...register("teamTag")}
            />
            {isSubmitted && errors.teamTag && (
              <S.FormError>{errors.teamTag?.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="game_id">Selecione um Jogo</S.FormLabel>
            <Select
              sx={{
                "--select-bg": "#2f3237",
              }}
              placeholder="Selecione um Jogo"
              {...register("game_id")}
            >
              {listGames?.data.map((item) => (
                <option value={item.entity.id} key={item.entity.id}>
                  {item.entity.name}
                </option>
              ))}
            </Select>
            <S.FormLabel htmlFor="description">Descrição</S.FormLabel>
            <S.FormTextarea
              id="description"
              rows={3}
              cols={50}
              placeholder="Escreva uma breve descrição para o seu time"
              {...register("description")}
              maxLength={200}
            />
            {isSubmitted && errors.description && (
              <S.FormError>{errors.description?.message}</S.FormError>
            )}

            <div className="ButtonCenter">
              <S.PrimaryAction type="submit">Criar Time</S.PrimaryAction>
            </div>
          </S.RegisterForm>
        </S.LeftWrapper>
      </S.FormContainer>
    </Layout>
  );
};

export default CreateTeam;
