import styled from "styled-components";

import { device } from "../../../../utils";

export const CampeonatosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: inherit;
  color: white;

  margin: 2rem;

  div.cardContainer {
    display: flex;
    flex-direction: row;
  }

  .carousel-container {
    width: 100%;
    height: 100%;
  }

  .carousel-item {
    margin: 0rem 1.5rem 0rem 1.5rem;
    width: 4rem;
  }

  .carousel {
    margin: 1.5rem 1rem 0rem 1.5rem;
  }
`;

export const DesktopCarouselWrapper = styled.div`
  display: none;

  @media ${device.desktopSmall} {
    display: flex;
  }
`;

export const MobileCarouselWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;

  @media ${device.desktopSmall} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

export const CampeonatosTitle = styled.h2`
  text-align: center;
  font-size: 20pt;
  color: #e94560;
  text-transform: uppercase;
  padding-bottom: 2rem;

  @media ${device.desktopSmall} {
    font-size: 28pt;
  }
`;
