import styled from "styled-components";
import { device } from "@utils/index";

export const Container = styled.div<{ backgroundImage?: string }>`
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 65vh;
`;

export const WelcomeImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 80vh;
  width: 100vw;
  z-index: 1;
`;

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8rem 2rem 4rem 6rem;
  z-index: 2;

  @media ${device.desktopSmall} {
    padding: 8rem 2rem 8rem 8rem;
  }
`;

export const Title = styled.h1`
  opacity: 1;
  color: #e94560;
  letter-spacing: 0rem;
  font-size: 32px;
  text-align: left;
  font-weight: 600;

  @media ${device.mobileLarge} {
    font-size: 30px;
  }
`;

export const Description = styled.p`
  padding: 2rem 0;
  color: var(--text-color);
  font-size: 14px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 0 2rem;
  margin-top: 3rem;
`;

export const PrimaryAction = styled.a`
  font-size: 12pt;
  letter-spacing: 0px;
  background: var(--layout-color);
  color: var(--text-color);
  opacity: 1;
  text-align: center;
  border-radius: 5rem;
  border: 1px solid var(--layout-color);
  padding: 0.5rem 1rem;
  cursor: pointer;

  box-shadow: 0rem 0rem 1rem var(--layout-color);
  border-radius: 4px;

  @media ${device.desktopSmall} {
    padding: 1.5rem 3rem;
  }
`;

export const SecondaryAction = styled.a`
  font-size: 12pt;
  letter-spacing: 0px;
  background: transparent;
  color: var(--layout-color);
  opacity: 1;
  text-align: center;
  border-radius: 5rem;
  border: 1px solid var(--layout-color);
  padding: 0.5rem 1rem;
  cursor: pointer;

  border-radius: 4px;

  @media ${device.desktopSmall} {
    padding: 1.5rem 3rem;
  }
`;
