import { optionsFetchGames } from "@plugins/reactQuery/queryOptions.reactquery";
import { useQuery } from "@tanstack/react-query";

const useGame = () => {
  const { data: listGames } = useQuery(optionsFetchGames());

  return { listGames };
};

export default useGame;
