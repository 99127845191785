import styled from "styled-components";

import { ToastContainer } from "react-toastify";

export const ToastContainerStyled = styled(ToastContainer)`
  .Toastify__toast {
    background-color: #0c1a27;
    color: #fff;
    font-size: 14px;

    svg {
      color: #fff;
    }
  }

  .Toastify__progress-bar {
    background-color: #e9445f;
    box-shadow: 0rem 0rem 1rem #e9445f;
  }
`;
