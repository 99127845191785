import { device } from "@utils/index";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DropdownContainerWrapper = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: calc(100% - 30px);
  z-index: 12;

  width: 400px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  background: #0c1a27;

  animation: ${fadeIn} 0.3s ease-in-out;

  @media ${device.tablet} {
    top: calc(100% + 10px);
    right: 0;
    width: 300px;
  }
`;

export const DropdownHeader = styled.header`
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 15px;
  background: hsl(209, 53%, 8%);
  text-align: center;

  @media ${device.tablet} {
    padding: 10px;
  }
`;

export const DropdownTitle = styled.h2`
  font-size: var(--font-size-large);

  @media ${device.tablet} {
    font-size: var(--font-size-base);
  }
`;

export const DropdownItemContainer = styled.div`
  padding: 15px;
  border-bottom: 3px solid hsl(209, 53%, 8%);

  &:last-child {
    border-bottom: none;
  }
`;

export const PrimaryNotification = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media ${device.tablet} {
  }
`;

export const UserAreaContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
`;

export const UserIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  border-radius: 50%;
  box-shadow: var(--box-shadow-default);

  color: var(--text-color);
  background-color: var(--layout-color);

  .user_icon {
    width: 18px;
    height: 18px;
  }

  @media ${device.tablet} {
    padding: 7px;
  }
`;

export const UserTextContainer = styled.div``;
export const UserName = styled.p`
  font-size: var(--font-size-base);

  @media ${device.tablet} {
    font-size: var(--font-size-medium);
  }
`;

export const UserTextDescription = styled.p`
  font-size: var(--font-size-medium);

  @media ${device.tablet} {
    font-size: var(--font-size-small);
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 40px;
`;

export const ButtonAccept = styled.button`
  display: flex;

  border-radius: 0.4rem;
  border: none;
  box-shadow: var(--box-shadow-default);
  padding: 0.5rem 1rem;

  transition: 0.2s ease-in;
  color: var(--text-color);
  background-color: var(--layout-color);
  font-size: var(--font-size-small);

  &:hover {
    transition: 0.2s ease-in;
    background-color: hsla(350, 79%, 56%, 0.9);
  }
`;

export const ButtonDeny = styled.button`
  display: flex;
  padding: 0.5rem 1rem;

  border-radius: 0.4rem;
  border: 1px solid var(--layout-color);

  transition: 0.2s ease-in;
  color: var(--text-color);
  background: transparent;
  font-size: var(--font-size-small);

  &:hover {
    box-shadow: var(--box-shadow-default);
    transition: 0.2s ease-in;
    color: var(--text-color);
    background: var(--layout-color);
  }
`;
export const SecundaryNotification = styled.div``;
