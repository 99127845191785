import useAuth from "@hooks/useAuth";

type AuthenticatedUserDisplayProps = {
  children: React.ReactNode;
  rule?: boolean;
};

const AuthenticatedDisplay: React.FC<AuthenticatedUserDisplayProps> = ({
  children,
  rule = true,
}) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated && rule && <>{children}</>;
};

const UnauthenticatedDisplay: React.FC<AuthenticatedUserDisplayProps> = ({
  children,
  rule = false,
}) => {
  const { isAuthenticated } = useAuth();
  return !isAuthenticated && !rule && <>{children}</>;
};

export const AuthenticatedUserDisplay = {
  True: AuthenticatedDisplay,
  False: UnauthenticatedDisplay,
};
