import styled from "styled-components";

export const SuporteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 2rem 0;
`;

export const SuporteTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: var(--layout-color);
  margin-bottom: 2rem;
`;

export const SuporteContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuporteLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const SuporteLink = styled.a`
  font-size: 14px;
  text-align: center;
  color: var(--text-color);
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
`;
