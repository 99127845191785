import userService from "@services/user.service";
import { queryOptions } from "@tanstack/react-query";

const userQueryOptions = () => {
  const {
    getTeamsUserById,
    getSearchUser,
    getUserByIdPublic,
    getSetupByUserIdPublic,
  } = userService();

  const optionsFetchTeamsUserById = (id: string) => {
    return queryOptions({
      queryKey: [],
      queryFn: () => getTeamsUserById(id),
      enabled: !!id,
    });
  };

  const optionsFetchSearchUser = (name: string) => {
    return queryOptions({
      queryKey: ["search_user", name],
      queryFn: () => getSearchUser(name),
      enabled: name.length > 3,
    });
  };

  const optionsFetchUserByIdPublic = (id: string) => {
    return queryOptions({
      queryKey: ["getUserByIdPublic", id],
      queryFn: () => getUserByIdPublic(id),
      enabled: !!id,
    });
  };

  const optionsFetchSetupUserByIdPublic = (id: string) =>
    queryOptions({
      queryKey: ["getSetupUserByIdPublic", id],
      queryFn: () => getSetupByUserIdPublic(id),
      enabled: !!id,
    });

  return {
    optionsFetchTeamsUserById,
    optionsFetchSearchUser,
    optionsFetchUserByIdPublic,
    optionsFetchSetupUserByIdPublic,
  };
};

export const {
  optionsFetchTeamsUserById,
  optionsFetchSearchUser,
  optionsFetchUserByIdPublic,
  optionsFetchSetupUserByIdPublic,
} = userQueryOptions();
