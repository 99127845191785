import Layout from "@components/template/layout";
import useAuth from "@hooks/useAuth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IChangePassword,
  IChangePasswordParams,
} from "@interfaces/auth.interface";
import { ResetPasswordScheme } from "@validations/auth.schema";
import * as S from "./styles.ts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage: React.FC = () => {
  const { submiteChangePassword, isAuthenticated } = useAuth();
  const navegate = useNavigate();
  const [queryData, setQueryData] = useState<IChangePasswordParams>({
    token: "",
    email: "",
  });

  if (isAuthenticated) {
    navegate("/");
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<IChangePassword>({
    resolver: yupResolver(ResetPasswordScheme),
  });

  const handleCancel = () => {
    navegate("/login");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token") || "";
    const email = urlParams.get("email") || "";
    setQueryData({ token, email });
  }, []);

  return (
    <Layout
      styleContainer={{ overflow: "visible" }}
      styleBodyContainer={{ overflow: "visible" }}
    >
      <S.LineTop />
      <S.RegisterFormContainer>
        <S.FormContainer>
          <S.Title>Recuperação de Senha</S.Title>
          <S.RegisterForm
            onSubmit={handleSubmit(
              async (
                data: IChangePassword,
                event?: React.BaseSyntheticEvent
              ) => {
                await submiteChangePassword({ ...data, ...queryData }, event);
                reset();
                navegate("/login");
              }
            )}
          >
            <S.FormLabel htmlFor="registerPassword">Senha</S.FormLabel>
            <S.FormInput
              id="registerPassword"
              type="password"
              placeholder="Sua senha"
              {...register("password")}
            />
            {isSubmitted &&
              errors.password &&
              errors.password
                .message!.split("\n")
                .map((item: string, index: number) => (
                  <S.FormError key={index} style={{ padding: "8px 0px" }}>
                    {item}
                  </S.FormError>
                ))}

            <S.FormLabel htmlFor="registerPasswordConfirm">
              Confirmar senha
            </S.FormLabel>
            <S.FormInput
              id="registerPasswordConfirm"
              type="password"
              placeholder="Confirme sua senha"
              {...register("passwordConfirm")}
            />
            {isSubmitted && errors.passwordConfirm && (
              <S.FormError>{errors.passwordConfirm.message}</S.FormError>
            )}
            <S.Row>
              <S.PrimaryAction type="submit">Salvar</S.PrimaryAction>
              <S.LinkButton onClick={handleCancel}>Cancelar</S.LinkButton>
            </S.Row>
          </S.RegisterForm>
        </S.FormContainer>
      </S.RegisterFormContainer>
    </Layout>
  );
};

export default ResetPasswordPage;
