import { Link } from "react-router-dom";
import { Home, Trophy } from "lucide-react";

import Logo from "@assets/ativos/png/Logo.png";

import { LeftContainer, HeaderLogo, HeaderUl, ButtonSection } from "./styles";

export default function HeaderLeft(props: any) {
  let borderBottomInicio = "active link";
  let borderBottomjogar = " link";
  if (props.active === "inicio") {
    borderBottomInicio = "active link";
    borderBottomjogar = " link";
  } else if (props.active === "jogar") {
    borderBottomInicio = " link";
    borderBottomjogar = "active link";
  } else if (props.active === "noticias") {
    borderBottomInicio = " link";
    borderBottomjogar = " link";
  }
  return (
    <LeftContainer>
      <Link to="/">
        <HeaderLogo src={Logo} alt="GoGamers" />
      </Link>
      <HeaderUl>
        <Link to="/">
          <li className={borderBottomInicio}>
            <ButtonSection>
              <Home size="18" />
              <p>Início</p>
            </ButtonSection>
          </li>
        </Link>
        {/* <Link to="/championships">
          <li className={borderBottomjogar}>
            <ButtonSection>
              <Trophy size="18" />
              <p>Campeonatos</p>
            </ButtonSection>
          </li>
        </Link> */}
      </HeaderUl>
    </LeftContainer>
  );
}
