import styled from "styled-components";
import { device } from "@utils/index";

export const Layout = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 80%;
  padding: 1.5rem 0 1.5rem 0;
  margin-inline: auto;
  color: var(--text-color);
  flex-direction: column;

  @media ${device.tablet} {
    width: 90%;
  }
`;

export const ContainerBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    width: 90%;
  }
`;
