import ChampionshipsSession from "./components/championshipsSession";
import AreaEventos from "./components/areaEventos";
import AreaPatrocinadores from "./components/areaPatrocinadores";
import Welcome from "./components/welcome";
import FAQ from "./components/faq";
import Layout from "@components/template/layout";
import TwitchEmbedCard from "@components/twitchEmbedCard";
import AreaMidia from "./components/areaNaMidia";

const HomePage = () => {
  return (
    <Layout>
      <Welcome />
      <ChampionshipsSession />
      <TwitchEmbedCard />
      {/* <AreaPatrocinadores />
      <FAQ />
      <AreaEventos /> */}
      <AreaMidia />
    </Layout>
  );
};

export default HomePage;
