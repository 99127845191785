import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputProps,
} from "@chakra-ui/react";

interface Props extends InputProps {
  label?: string;
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { label, error, ...rest },
  ref
) => {
  const errorMessage = error?.message?.split("\n");
  return (
    <FormControl isInvalid={!!error}>
      {!!label && (
        <FormLabel
          htmlFor={rest.name}
          color={"#FFFFFF"}
          m={"2px"}
          fontSize={"1.1rem"}
        >
          {label}
        </FormLabel>
      )}
      <Input type="text" {...rest} ref={ref} color={"#FFFFFF"} />
      {!!error &&
        errorMessage?.map((line, index) => (
          <FormErrorMessage key={index}>{line}</FormErrorMessage>
        ))}
    </FormControl>
  );
};

const InputText = forwardRef(InputBase);

export default InputText;
