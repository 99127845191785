import useChampionship from "@hooks/useChampionship";
import Card from "./Card/Card";
import * as S from "./styles";
import { Link } from "react-router-dom";
import { ChampionshipsTranslate } from "@translate/championships.translate";

const ChampionshipsSession = () => {
  const { listChampionships } = useChampionship();

  return (
    <S.CampeonatosContainer>
      <S.CampeonatosTitle>CAMPEONATOS EM DESTAQUE</S.CampeonatosTitle>
      <S.MobileCarouselWrapper>
        {listChampionships?.map((item) => {
          const file = item.files.find(
            (item) => item.business_type === "CHAMPIONSHIP_CARD"
          );
          const game = item.entity.game.files.find(
            (item) => item.business_type === "GAMES_ICON"
          );
          return (
            <Link to={`/championship/${item.entity.id}`}>
              <Card
                key={item.entity.id}
                championshipName={item.entity.name}
                championshipImg={file?.url}
                gameImg={game?.url}
                date={new Date(item.entity.start_date)}
                currentUsers={item.entity.registered_participants_count}
                maxUsers={item.entity.participant_limit}
                squad={ChampionshipsTranslate(item.entity.modality)}
              />
            </Link>
          );
        })}
      </S.MobileCarouselWrapper>
    </S.CampeonatosContainer>
  );
};

export default ChampionshipsSession;
