import styled from "styled-components";

export const StylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#102131",
    padding: "24px",
    maxWidth: "450px",
    width: "100%",
    border: "2px solid var(--layout-color)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "1000",
  },
};
export const ModalContainerWrapper = styled.div`
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalTitle = styled.h2`
  color: white;
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 350px) {
    font-size: var(--font-size-base);
  }
`;

export const ModalIconCloseContainer = styled.div`
  position: absolute;
  top: 6%;
  right: 3%;

  .modal-icon-close {
    cursor: pointer;
    color: var(--layout-color);
  }
`;

export const ModalText = styled.p`
  margin-bottom: 0.5rem;
  color: white;
  font-style: normal;
  text-align: center;
  font-size: 14px;

  @media (max-width: 350px) {
    font-size: 11px;
  }
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalInput = styled.input`
  width: 100%;
  padding: 0.4rem 0.6rem;
  margin: 0.6rem 0 0.8rem 0;
  border: none;
  border-radius: 6px;
  font-size: var(--font-size-small);

  @media (max-width: 350px) {
    padding: 0.4rem 0.4rem;
    margin: 0.2rem 0 0.8rem 0;
  }
`;

export const ModalButton = styled.button`
  padding: 0.6rem 1.8rem;

  border: none;
  border-radius: 4px;
  box-shadow: 0rem 0rem 1rem var(--layout-color);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  color: var(--text-color);
  background: var(--layout-color);
  text-align: center;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 350px) {
    padding: 0.4rem 1.4rem;
  }
`;


export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 0.6rem 0.8rem;
  margin: 0.3rem 0 0.8rem 0;
  border-radius: 6px;
  border: 1px solid var(--text-color);
  margin-bottom: 1rem;
  font-size: 14px;
  color: var(--text-color);
  background-color: transparent;
`;

export const FormLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  text-align: left;
  width: 100%;
  margin-bottom: 0.5rem;
`;


export const FormError = styled.p`
  width: 100%;
  font-size: 14px;
  color: var(--layout-color);
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
`;


export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 20px;
`; 

export const LinkButton = styled.a`
  margin: 1rem 0;
  cursor: pointer;
  color: var(--text-color);
  font-size: 14px;
  text-decoration: underline;
  margin-right: 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: right;
  width: 100%;
`

export const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;