import { Flex, Text } from "@chakra-ui/react";
import { BaseResponse } from "@interfaces/base";
import {
  ChampionshipModalityType,
  IChampionships,
} from "@interfaces/championships.interface";
import { dateTimeFormat2 } from "@utils/date";
import InforCard from "../../components/inforCard";
import { IoCalendarSharp } from "react-icons/io5";
import { TbUsers } from "react-icons/tb";
import { RiSwordFill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { ChampionshipsTranslate } from "@translate/championships.translate";
import Awards from "@pages/championshipDetails/components/awards";

type Props = {
  championship?: BaseResponse<IChampionships>;
};

const Details: React.FC<Props> = ({ championship }) => {
  return (
    <>
      <Flex display={{ base: "none", lg: "flex" }} width="100%">
        <Flex flexDirection="column" flex="1">
          <Flex
            flexDirection="column"
            width="94%"
            dangerouslySetInnerHTML={{
              __html: championship?.entity.description!,
            }}
          ></Flex>
          <Flex flexDirection="column" width="90%" marginTop="20px">
            <Text fontSize="1.3rem" fontWeight="bold" color="#ffffff">
              Premiações
            </Text>
            <Awards
              awards={
                championship?.entity?.championship_awards?.length! > 0
                  ? championship?.entity.championship_awards!
                  : [1, 2, 3]
              }
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="6">
          <Text fontSize="1.5rem" fontWeight="400">
            INFORMAÇÕES
          </Text>
          <Flex flexDirection="column" gap="3">
            <Text fontSize="1.3rem" fontWeight="400" color="#ffffff">
              VALOR DE INSCRIÇÃO
            </Text>
            <Flex gap="6">
              <InforCard
                icon={
                  <RiMoneyDollarCircleFill
                    color={championship?.entity.paid ? "#E94560" : "#ffffff"}
                    size="40"
                  />
                }
                text={
                  championship?.entity.paid
                    ? `R$ ${championship.entity.paid_value}`
                    : "GRATUITO"
                }
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="3">
            <Text fontSize="1.3rem" fontWeight="400" color="#ffffff">
              INSCRIÇÕES
            </Text>
            <Flex gap="6">
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={`INÍCIO: ${dateTimeFormat2(championship?.entity?.registration_start_date!)}`}
              />
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={`TÉRMINO: ${dateTimeFormat2(championship?.entity?.registration_end_date!)}`}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="3">
            <Text fontSize="1.3rem" fontWeight="400" color="#ffffff">
              CAMPEONATO
            </Text>
            <Flex gap="6">
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={`INÍCIO: ${dateTimeFormat2(championship?.entity?.start_date!)}`}
              />
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={`TÉRMINO: ${dateTimeFormat2(championship?.entity?.end_date!)}`}
              />
            </Flex>
            <Flex gap="6">
              <InforCard
                icon={<TbUsers color="#E94560" size="32" />}
                text={`${championship?.entity.registered_participants_count}/${championship?.entity.participant_limit}`}
              />
              <InforCard
                icon={<RiSwordFill color="#E94560" size="32" />}
                text={`${ChampionshipsTranslate(championship?.entity.type!)}`}
              />
            </Flex>
            <Flex gap="6">
              <InforCard
                icon={<FaLocationDot color="#E94560" size="32" />}
                text={
                  championship?.entity.modality_type ===
                  ChampionshipModalityType.HYBRID
                    ? "ONLINE/PRESENCIAL"
                    : championship?.entity.modality_type!!
                }
              />
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={championship?.entity.game.entity.name!}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        display={{ base: "flex", lg: "none" }}
        flexDirection="column"
        padding="4"
      >
        <Flex flexDirection="column" flex="1">
          <Flex
            flexDirection="column"
            width="94%"
            dangerouslySetInnerHTML={{
              __html: championship?.entity.description!,
            }}
          ></Flex>
          <Flex flexDirection="column" width="90%" marginTop="20px">
            <Text fontSize="1.3rem" fontWeight="bold" color="#ffffff">
              Premiações
            </Text>
            <Awards
              awards={
                championship?.entity?.championship_awards?.length! > 0
                  ? championship?.entity.championship_awards!
                  : [1, 2, 3]
              }
            />
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="6">
          <Text fontSize="1.5rem" fontWeight="400">
            INFORMAÇÕES
          </Text>
          <Flex flexDirection="column" gap="3">
            <Text fontSize="1.3rem" fontWeight="400" color="#ffffff">
              VALOR DE INSCRIÇÃO
            </Text>
            <Flex flexDirection={{ base: "column", md: "row" }} gap="6">
              <InforCard
                icon={
                  <RiMoneyDollarCircleFill
                    color={championship?.entity.paid ? "#E94560" : "#ffffff"}
                    size="40"
                  />
                }
                text={
                  championship?.entity.paid
                    ? `R$ ${championship.entity.paid_value}`
                    : "GRATUITO"
                }
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="3">
            <Text fontSize="1.3rem" fontWeight="400" color="#ffffff">
              INSCRIÇÕES
            </Text>
            <Flex flexDirection={{ base: "column", md: "row" }} gap="6">
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={`INÍCIO: ${dateTimeFormat2(championship?.entity?.registration_start_date!)}`}
              />
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={`TÉRMINO: ${dateTimeFormat2(championship?.entity?.registration_end_date!)}`}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="3">
            <Text fontSize="1.3rem" fontWeight="400" color="#ffffff">
              CAMPEONATO
            </Text>
            <Flex flexDirection={{ base: "column", md: "row" }} gap="6">
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={`INÍCIO: ${dateTimeFormat2(championship?.entity?.start_date!)}`}
              />
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={`TÉRMINO: ${dateTimeFormat2(championship?.entity?.end_date!)}`}
              />
            </Flex>
            <Flex flexDirection={{ base: "column", md: "row" }} gap="6">
              <InforCard
                icon={<TbUsers color="#E94560" size="32" />}
                text={`${championship?.entity.registered_participants_count}/${championship?.entity.participant_limit}`}
              />
              <InforCard
                icon={<RiSwordFill color="#E94560" size="32" />}
                text={`${ChampionshipsTranslate(championship?.entity.type!)}`}
              />
            </Flex>
            <Flex flexDirection={{ base: "column", md: "row" }} gap="6">
              <InforCard
                icon={<FaLocationDot color="#E94560" size="32" />}
                text={
                  championship?.entity.modality_type ===
                  ChampionshipModalityType.HYBRID
                    ? "ONLINE/PRESENCIAL"
                    : championship?.entity.modality_type!!
                }
              />
              <InforCard
                icon={<IoCalendarSharp color="#E94560" size="32" />}
                text={championship?.entity.game.entity.name!}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Details;
