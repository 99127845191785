import PersonPlus from "@assets/ativos/svg/PersonPlus.svg";
import * as S from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { TeamMembersRoles } from "@interfaces/team.interface";
import { FileBussinessType } from "@interfaces/file.interface";
import { useDisclosure } from "@chakra-ui/react";
import InviteMemberModal from "../inviteMemberModal";
import { AuthenticatedUserDisplay } from "@components/authenticatedUserDisplay";
import useUser from "@hooks/useUser";
import useTeamPublic from "@hooks/useTeamPublic";

const Members: React.FC = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { teamMembers, team } = useTeamPublic(teamId);
  const { userAuth } = useUser();

  return (
    <S.Container>
      <S.MobileMembersWrapper>
        <AuthenticatedUserDisplay.True rule={team?.owner.id === userAuth?.id}>
          <S.NewMemberLinkContainer>
            <S.NewMemberLink onClick={onOpen}>
              <S.NewMemberIcon
                src={PersonPlus.toString()}
                alt="Adicionar membro"
              />
              Convidar jogadores
            </S.NewMemberLink>
          </S.NewMemberLinkContainer>
        </AuthenticatedUserDisplay.True>
        {teamMembers?.map((member) => {
          const fileMember = member.files.find(
            (item) =>
              item.business_type === FileBussinessType.USER_PROFILE_PHOTO
          );
          return (
            <S.MemberWrapper
              key={`member-${member.entity.id}`}
              onClick={() => navigate(`/profile/${member.entity.user_id}`)}
            >
              <S.MemberImg
                src={
                  fileMember
                    ? fileMember.url
                    : "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"
                }
              />
              {member.entity.role === TeamMembersRoles.OWNER && (
                <S.MemberLeader>⭐ Líder do time</S.MemberLeader>
              )}
              <S.MemberName>{member.entity.user_name}</S.MemberName>
            </S.MemberWrapper>
          );
        })}
      </S.MobileMembersWrapper>
      <InviteMemberModal teamId={teamId!} isOpen={isOpen} onClose={onClose} />
    </S.Container>
  );
};

export default Members;
