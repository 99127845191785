const breakpoints = {
  mobileSmall: "320px",
  mobileMedium: "375px",
  mobileLarge: "425px",
  tablet: "768px",
  desktopSmall: "1024px",
  desktopMedium: "1440px",
  desktopLarge: "2560px",
};

export const device = {
  mobileSmall: `(max-width: ${breakpoints.mobileSmall})`,
  mobileMedium: `(max-width: ${breakpoints.mobileMedium})`,
  mobileLarge: `(max-width: ${breakpoints.mobileLarge})`,
  tablet: `(max-width: ${breakpoints.tablet})`,
  desktopSmall: `(max-width: ${breakpoints.desktopSmall})`,
  desktopLarge: `(max-width: ${breakpoints.desktopLarge})`,
};

export const modalGlobalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#102131",
    border: "none",
    padding: "24px",
    maxWidth: "315px",
    width: "100%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "1000",
  },
};
