import { Flex, Spinner, Text } from "@chakra-ui/react";
import { AuthenticatedUserDisplay } from "@components/authenticatedUserDisplay";
import useUser from "@hooks/useUser";
import {
  ChampionshipType,
  IParticipant,
  ParticipantSituation,
} from "@interfaces/championships.interface";
import CardParticipants from "@pages/championshipDetails/components/cardParticipants";
import { ParticipantSituationTranslate } from "@translate/geral.translate";

type Props = {
  participantList: IParticipant[];
  championshipsType: ChampionshipType;
  loading: boolean;
};

const Participants: React.FC<Props> = ({
  participantList,
  championshipsType,
  loading,
}) => {
  const { userAuth } = useUser();
  const myTeamsId = userAuth?.teams.map((item) => item.entity.id);
  const participant = participantList.find(
    (item) => item.participant.entity_id == userAuth?.id!
  );
  const myTeamParticipant = participantList.find((item) =>
    myTeamsId?.includes(item.participant.entity_id)
  );

  const colorStatus = (situation: ParticipantSituation) => {
    return {
      PENDING: "#FFFF03",
      ACCEPTED: "#21FF03",
      CANCELLED: "#FF2903",
    }[situation];
  };

  const teamStatus = () => {
    return (
      <>
        STATUS DA EQUIPE:
        <Text
          casing="uppercase"
          color={colorStatus(myTeamParticipant?.participant.situation!)}
          fontSize="1.2rem"
        >
          {ParticipantSituationTranslate(
            myTeamParticipant?.participant.situation!
          )}
        </Text>
      </>
    );
  };

  const individualStatus = () => {
    return (
      <>
        STATUS DO PARTICIPANTE:
        <Text
          casing="uppercase"
          color={colorStatus(participant?.participant.situation!)}
        >
          {ParticipantSituationTranslate(participant?.participant.situation!)}
        </Text>
      </>
    );
  };

  return (
    <Flex flexDir="column" gap="40px" paddingTop="15px">
      <AuthenticatedUserDisplay.True
        rule={!!myTeamParticipant || !!participant}
      >
        <Flex fontSize="1.2rem" gap="4px">
          {championshipsType === ChampionshipType.TEAM
            ? teamStatus()
            : individualStatus()}
        </Flex>
      </AuthenticatedUserDisplay.True>
      <Flex bg="#0F1B34" flexDir="column" padding="18px" gap="31px">
        <Text fontSize="1.3rem" color="#ffffff" fontWeight="bold">
          {championshipsType === ChampionshipType.TEAM && "EQUIPE "}
          PARTICIPANTES
        </Text>
        {loading ? (
          <Flex w="100%" justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#E94560"
              size="xl"
            />
          </Flex>
        ) : (
          <Flex
            flexWrap="wrap"
            gap="20px"
            className="ScrollBar"
            overflowY="auto"
            h="480px"
          >
            {participantList.map((item) => {
              return (
                <CardParticipants
                  name={item.entity.name}
                  id={item.participant.entity_id}
                  type={item.participant.business_type}
                  background={item.entity.files[1]?.url}
                  logo={item.entity.files[0]?.url}
                  maxUsers={7}
                  listMembers={item.entity.team_members || []}
                  situation={item.participant.situation!}
                />
              );
            })}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Participants;
