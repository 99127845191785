import { useParams } from "react-router-dom";
import GeneralProfile from "./tabs/generalProfile";
import MyTeams from "./tabs/myTeams";
import * as S from "./styles";
import Layout from "@components/template/layout";
import {
  Flex,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import ImageHover from "@components/imageHover";
import ImageCropper from "@components/imageCropper";
import { FileBussinessType, FileEntityType } from "@interfaces/file.interface";
import Modal from "@components/template/modal";
import { useQueryClient } from "@tanstack/react-query";
import { optionsFetchUserByIdPublic } from "@plugins/reactQuery/user.query-options";
import useUserPublic from "@hooks/useUserPublic";
import useAuth from "@hooks/useAuth";
import useUser from "@hooks/useUser";
import { QUERY_KEY_USER } from "../../constants/queryKeys.constants";
import ProfileSettings from "./tabs/settings";
import { AuthenticatedUserDisplay } from "@components/authenticatedUserDisplay";

const Profile: React.FC = () => {
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const modalUpdateBanner = useDisclosure();
  const modalUpdatePhoto = useDisclosure();
  const { user, avatarFile, bannerFile } = useUserPublic(userId!);
  const { isAuthenticated } = useAuth();
  const { userAuth } = useUser();

  const closeModal = () => {
    modalUpdateBanner.onClose();
    modalUpdatePhoto.onClose();
    queryClient.invalidateQueries(optionsFetchUserByIdPublic(userId!));
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY_USER.PROFILE] });
  };

  return (
    <>
      <Layout>
        <ImageHover
          hoverText="Atualizar Banner"
          handlerClick={modalUpdateBanner.onOpen}
          imgPath={bannerFile?.url!}
          isEdit={isAuthenticated && user?.id === userAuth?.id}
          altImage="user_banner"
          styleContainer={{
            width: "99vw",
            height: "430px",
            marginBottom: "-5rem",
          }}
        />
        <Flex alignItems={"flex-start"} flexDir={"column"} w={"100%"}>
          <S.ProfileAvatarWrapper>
            <ImageHover
              imgPath={avatarFile?.url!}
              hoverText="Atualizar Perfil"
              altImage="user_avatar"
              handlerClick={modalUpdatePhoto.onOpen}
              isEdit={isAuthenticated && user?.id === userAuth?.id}
              styleContainer={{
                borderRadius: "50%",
              }}
            />
            <S.ProfileAvatarTextWrapper>
              <S.ProfileNameContainer>
                <Skeleton
                  isLoaded={!!user}
                  style={{ width: "100%", borderRadius: "1rem" }}
                  minH="26px"
                  startColor="#E2E8F0"
                  endColor="#718096"
                >
                  <S.ProfileName>{user?.name}</S.ProfileName>
                </Skeleton>
              </S.ProfileNameContainer>
              <S.LargeBadgesContainer></S.LargeBadgesContainer>
            </S.ProfileAvatarTextWrapper>
          </S.ProfileAvatarWrapper>
          <Tabs w={"100%"} colorScheme={"#FFFFFF"}>
            <TabList borderBottomColor={"#E94560"}>
              <Tab>GERAL</Tab>
              <Tab>SEUS TIMES</Tab>
              <AuthenticatedUserDisplay.True
                rule={isAuthenticated && user?.id === userAuth?.id}
              >
                <Tab>CONFIGURAÇÕES</Tab>
              </AuthenticatedUserDisplay.True>
            </TabList>
            <TabPanels>
              <TabPanel>
                <GeneralProfile />
              </TabPanel>
              <TabPanel>
                <MyTeams />
              </TabPanel>
              <AuthenticatedUserDisplay.True
                rule={isAuthenticated && user?.id === userAuth?.id}
              >
                <TabPanel>
                  <ProfileSettings />
                </TabPanel>
              </AuthenticatedUserDisplay.True>
            </TabPanels>
          </Tabs>
        </Flex>
      </Layout>
      <Modal
        title="Foto de Perfil"
        isOpen={modalUpdatePhoto.isOpen}
        handlerCancel={modalUpdatePhoto.onClose}
        isFooter={false}
      >
        <ImageCropper
          handlerCompleted={closeModal}
          crop={{ height: 270, width: 270, isCircularCrop: true }}
          file={{
            id: avatarFile?.id,
            url: avatarFile?.url!,
            entity_id: user?.id!,
            business_type: FileBussinessType.USER_PROFILE_PHOTO,
            entity_type: FileEntityType.USER,
          }}
        />
      </Modal>
      <Modal
        title="Foto de Banner"
        isOpen={modalUpdateBanner.isOpen}
        handlerCancel={closeModal}
        isFooter={false}
        size="6xl"
      >
        <ImageCropper
          isBanner
          handlerCompleted={closeModal}
          crop={{ height: 270, width: 1920, isCircularCrop: false }}
          file={{
            id: bannerFile?.id,
            url: bannerFile?.url!,
            entity_id: user?.id!,
            business_type: FileBussinessType.USER_PROFILE_BANNER,
            entity_type: FileEntityType.USER,
          }}
        />
      </Modal>
    </>
  );
};

export default Profile;
