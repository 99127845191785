import ImgLogo from "@assets/ativos/png/Big_logo.png";

import { LogoContainer, LogoImg } from "./styles";

function Logo() {
  return (
    <LogoContainer>
      <LogoImg src={ImgLogo} alt="ProGamers logo" />
    </LogoContainer>
  );
}

export default Logo;
