import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AlignJustify, Bell } from "lucide-react";
import NotificationArea from "./components/notificationArea";
import * as S from "./styles";
import { AuthenticatedUserDisplay } from "@components/authenticatedUserDisplay";
import useTeam from "@hooks/useTeam";
import useUser from "@hooks/useUser";
import { IoIosArrowDown } from "react-icons/io";
import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import useAuth from "@hooks/useAuth";

export default function HeaderRight() {
  const navidate = useNavigate();
  const { userAuth, avatarFile } = useUser();
  const { notificationByUser } = useTeam();
  const { logout } = useAuth();
  const [sidebarIsActive, setSidebarIsActive] = useState<boolean>(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    function handleDocumentClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownActive(false);
      }
    }

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  function toggleDropdownActivation(event) {
    event.stopPropagation();
    setDropdownActive(!dropdownActive);
  }

  function handleDropdownMouseLeave() {
    setDropdownActive(false);
  }

  const showSidebar = () => setSidebarIsActive(!sidebarIsActive);

  return (
    <S.RightContainer>
      <S.ContainerButtonWrapper>
        {/* <S.ContainerLinkAssinar to="">Assinar</S.ContainerLinkAssinar> */}
        <AuthenticatedUserDisplay.True>
          <S.ContainerUserWrapper>
            <Bell size={18} onClick={toggleDropdownActivation} />
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<IoIosArrowDown color="#E94560" />}
                bg="none"
                _hover={{ background: "none" }}
                _active={{ background: "none" }}
              >
                <Avatar
                  src={
                    avatarFile?.url
                      ? avatarFile?.url
                      : "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"
                  }
                  size="md"
                />
              </MenuButton>
              <MenuList bg="#0D2134" borderColor="#E94560">
                <MenuItem
                  bg="#0D2134"
                  onClick={() => navidate(`/profile/${userAuth?.id}`)}
                >
                  Perfil
                </MenuItem>
                <MenuItem bg="#0D2134" onClick={logout}>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
            {dropdownActive && (
              <NotificationArea
                onMouseLeave={handleDropdownMouseLeave}
                notificationList={notificationByUser}
              />
            )}
          </S.ContainerUserWrapper>
        </AuthenticatedUserDisplay.True>
        <AuthenticatedUserDisplay.False>
          <Link to="/login">
            <S.ContainerLinkEntrar>Entrar</S.ContainerLinkEntrar>
          </Link>
        </AuthenticatedUserDisplay.False>
        {/* <S.ContainerMobileMenuWrapper>
          <Sidebar
            setSidebarIsActive={setSidebarIsActive}
            sidebarIsActive={sidebarIsActive}
          />
          <button onClick={showSidebar}>
            <AlignJustify />
          </button>
        </S.ContainerMobileMenuWrapper> */}
      </S.ContainerButtonWrapper>
    </S.RightContainer>
  );
}
