import { DISCORD_URL } from "@utils/const";
import {
  SuporteContainer,
  SuporteTitle,
  SuporteContentWrapper,
  SuporteLinkWrapper,
  SuporteLink,
} from "./styles";

function Suporte() {
  return (
    <SuporteContainer>
      <SuporteContentWrapper>
        <SuporteTitle>Suporte</SuporteTitle>

        <SuporteLinkWrapper>
          <SuporteLink href={DISCORD_URL} target="blank">Fale Conosco</SuporteLink>
        </SuporteLinkWrapper>
      </SuporteContentWrapper>
    </SuporteContainer>
  );
}

export default Suporte;
