import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Progress,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const UserNotRegisterModal: React.FC<Props> = ({ isOpen, onClose }) => {
  if (!isOpen) return;
  const navigate = useNavigate();
  const [progress, setProgress] = useState<number>(0);

  const duration = 140 * 60;
  const interval = 280;
  const increment = (interval / duration) * 100;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev + increment >= 100) {
          clearInterval(timer);
          navigate("/register");
          return 100;
        }
        return prev + increment;
      });
    }, interval);
    return () => clearInterval(timer);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="#0C1A27CF" />
      <ModalContent border="1px solid #E94560" bg="#010101F0" color="#ffffff">
        <ModalBody>
          <Text fontSize="20px" fontWeight="400" m="19px">
            VOCÊ SERÁ REDIRECIONADO PARA TELA DE LOGIN PARA CONCLUIR SUA
            INSCRIÇÃO NO CAMPEONATO.
          </Text>
        </ModalBody>
        <Progress
          value={progress}
          size="xs"
          h="6px"
          colorScheme="red"
          marginBottom="5px"
        />
      </ModalContent>
    </Modal>
  );
};
export default UserNotRegisterModal;
