import { useState } from "react";
import { Box, Image, Flex, Text, Heading } from "@chakra-ui/react";

const Badget = () => {
  const badgetData: any[] = [];

  const [visibleDescriptionIndex, setVisibleDescriptionIndex] = useState(null);

  // TODO - [MICHEL]: Revisar tipagem
  const handleIconClick = (index: any) => {
    setVisibleDescriptionIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  return (
    <Flex flexDir={"column"} w={"100%"} gap={"12px"}>
      <Text fontSize={"1.5rem"} fontWeight={"bold"}>
        Conquistas
      </Text>
      <Box
        bg="#0C1A27"
        w={{ base: "100%", sm: "233px", md: "600px", lg: "100%" }}
        h={{ base: "225px", sm: "233px", md: "233px" }}
        minH="233px"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        boxShadow="4px 4px 6px 2px rgba(0, 0, 0, 0.50)"
        overflow="hidden"
        boxSizing="border-box"
      >
        <Flex
          justify="flex-start"
          w="100%"
          h="170px"
          mt="50px"
          overflowX="auto"
          p="0 20px 20px"
          sx={{
            "&::-webkit-scrollbar": { height: "8px" },
            "&::-webkit-scrollbar-track": {
              background: "#1C2A3A",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#3B4A5A",
              borderRadius: "10px",
              border: "2px solid transparent",
              backgroundClip: "content-box",
            },
            "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#606C78" },
          }}
        >
          {badgetData.map((badge, index) => (
            <Box
              key={badge.id}
              cursor="pointer"
              position="relative"
              m={{ base: "5px", sm: "10px", md: "15px" }}
              mt="50px"
              flexShrink="0"
              w={{ base: "60px", sm: "80px", md: "100px" }}
              h={{ base: "60px", sm: "80px", md: "100px" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              _hover={{
                boxShadow: "0px 0px 15px 5px rebeccapurple",
                transition: "box-shadow 0.3s ease",
              }}
              onClick={() => handleIconClick(index)}
            >
              <Box
                position="absolute"
                top={{ base: "-40px", sm: "80px", md: "-15px" }}
                left="50%"
                transform="translateX(-50%)"
                bg="rgba(0, 0, 0, 0.92)"
                color="white"
                borderRadius="4px"
                p="10px"
                display={visibleDescriptionIndex === index ? "flex" : "none"}
                flexDir="column"
                alignItems="center"
                zIndex="1"
                w={{ base: "80px", sm: "125px", md: "140px" }}
                h={{ base: "50px", sm: "125px", md: "80px" }}
                textAlign="center"
              >
                <Heading
                  as="h3"
                  fontSize={{ base: "8px", sm: "12px" }}
                  mb="3px"
                  w="70px"
                  fontWeight="lighter"
                >
                  {badge.title}
                </Heading>
                <Text
                  fontSize={{ base: "9px", sm: "13px" }}
                  color="rebeccapurple"
                  fontWeight="bold"
                >
                  {badge.description}
                </Text>
              </Box>
              <Image
                src={badge.imgSrc}
                alt={`Ícone de ${badge.title}`}
                boxSize={{ base: "60px", sm: "80px", md: "100px" }}
                objectFit="cover"
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};

export default Badget;
