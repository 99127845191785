import styled from "styled-components";
import { device } from "@utils/index";
import { Link } from "react-router-dom";

export const LineTop = styled.div`
  height: 4px;
  width: 99vw;
  margin-top: -14px;
  margin-bottom: 14px;
  background-image: linear-gradient(
    to right,
    transparent,
    #e9445f,
    transparent
  );
`;

export const RegisterFormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${device.tablet} {
    margin-bottom: 4rem;
  }

  @media ${device.mobileLarge} {
    margin-bottom: 1rem;
  }
`;
export const FormContainer = styled.div`
  align-items: center;
  max-width: 560px;
  width: 100%;

  @media ${device.tablet} {
    max-width: 350px;
  }

  @media ${device.mobileLarge} {
    max-width: 250px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 1rem;
  color: var(--layout-color);

  font-size: 36px;
  font-weight: 600;
  text-align: center;

  @media ${device.tablet} {
    margin-bottom: 2rem;
    font-size: 30px;
  }

  @media ${device.mobileLarge} {
    font-size: 24px;
  }
`;

export const RegisterForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .inputFormSelect {
    width: 100%;
    border: 1px solid var(--text-color);
    border-radius: 6px;

    margin: 0.3rem 0 0.8rem 0;
    padding: 0.1rem 0.51rem;

    @media ${device.mobileLarge} {
      margin-bottom: 0.5rem;
      padding: 0.1rem 0.4rem;

      font-size: 12px;
    }
  }
`;

export const FormLabel = styled.label`
  width: 100%;
  color: var(--text-color);

  font-weight: 600;
  text-align: left;

  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobileLarge} {
    font-size: 12px;
  }
`;

export const FormInput = styled.input`
  width: 100%;

  outline: none;
  border: 1px solid var(--text-color);
  border-radius: 6px;

  padding: 0.6rem 0.8rem;
  margin: 0.3rem 0 0.8rem 0;

  color: var(--text-color);
  background-color: transparent;
  font-size: 16px;

  @media ${device.mobileLarge} {
    margin-bottom: 0.5rem;
    padding: 0.4rem 0.6rem;

    font-size: 12px;
  }
`;
export const FormError = styled.p`
  width: 100%;
  margin-top: -10px;
  color: var(--layout-color);

  font-size: 14px;
  text-align: left;

  @media ${device.mobileLarge} {
    margin-bottom: 1px;
    font-size: 12px;
  }
`;

export const FormSelect = styled.select`
  width: 100%;

  outline: none;
  border: none;

  margin-bottom: 0;
  padding-block: 0.5rem;

  color: var(--placeholder-form-color);
  background-color: transparent;
  font-size: 16px;

  option {
    color: var(--option-form-color);
  }

  @media ${device.mobileLarge} {
    padding-block: 0.269rem;

    font-size: 12px;
  }
`;

export const RegisterCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

export const RegisterInputCheckbox = styled.input`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 1rem;
  accent-color: var(--layout-color);

  @media ${device.mobileLarge} {
    width: 1.3rem;
    height: 1.3rem;
  }
`;

export const RegisterCheckboxLabel = styled.a`
  border-bottom: 2px solid var(--layout-color);
  color: var(--layout-color);
  text-transform: underline;
  cursor: pointer;
`;

export const RegisterLink = styled.p`
  margin: 1rem 0;

  color: var(--text-color);
  text-align: center;
  font-size: 16px;

  @media ${device.mobileLarge} {
    font-size: 14px;
  }
`;

export const RegisterSpan = styled(Link)`
  border-bottom: 2px solid var(--layout-color);
  color: var(--layout-color);
  font-size: 18px;

  @media ${device.mobileLarge} {
    font-size: 16px;
  }
`;

export const PrimaryAction = styled.button`
  margin-top: 2rem;
  padding: 0.8rem 3rem;

  box-shadow: var(--box-shadow-default);
  border: 1px solid var(--layout-color);
  border-radius: 0.5rem;

  cursor: pointer;
  color: var(--text-color);
  background: var(--layout-color);

  font-size: 16px;

  @media ${device.tablet} {
    padding: 0.6rem 2.5rem;
  }

  @media ${device.mobileLarge} {
    margin-top: 1rem;
    padding: 0.5rem 2rem;
    font-size: 14px;
  }
`;
