import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ItemValue = styled.p`
  color: var(--text-color);
  font-size: var(--font-size-base);
  font-weight: 500;
  text-align: left;
`;

export const ItemKey = styled.p`
  color: #b2b2b2;
  font-size: var(--font-size-medium);
  font-weight: 300;
  text-transform: uppercase;
  text-align: right;
`;
