import {
  ICreateTeamRequest,
  IInviteMemberRequest,
  IUpdateTeamRequest,
  TeamMembersRoles,
} from "@interfaces/team.interface";
import teamService from "@services/team.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuth from "./useAuth";
import useUser from "./useUser";
import {
  optionsFetchTeamByIdPublic,
  optionsFetchTeamNotifications,
} from "@plugins/reactQuery/queryOptions.reactquery";
import useTeamPublic from "./useTeamPublic";

const useTeam = (teamId?: string) => {
  const queryClient = useQueryClient();
  const { searchUser } = useUser();
  const { isAuthenticated } = useAuth();
  const { teamMembers } = useTeamPublic(teamId);
  const {
    postCreate,
    postSendInvite,
    putUpdate,
    patchAcceptionInvitation,
    patchDeclineInvitation,
  } = teamService();
  const navigator = useNavigate();

  const { data: teamNotificationUser } = useQuery(
    optionsFetchTeamNotifications(isAuthenticated)
  );

  const createTeam: SubmitHandler<ICreateTeamRequest> = async (
    values,
    event
  ) => {
    event?.preventDefault();
    const result = await postCreate(values);
    toast.success("Time criado com sucesso.");
    navigator(`/team/${result.id}`);
  };

  const sendInvitation = async (data: IInviteMemberRequest) => {
    await postSendInvite(teamId!, data);
    toast.success("Convite enviado com Sucesso.");
  };

  const acceptionInvitation = async (teamId: string, invitationId: string) => {
    await patchAcceptionInvitation(teamId, invitationId);
    toast.success("Convite Aceito.");
  };

  const declineInvitation = async (teamId: string, invitationId: string) => {
    await patchDeclineInvitation(teamId, invitationId);
    toast.success("Convite Recusado.");
  };

  const invite = async (value: string) => {
    await sendInvitation({
      userId: value,
      role: TeamMembersRoles.PLAYER,
    });
  };

  const searchUsersTeamInvitation = (valeu: string) => {
    const teamMembersId = teamMembers?.map((item) => item.entity.id);
    const listUsers = searchUser(valeu);
    return listUsers
      ?.filter((item) => !teamMembersId?.includes(item.entity.id))
      ?.map((item) => item);
  };

  const updateTeam = async (teamId: string, data: IUpdateTeamRequest) => {
    await putUpdate(teamId, data);
    toast.success("Time atualizado com sucesso.");
    queryClient.invalidateQueries(optionsFetchTeamByIdPublic(teamId));
  };

  return {
    createTeam,
    notificationByUser: teamNotificationUser,
    acceptionInvitation,
    sendInvitation,
    declineInvitation,
    invite,
    searchUsersTeamInvitation,
    updateTeam,
  };
};

export default useTeam;
