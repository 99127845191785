import { device } from "@utils/index";
import styled from "styled-components";

export const LeftContainer = styled.div`
  display: flex;
  gap: 4rem;

  @media ${device.desktopSmall} {
    gap: 2rem;
  }
`;

export const HeaderLogo = styled.img`
  width: 10rem;
  transition: 0.2s ease-in;

  &:hover {
    transform: scale(1.1);
    transition: 0.2s ease-in;
  }

  @media ${device.tablet} {
    width: 7rem;
  }
`;

export const HeaderUl = styled.ul`
  display: flex;
  align-items: center;

  li {
    display: flex;
    position: relative;
    padding: 0.5rem 2rem;

    list-style: none;
    color: var(--text-color);
    transition: 0.2s ease-in;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
  }

  li.active::after {
    position: absolute;
    bottom: -0.5rem;
    left: 0rem;
    z-index: 0;
    content: "";

    height: 0.25rem;
    width: 100%;

    box-shadow: var(--box-shadow-default);
    background: var(--layout-color);
  }

  li:hover {
    box-shadow: var(--box-shadow-default);
    transition: 0.2s ease-in;
    background: var(--layout-color);
  }

  @media ${device.tablet} {
    display: none;
    list-style-type: none;

    li {
      padding: 0.6rem 1.6rem;
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
`;
