import { useQuery } from "@tanstack/react-query";
import {
  optionsFetchTeamByIdPublic,
  optionsFetchTeamMembersByIdPublic,
} from "@plugins/reactQuery/queryOptions.reactquery";
import { FileBussinessType } from "@interfaces/file.interface";

const useTeamPublic = (teamId?: string) => {
  const { data: team } = useQuery(optionsFetchTeamByIdPublic(teamId!));

  const files = team?.files || [];
  const avatarFile = files.find(
    (item) => item.business_type === FileBussinessType.TEAM_PROFILE_PHOTO
  );

  const bannerFile = files.find(
    (item) => item.business_type === FileBussinessType.TEAM_BANNER_PHOTO
  );

  const { data: teamMembers } = useQuery(
    optionsFetchTeamMembersByIdPublic(teamId!)
  );

  return {
    team: team?.entity,
    teamFiles: team?.files,
    avatarFile,
    bannerFile,
    teamMembers,
  };
};

export default useTeamPublic;
