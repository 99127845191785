import {
  QuemSomosContainer,
  QuemSomosTitle,
  QuemSomosContentWrapper,
  QuemSomosText,
  QuemSomosAction,
} from "./styles";

function QuemSomos() {
  return (
    <QuemSomosContainer>
      <QuemSomosContentWrapper>
        <QuemSomosTitle>Quem Somos</QuemSomosTitle>

        <QuemSomosText>
          Somos apaixonados por <br /> e-sports,criando competições envolventes.
          Conectamos jogadores e fãs em emocionantes narrativas virtuais.
        </QuemSomosText>

        <QuemSomosAction href="https://gogamers.pro/quem-somos">
          Conheça a equipe
        </QuemSomosAction>
      </QuemSomosContentWrapper>
    </QuemSomosContainer>
  );
}

export default QuemSomos;
