import { progamersApi } from "@config/axios.config";
import { BaseResponse, PaginationResponse } from "@interfaces/base";
import {
  ChampionshipStatus,
  IChampionships,
  IChampionshipsTournament,
  IParticipant,
} from "@interfaces/championships.interface";

export default function championshipService() {
  const getChampionshipByStatus = async (
    status: ChampionshipStatus
  ): Promise<BaseResponse<IChampionships>[]> => {
    const result = await progamersApi.get(
      `/public/championship/status/${status}`
    );
    return result.data;
  };

  const getChampionshipByIdPublic = async (
    id: string
  ): Promise<BaseResponse<IChampionships>> => {
    const result = await progamersApi.get(`/public/championships/${id}`);
    return result.data;
  };

  const getChampionshipsPublic = async (): Promise<
    PaginationResponse<BaseResponse<IChampionships>>
  > => {
    const { data } = await progamersApi.get(`/public/championships`, {
      params: {
        limit: 20,
      },
    });
    return data;
  };

  const postRegisterUserChampionship = async (championshipId: string) => {
    await progamersApi.post(`championships/registration/${championshipId}`);
  };

  const postRegisterTeamChampionship = async (
    championshipId: string,
    teamId: string
  ) => {
    await progamersApi.post(
      `championships/registration/${championshipId}/team/${teamId}`
    );
  };

  const getUserRegister = async (
    championshipId: string
  ): Promise<IParticipant[]> => {
    const { data } = await progamersApi.get(
      `championships/registration/${championshipId}`
    );
    return data;
  };

  const getAllParticipantsPublic = async (
    id: string
  ): Promise<IParticipant[]> => {
    const result = await progamersApi.get(
      `/public/championships/registration/${id}`
    );
    return result.data;
  };

  const getChampionshipsTournamentPublic = async (
    id: string
  ): Promise<IChampionshipsTournament[]> => {
    const result = await progamersApi.get(
      `/public/championships/tournament/${id}`
    );
    return result.data;
  };

  return {
    getChampionshipByStatus,
    getChampionshipByIdPublic,
    getChampionshipsPublic,
    postRegisterUserChampionship,
    postRegisterTeamChampionship,
    getUserRegister,
    getAllParticipantsPublic,
    getChampionshipsTournamentPublic,
  };
}
