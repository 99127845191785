import {
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import SetupSettings from "./tabs/setup";

const ProfileSettings: React.FC = () => {
  return (
    <Flex>
      <Tabs w={"100%"} colorScheme={"#FFFFFF"} variant={"unstyled"}>
        <TabList borderBottomColor={"#E94560"}>
          <Tab>SETUP</Tab>
        </TabList>
        <TabIndicator mt="-1.5px" height="2px" bg="#E94560" />
        <TabPanels>
          <TabPanel>
            <SetupSettings />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default ProfileSettings;
