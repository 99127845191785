import * as S from "./styles";

type Props = {
  keyProp: string;
  value?: string;
};

const InforCardItem: React.FC<Props> = ({ keyProp, value }) => (
  <S.Item>
    <S.ItemValue>{value}</S.ItemValue>
    <S.ItemKey>{keyProp}</S.ItemKey>
  </S.Item>
);

export default InforCardItem;
