import {
  AcessoRapidoContainer,
  AcessoRapidoTitle,
  AcessoRapidoContentWrapper,
  AcessoRapidoLinkWrapper,
  AcessoRapidoLink,
} from "./styles";

function AcessoRapido() {
  return (
    <AcessoRapidoContainer>
      <AcessoRapidoContentWrapper>
        <AcessoRapidoTitle>Acesso Rápido</AcessoRapidoTitle>

        <AcessoRapidoLinkWrapper>
          <AcessoRapidoLink href="/">Início</AcessoRapidoLink>
          <AcessoRapidoLink href="/championships">Campeonatos</AcessoRapidoLink>
          <AcessoRapidoLink href="/settings">configuracao</AcessoRapidoLink>
          <AcessoRapidoLink href="#">FAQ</AcessoRapidoLink>
        </AcessoRapidoLinkWrapper>
      </AcessoRapidoContentWrapper>
    </AcessoRapidoContainer>
  );
}

export default AcessoRapido;
