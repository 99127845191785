import styled from "styled-components";

export const StylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#102131",
    border: "none",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: "1000",
  },
};

export const ModalContainerWrapper = styled.div`
  width: 40vw;
  height: 80vh;
  color: #ffffff;
  overflow-y: auto;

  @media only screen and (max-width: 600px) {
    width: 90vw;
  }
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid var(--layout-color);
  padding: 24px 24px 15px 24px;
`;
export const ModalTitleContainer = styled.div``;

export const ModalTitle = styled.h2`
  color: var(--text-color);
  font-size: var(--font-size-large);

  @media (max-width: 350px) {
    font-size: var(--font-size-base);
  }
`;
export const ModalSubTitle = styled.h5`
  color: var(--text-color);
  font-size: var(--font-size-small);
  font-weight: var(--font-regular);

  @media (max-width: 350px) {
    display: none;
  }
`;
export const ModalIconCloseContainer = styled.div`
  position: absolute;
  top: 3%;
  right: 3%;

  .modal-icon-close {
    cursor: pointer;
    color: var(--layout-color);
  }
`;

export const ModalContainerFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 24px 0px 24px;
  margin-bottom: 24px;

  @media (max-width: 350px) {
    padding: 15px 24px 0px 24px;
  }
`;
export const ModalContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
export const ModalButtonDisagree = styled.button`
  border: 1px solid var(--layout-color);
  border-radius: 4px;

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  padding: 0.9rem 1.8rem;
  color: var(--layout-color);
  background: transparent;
  text-align: center;

  &:hover {
    color: var(--text-color);
    box-shadow: 0rem 0rem 1rem var(--layout-color);
    transition: all 0.3s ease-in-out;
    background: var(--layout-color);
  }

  @media (max-width: 350px) {
    padding: 0.4rem 1.4rem;
  }
`;
export const ModalButtonAccept = styled.button`
  border: none;
  border-radius: 4px;
  box-shadow: 0rem 0rem 1rem var(--layout-color);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  padding: 0.9rem 2.234rem 0.9rem 2.234rem;
  color: var(--text-color);
  background: var(--layout-color);
  text-align: center;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 350px) {
    padding: 0.462rem 1.834rem;
  }
`;

export const ContainerBody = styled.div`
  padding: 30px;
  text-align: justify;

  @media only screen and (max-width: 600px) {
    & p {
      text-align: initial;
    }
  }

  & p {
    text-indent: 2.5rem;
    margin-top: 10px;
  }

  & ul {
    margin-top: 12px;
  }
`;
