import InfoCard from "../infoCard";
import * as S from "./styles";
import { useParams } from "react-router-dom";
import useTeamPublic from "@hooks/useTeamPublic";

const TeamProfile: React.FC = () => {
  const { teamId } = useParams();
  const { team } = useTeamPublic(teamId);

  return (
    <S.Container>
      <S.ProfileWrapper>
        <S.LeftWrapper>
          <S.Title>
            Descrição
            {/* <AuthenticatedUserDisplay.True
              rule={team?.owner.id === userAuth?.id}
            >
              <a href="#">
                <S.EditProfileImg src={PencilSquare} alt="PencilSquare" />
              </a>
            </AuthenticatedUserDisplay.True> */}
          </S.Title>
          <S.AboutMeWrapper>
            <InfoCard data={team?.description} />
          </S.AboutMeWrapper>
          {/* <S.Title>Estatísticas</S.Title>
          <S.StatisticsWrapper>
            <S.StatisticsItemWrapper>
              <S.StatisticsImage src={Trophy} alt="Trophy" />
              <S.StatisticsTextWrapper>
                <S.StatisticsText>25</S.StatisticsText>
                <S.StatisticsDescription>
                  Campeonatos jogados
                </S.StatisticsDescription>
              </S.StatisticsTextWrapper>
            </S.StatisticsItemWrapper>
            <S.StatisticsItemWrapper>
              <S.StatisticsImage src={GraphUp} alt="Trophy" />
              <S.StatisticsTextWrapper>
                <S.StatisticsText>80%</S.StatisticsText>
                <S.StatisticsDescription>
                  Taxa de vitória
                </S.StatisticsDescription>
              </S.StatisticsTextWrapper>
            </S.StatisticsItemWrapper>
            <S.StatisticsItemWrapper>
              <S.StatisticsImage src={BookmarkStar} alt="Trophy" />
              <S.StatisticsTextWrapper>
                <S.StatisticsText>122º</S.StatisticsText>
                <S.StatisticsDescription>
                  Posição no ranking
                </S.StatisticsDescription>
              </S.StatisticsTextWrapper>
            </S.StatisticsItemWrapper>
          </S.StatisticsWrapper>
          <S.Title>Campeonatos jogados</S.Title>
          <S.MobileCarouselWrapper>
            {campeonatosList.map((item) => (
              <ChampionshipCard
                championshipName={item.championshipName}
                championshipImg={item.championshipImg}
                gameImg={item.gameImg}
                status={item.status}
              />
            ))}
          </S.MobileCarouselWrapper> */}

          {/* <div className="titlePosition">
            <S.Title>Vídeos</S.Title>
          </div>

          <S.VideosWrapper>
            <S.NewVideoLink href="#">Enviar novo vídeo</S.NewVideoLink>

            {videosList.map((item) => (
              <ProfileVideo
                videoImg={item.videoImg}
                videoTitle={item.videoTitle}
                userImg={item.userImg}
                videoViews={item.videoViews}
                videoDate={item.videoDate}
              />
            ))}
          </S.VideosWrapper> */}
        </S.LeftWrapper>

        <S.RightWrapper>
          {/* <S.Title>
            Descrição
            <a href="#">
              <S.EditProfileImg src={PencilSquare} alt="PencilSquare" />
            </a>
          </S.Title>
          <S.AboutMeWrapper>
            <InfoCard data={team?.description} />
          </S.AboutMeWrapper> */}
        </S.RightWrapper>
      </S.ProfileWrapper>
    </S.Container>
  );
};

export default TeamProfile;
