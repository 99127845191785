import { dateTimeFormat2 } from "@utils/date";
import * as S from "./styles";

type Props = {
  championshipName: string;
  championshipImg?: string;
  gameImg?: string;
  date: Date;
  currentUsers: number;
  maxUsers: number;
  squad: string;
};

const Card: React.FC<Props> = ({
  championshipName,
  championshipImg,
  gameImg,
  date,
  currentUsers,
  maxUsers,
  squad,
}) => {
  return (
    <S.CardContainer key={championshipName}>
      <S.ImageContainer>
        <S.CardGameImage src={gameImg} alt="Campeonato" />
        <S.CardChampionshipImage src={championshipImg} alt="Campeonato" />
        {/* <S.DetailsContainer>
          🕒 Inscrições encerram em <strong>10 dias</strong>
        </S.DetailsContainer> */}
      </S.ImageContainer>
      <S.InfoContainer>
        <S.CardTitle>{championshipName}</S.CardTitle>
        <S.CardFooterContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>
              📅 {dateTimeFormat2(date)}
            </S.CardSmallInfoText>
          </S.CardSmallInfoContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>
              👥 {currentUsers}/{maxUsers}
            </S.CardSmallInfoText>
          </S.CardSmallInfoContainer>
          <S.CardSmallInfoContainer>
            <S.CardSmallInfoText>⚔️ {squad}</S.CardSmallInfoText>
          </S.CardSmallInfoContainer>
        </S.CardFooterContainer>
      </S.InfoContainer>
    </S.CardContainer>
  );
};

export default Card;
