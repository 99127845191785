import { Box, Image, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { VscDeviceCamera } from "react-icons/vsc";

type Props = {
  imgPath: string;
  altImage?: string;
  hoverText?: string;
  handlerClick: () => void;
  isEdit?: boolean;
  styleContainer?: React.CSSProperties;
  styleImage?: React.CSSProperties;
};

const ImageHover: React.FC<Props> = ({
  imgPath = "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg",
  hoverText,
  styleContainer,
  styleImage,
  isEdit = false,
  altImage = "",
  handlerClick,
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 4000);
  }, []);

  return (
    <Box
      position="relative"
      overflow="hidden"
      cursor="pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={styleContainer}
      w={{ base: "170px", lg: "" }}
      h={{ base: "170px", lg: "" }}
    >
      <Skeleton
        isLoaded={isLoaded}
        fadeDuration={4}
        color="white"
        h="100%"
        display={"flex"}
      >
        <Image
          src={imgPath}
          alt={altImage}
          width="100%"
          height="100%"
          objectFit="cover"
          style={styleImage}
        />
      </Skeleton>
      {isHovered && isEdit && isLoaded && (
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          background="#E9456080"
          transition="opacity 0.5s ease"
          flexDirection="column"
          onClick={handlerClick}
        >
          <VscDeviceCamera size="60px" />
          <Text fontSize="24px">{hoverText}</Text>
        </Box>
      )}
    </Box>
  );
};

export default ImageHover;
