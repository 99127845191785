import InforCardItem from "./InforCardItem";
import * as S from "./styles";

type Props = {
  children: React.ReactNode;
};

const InforCard: React.FC<Props> = ({ children }) => (
  <S.Container>{children}</S.Container>
);

export { InforCard, InforCardItem };
