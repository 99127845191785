import Modal from "@components/template/modal";
import React from "react";
import InputMask from "react-input-mask";
import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import useUser from "@hooks/useUser";

const UserProfileFinishingModal: React.FC = () => {
  const {
    userAuth,
    isOpenUserProfileFinishingModal,
    onCloseUserProfileFinishingModal,
    formUpdateProfileUser,
  } = useUser();

  const {
    formRegisterProfileUser,
    handleSubmitProfileUser,
    isSubmitted,
    errors,
  } = formUpdateProfileUser;

  const isCpf = userAuth?.cpf === null || userAuth?.cpf === "";
  const isPhone = userAuth?.phone === null || userAuth?.phone === "";
  const isNick = userAuth?.nick === null || userAuth?.nick === "";

  return (
    <Modal
      isOpen={isOpenUserProfileFinishingModal}
      handlerCancel={onCloseUserProfileFinishingModal}
      size="xl"
      cancelButtonText="Salvar"
      title="SEU PERFIL ESTÁ INCOMPLETO É OBRIGATÓRIO O PREENCHIMENTO DE:"
      isFooter={false}
    >
      <Flex
        as="form"
        onSubmit={handleSubmitProfileUser}
        gap="20px"
        flexDir="column"
      >
        {isNick && (
          <FormControl
            display="flex"
            flexDir="row"
            alignItems="center"
            gap="15px"
          >
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="nick" m="0">
              NICK:
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Input
                placeholder="DIGITE SEU NICK"
                color="#ffffff"
                border="2px solid #E94560"
                {...formRegisterProfileUser("nick")}
              />
              {isSubmitted && errors.nick && (
                <FormHelperText color="#E94560">
                  {errors.nick?.message}
                </FormHelperText>
              )}
            </Flex>
          </FormControl>
        )}

        {isPhone && (
          <FormControl
            display="flex"
            flexDir="row"
            alignItems="center"
            gap="15px"
          >
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="phone" m="0">
              CELULAR:
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Input
                as={InputMask}
                alwaysShowMask={true}
                mask="(**) *****-****"
                placeholder="(12)3456-7890"
                color="#ffffff"
                border="2px solid #E94560"
                {...formRegisterProfileUser("phone")}
              />
              {isSubmitted && errors.phone && (
                <FormHelperText color="#E94560">
                  {errors.phone.message}
                </FormHelperText>
              )}
            </Flex>
          </FormControl>
        )}

        {isCpf && (
          <FormControl
            display="flex"
            flexDir="row"
            alignItems="center"
            gap="15px"
          >
            <FormLabel color="#ffffff" fontWeight="bold" htmlFor="cpf" m="0">
              CPF:
            </FormLabel>
            <Flex flexDir={"column"} w={"100%"}>
              <Input
                as={InputMask}
                alwaysShowMask={true}
                mask="***.***.***-**"
                placeholder="999.999.999-99"
                color="#ffffff"
                border="2px solid #E94560"
                {...formRegisterProfileUser("cpf")}
              />
              {isSubmitted && errors.cpf && (
                <FormHelperText color="#E94560">
                  {errors.cpf.message}
                </FormHelperText>
              )}
            </Flex>
          </FormControl>
        )}

        <Flex justifyContent={"center"}>
          <Button type="submit" color="#ffffff" bg="#E94560" w="103px" h="30px">
            Salvar
          </Button>
        </Flex>
        <Text color="#E94560" fontWeight="bold" fontSize="13px">
          OBS: SEU WHATSAPP NÃO ESTARÁ DISPONÍVEL AO PÚBLICO, SOMENTE PARA
          EQUIPE ORGANIZADORA, PARA FINS DE CONTATO.
        </Text>
      </Flex>
    </Modal>
  );
};

export default UserProfileFinishingModal;
