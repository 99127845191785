import { ToastContainerStyled } from "./styles";
import "react-toastify/dist/ReactToastify.css";

export default function Toast() {
  const closeToastTimeInSeconds = 3;

  return (
    <ToastContainerStyled
      position="top-right"
      autoClose={closeToastTimeInSeconds * 1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
