import Modal from "react-modal";
import { IoMdClose } from "react-icons/io";
import * as S from "./style";
import Autocomplete from "@components/autocompleteInput";
import useTeam from "@hooks/useTeam";

type Props = {
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
};

const modalCustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    border: "none",
    zIndex: "999",
    background: "transparent",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.8)",
  },
};

const InviteMemberModal: React.FC<Props> = ({ isOpen, onClose, teamId }) => {
  const { invite, searchUsersTeamInvitation } = useTeam(teamId);

  return (
    <Modal isOpen={isOpen} style={modalCustomStyles}>
      <S.ModalContainer>
        <S.ModalHeader>
          <S.ModalTitle>Convidar membros</S.ModalTitle>
          <S.ModalClose onClick={onClose}>
            <IoMdClose size={24} />
          </S.ModalClose>
        </S.ModalHeader>
        <Autocomplete suggestions={searchUsersTeamInvitation} select={invite} />
      </S.ModalContainer>
    </Modal>
  );
};

export default InviteMemberModal;
