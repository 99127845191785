import { BaseResponse } from "./base";
import { IFileResponse } from "./file.interface";
import { IGame } from "./game.interface";
import { IRegulation } from "./regulations.interface";
import { ITeam, ITeamMembers } from "./team.interface";
import { IRoundProps } from "@sportsgram/brackets";
import { IUser } from "./user.interface";

export interface IChampionships {
  id: string;
  name: string;
  description: string;
  registration_start_date: Date;
  registration_end_date: Date;
  start_date: Date;
  end_date: Date;
  status: ChampionshipStatus;
  modality: ChampionshipModality;
  modality_type: ChampionshipModalityType;
  game: BaseResponse<IGame>;
  type: ChampionshipType;
  main_showcase: boolean;
  published: boolean;
  paid: boolean;
  paid_value: number;
  participant_limit: number;
  participant_limit_per_team: number;
  registered_participants_count: number;
  regulation_id: string;
  game_id: string;
  flag_picks: boolean;
  created_at: Date;
  updated_at: Date;
  lcg: boolean;
  championship_awards: BaseResponse<ChampionshipAward>[];
  regulations: IRegulation;
}

export enum ChampionshipStatus {
  UPCOMING = "UPCOMING",
  OPEN_FOR_REGISTRATION = "OPEN_FOR_REGISTRATION",
  REGISTRATION_CLOSED = "REGISTRATION_CLOSED",
  ONGOING = "ONGOING",
  CANCELLED = "CANCELLED",
  FINISHED = "FINISHED",
}

enum ChampionshipModality {
  MULTI_STAGE = "Multi-stage",
  SINGLE_STAGE = "Single-stage",
  ROUND_ROBIN = "Round Robin",
  SINGLE_ELIMINATION = "Single Elimination",
  DOUBLE_ELIMINATION = "Double Elimination",
}

export enum ChampionshipModalityType {
  ONLINE = "ONLINE",
  PRESENCIAL = "PRESENCIAL",
  HYBRID = "HYBRID",
}

export enum ChampionshipType {
  INDIVIDUAL = "INDIVIDUAL",
  TEAM = "TEAM",
}

export enum ParticipantSituation {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  CANCELLED = "CANCELLED",
}

export interface IParticipant {
  participant: {
    id: string;
    championship_id: string;
    entity_id: string;
    business_type: ChampionshipType;
    payment_status: string;
    situation: ParticipantSituation;
    created_at: Date;
    updated_at: Date;
  };
  entity: {
    name: string;
    files: IFileResponse[];
    team_members_count?: number;
    team_members?: BaseResponse<ITeamMembers>[];
  };
}

export enum ChampionshipRoundStatus {
  UPCOMING = "UPCOMING",
  ONGOING = "ONGOING",
  FINISHED = "FINISHED",
}

export interface IChampionshipMatch {
  id: string;
  status: ChampionshipRoundStatus;
  participant_opponent1: string;
  participant_opponent2: string;
  start_date: string;
  modality: string;
  created_at: string;
  updated_at: string;
  round_id: string;
  number: number;
  round_duration: number;
  bracket_status: string;
  score_opponent1: string;
  score_opponent2: string;
  result_opponent1: string;
  result_opponent2: string;
  championship_match_game: [];
  data_participants: BaseResponse<ITeam | IUser>[];
}

export interface IChampionshipRound {
  id: string;
  championship_id: string;
  stage_id: string;
  name: string;
  number: 0;
  status: ChampionshipRoundStatus;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  championship_match: IChampionshipMatch[];
}

export interface IChampionshipsTournament {
  id: string;
  championship_id: string;
  name: string;
  type: ChampionshipModality;
  order: number;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  championship_round: IChampionshipRound[];
}

export type ChampionshipsTournament = {
  wining: IRoundProps[];
  lower: IRoundProps[];
};

export type ChampionshipAward = {
  id: string;
  position: number;
  name: string;
  description: string;
  type: string;
  value: number;
  championship_id: string;
};
