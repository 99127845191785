import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;

  max-height: 564px;
  height: 100%;

  border: 1px solid #fafafa22;
  box-shadow: 0px 15px 30px #0000007d;
  border-radius: 7px 7px 7px 7px;
  color: #ffffff;

  transition-duration: 1s;
  cursor: pointer;

  &:hover {
    transition-duration: 0.8s;
    transform: scale(0.9);
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const CardChampionshipImage = styled.img`
  width: 100%;
`;

export const CardGameImage = styled.img`
  position: absolute;
  width: 50px;
  border-radius: 4px;
  margin: 1rem;
  border: 2px solid #fff;
`;

export const DetailsContainer = styled.div`
  font-size: var(--font-size-medium);
  position: absolute;
  bottom: 0;
  z-index: 996;
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  background-color: rgba(31, 43, 68, 0.8);

  strong {
    font-size: var(--font-size-medium);
    text-align: center;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1f2b44;
`;

export const CardTitle = styled.p`
  height: 60px;
  font-size: var(--font-size-large);
  padding: 1rem;
`;

export const CardFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardSmallInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`;

export const CardSmallInfoText = styled.span`
  font-size: var(--font-size-base);
`;
