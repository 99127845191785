import axios from "axios";
import { toast } from "react-toastify";

const progamersApi = axios.create({
  baseURL: import.meta.env.VITE_SOME_API_URL,
});

progamersApi.interceptors.request.use((request) => {
  const token = localStorage.getItem("programers.token");

  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }

  return request;
});

progamersApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message = error.response.data.message;
    if (Array.isArray(error.response.data.message)) {
      message = error.response.data.message[0];
    }

    if (error.status === 503) {
      message = "Tivemos um problema. Contate o suporte.";
    }

    if (error.status === 401) {
      localStorage.removeItem("programers.token");
    }

    toast.error(message);
    return Promise.reject(error);
  }
);

export { progamersApi };
