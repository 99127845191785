import * as S from "./style";
import Header from "../header";
import Sidebar from "../sidebar";
import Footer from "../footer";
import UserProfileFinishingModal from "@components/userProfileFinishingModal";

type Props = {
  children: React.ReactNode;
  styleContainer?: React.CSSProperties;
  styleBodyContainer?: React.CSSProperties;
  showSidebar?: boolean;
};

const Layout: React.FC<Props> = ({
  children,
  styleContainer,
  styleBodyContainer,
  showSidebar = true,
}) => {
  return (
    <S.Layout>
      <UserProfileFinishingModal />
      <S.Container style={styleContainer}>
        {showSidebar && <Sidebar />}
        <Header />
        <S.ContainerBody style={styleBodyContainer}>{children}</S.ContainerBody>
        <Footer />
      </S.Container>
    </S.Layout>
  );
};
export default Layout;
