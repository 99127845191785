import Modal from "react-modal";
import * as S from "./styles.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPasswordScheme } from "@validations/auth.schema.ts";
import { useForm } from "react-hook-form";
import useAuth from "@hooks/useAuth.ts";
import { Icon, Spinner } from "@chakra-ui/react";
import { HiOutlineLightBulb } from "react-icons/hi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { X } from "lucide-react";

import { useEffect, useState } from "react";
import { IResetPassword } from "@interfaces/auth.interface.ts";

enum ModalState {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
}

export function ModalResetPassword({ isOpen, onRequestClose }) {
  const { submiterResetPassword } = useAuth();
  const [modalState, setModalState] = useState(ModalState.INITIAL);
  const [emailValueAfterReset, setEmailValueAfterReset] = useState("");
  const {
    register,
    reset,
    handleSubmit: handleSubmitCreate,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(ForgotPasswordScheme),
  });

  useEffect(() => {
    if (!isOpen) {
      setModalState(ModalState.INITIAL);
      setEmailValueAfterReset("");
      reset();
    }
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={S.StylesModal}
      contentLabel="Exemplo de Modal"
    >
      <S.ModalContainerWrapper>
          {modalState === ModalState.SUCCESS && (
            <S.CloseContainer>
              <X
                onClick={onRequestClose}
                className="modal-icon-close"
                color="red"
                size={22}/>
            </S.CloseContainer>

          )}
          {modalState === ModalState.INITIAL && (
             <S.ModalHeaderContainer>
                <S.ModalTitle>Recuperar Senha</S.ModalTitle>
            </S.ModalHeaderContainer>
          )}
          {modalState === ModalState.SUCCESS && (
             <S.ModalHeaderContainer>
                <S.ModalTitle>Email Enviado</S.ModalTitle>
             </S.ModalHeaderContainer>
          )}
          {modalState === ModalState.LOADING && (
             <S.ModalHeaderContainer>
                <S.ModalTitle>Carregando...</S.ModalTitle>
             </S.ModalHeaderContainer>
          )}
        {modalState === ModalState.INITIAL && (
          <S.TextContainer>
            <Icon
              as={HiOutlineLightBulb}
              height="30px"
              width="30px"
              color="#E94560"
            />
            <S.ModalText>
              Cada esquecimento é uma chance de recomeçar com mais sabedoria.
            </S.ModalText>
          </S.TextContainer>
        )}
        {modalState === ModalState.SUCCESS && (
          <S.TextContainer>
            <Icon
              as={MdOutlineMarkEmailRead}
              height="30px"
              width="30px"
              color="#E94560"
            />
            <S.ModalText>
            Enviamos um email com instruções de como redefinir sua senha para <b>{emailValueAfterReset}</b> Se você não encontrar o email na sua caixa de entrada, verifique a lixeira ou a pasta de spam.
            </S.ModalText>
          </S.TextContainer>
        )}
        {modalState === ModalState.INITIAL && (
          <S.LoginForm onSubmit={handleSubmitCreate(async (data: IResetPassword, event?: React.BaseSyntheticEvent) => {
              setEmailValueAfterReset(data.email);
              setModalState(ModalState.LOADING);
              await submiterResetPassword(data, event);
              setModalState(ModalState.SUCCESS);
            })}>
                <S.FormLabel htmlFor="loginEmail">E-mail</S.FormLabel>
                <S.FormInput
                  id="loginEmail"
                  type="email"
                  placeholder="Seu e-mail"
                  {...register("email")}
                />
                {isSubmitted && errors.email && (
                  <S.FormError>{errors.email.message}</S.FormError>
                )}
                <S.Row>
                  <S.LinkButton onClick={onRequestClose}>
                    Voltar ao Login
                  </S.LinkButton>
                  <S.ModalButton type="submit">Enviar</S.ModalButton>
                </S.Row>
          </S.LoginForm>
        )}

        {modalState === ModalState.LOADING && (
          <S.LoadingContainer>
              <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color="#E94560"
              size='xl'
            />
          </S.LoadingContainer>

        )}

      </S.ModalContainerWrapper>
    </Modal>
  );
}
