import {
  FileExtension,
  FileImageCropper,
  IFileRequest,
} from "@interfaces/file.interface";
import fileService from "@services/file.service";
import { toast } from "react-toastify";

const useFile = () => {
  const { postSave, putUpdate } = fileService();

  const uploadFile = async (base64Image: string, file: FileImageCropper) => {
    const fileRequest: IFileRequest = {
      base64: base64Image.split("base64,")[1],
      extension: FileExtension.PNG,
      entityType: file.entity_type,
      entityId: file.entity_id,
      businessType: file.business_type,
      fileType: "IMAGE",
    };

    try {
      if (file.id) {
        await putUpdate(file.id, fileRequest);
      } else {
        await postSave(fileRequest);
      }
    } catch (error) {
      toast.error("Image Upload Error");
      throw new Error("Image Upload Error");
    }
  };

  return { uploadFile };
};

export default useFile;
