import styled from "styled-components";
import { device } from "@utils/index";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-inline: auto;
  color: var(--text-color);
  z-index: 10;

  @media ${device.tablet} {
    width: 90%;
  }
`;
