import { ParticipantSituation } from "@interfaces/championships.interface";

export const ParticipantSituationTranslate = (
  situation: ParticipantSituation
) => {
  return (
    {
      PENDING: "Pendente",
      ACCEPTED: "Aprovado",
      CANCELLED: "Reprovado",
    }[situation] || ""
  );
};
