import { Button, Checkbox, Flex, Text } from "@chakra-ui/react";
import InputText from "@components/template/inputText";
import { UserSetupForm } from "@interfaces/user.interface";
import { Controller, useFormContext } from "react-hook-form";
import Gallery from "../../components/gallery";
import { IFile } from "@interfaces/file.interface";

type Props = {
  handlerSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

const Tablet: React.FC<Props> = ({ handlerSubmit }) => {
  const {
    register,
    control,
    formState: { errors, defaultValues },
  } = useFormContext<UserSetupForm>();

  return (
    <Flex flexDir={{ base: "column", lg: "row" }} gap={"20px"}>
      <Flex
        as="form"
        flexDir={"column"}
        alignItems={"center"}
        onSubmit={handlerSubmit}
        gap={"15px"}
        w={{ base: "100%", lg: "45%" }}
      >
        <Text fontWeight={"bold"} fontSize={"1.8rem"}>
          Hardware
        </Text>
        <InputText
          label="Marca/Modelo"
          placeholder="Seu Celular"
          error={errors.TABLET?.entity?.smartphone}
          {...register("TABLET.entity.brand_model")}
        />
        <InputText
          label="Sistema Operacional"
          placeholder="Seu Sistema Operacional"
          error={errors.TABLET?.entity?.os}
          {...register("TABLET.entity.os")}
        />
        <InputText
          label="Memória"
          placeholder="Sua Memória"
          error={errors.TABLET?.entity?.memoryRam}
          {...register("TABLET.entity.memoryRam")}
        />
        <InputText
          label="HD/SSD"
          placeholder="Seu HD ou SSD"
          error={errors.TABLET?.entity?.storage}
          {...register("TABLET.entity.storage")}
        />
        <Text fontWeight={"bold"} fontSize={"1.8rem"} marginTop={"28px"}>
          Periféricos
        </Text>
        <InputText
          label="Monitor"
          placeholder="Seu Monitor"
          error={errors.TABLET?.entity?.monitor}
          {...register("TABLET.entity.monitor")}
        />
        <InputText
          label="Teclado"
          placeholder="Seu Teclado"
          error={errors.TABLET?.entity?.keyboard}
          {...register("TABLET.entity.keyboard")}
        />
        <InputText
          label="Headset"
          placeholder="Seu Headset"
          error={errors.TABLET?.entity?.headphone}
          {...register("TABLET.entity.headphone")}
        />
        <Controller
          name="TABLET.entity.is_main"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              isChecked={field.value}
              alignSelf={"flex-start"}
              color={"#ffffff"}
              colorScheme={"red"}
              marginTop={"22px"}
            >
              Definir esse setup como principal?
            </Checkbox>
          )}
        />
        <Button
          bg={"#E94560"}
          w={"150px"}
          marginTop={"45px"}
          alignSelf={"center"}
          color={"#FFFFFF"}
          type="submit"
        >
          Salvar
        </Button>
      </Flex>
      <Gallery
        files={defaultValues?.TABLET?.files as IFile[]}
        entityId={defaultValues?.TABLET?.entity?.id!}
      />
    </Flex>
  );
};

export default Tablet;
