export const ChampionshipsTranslate = (message: string) => {
  return (
    {
      MULTI_STAGE: "Multi-estágio",
      SINGLE_STAGE: "Estágio único",
      ROUND_ROBIN: "Round Robin",
      SINGLE_ELIMINATION: "Eliminação simples",
      DOUBLE_ELIMINATION: "Eliminação dupla",
      TEAM: "EQUIPE",
      INDIVIDUAL: "INDIVIDUAL",
    }[message] || ""
  );
};
