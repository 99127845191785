import { Button, Flex } from "@chakra-ui/react";
import TableChampionships from "@pages/championshipDetails/tabs/tableChampionships";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useChampionship from "@hooks/useChampionship";

const EventLcg: React.FC = () => {
  const { championshipId } = useParams();
  const [searchParams] = useSearchParams();
  const [viewTable, setViewTable] = useState<string>("all");
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    const viewAdmin = searchParams.get("viewAdmin");
    if (!viewAdmin || viewAdmin != "true") {
      window.location.href = `/`;
    }
  }, [searchParams]);

  const { championshipsTournament, isLoadingTournament } = useChampionship(
    championshipId!
  );

  const handlerClick = (isOut: boolean = false) => {
    setZoom((state) => (isOut ? state - 0.1 : state + 0.1));
  };

  const handlerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setViewTable(e.target.value);
  };

  return (
    <Flex bg="#00FF04" flexDir="column">
      <Flex>
        <select name="" id="" onChange={handlerSelect}>
          <option value="all">All</option>
          <option value="isLosing">isLosing</option>
          <option value="isWining">isWining</option>
        </select>
        <Button
          onClick={() => handlerClick()}
          bg="red"
          width="80px"
          fontSize="26px"
        >
          +
        </Button>
        <Button
          onClick={() => handlerClick(true)}
          bg="red"
          width="80px"
          fontSize="26px"
        >
          -
        </Button>
      </Flex>
      <TableChampionships
        tournaments={championshipsTournament}
        isLoading={isLoadingTournament}
        isLosing={viewTable == "isLosing" || viewTable == "all"}
        isWining={viewTable == "isWining" || viewTable == "all"}
        style={{ transform: `scale(${zoom})`, transformOrigin: "top left" }}
      />
    </Flex>
  );
};

export default EventLcg;
