import styled from "styled-components";
import { ListItem as ItemList } from "@chakra-ui/react";

export const InputSearch = styled.input`
  width: 100%;
  height: 50px;
  background-color: transparent;
  color: #fff;
  padding-left: 1rem;
  border: 1px solid #ffffff;
`;

export const ListItem = styled(ItemList)`
  display: flex;
  gap: 10;
  height: max-content;
  align-items: center;
  justify-content: space-between;
`;
