import styled from "styled-components";

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 2rem 0;
`;

export const LogoImg = styled.img`
  width: 150px;
`;
