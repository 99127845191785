import { ChampionshipStatus } from "@interfaces/championships.interface";
import championshipService from "@services/championships.service";
import { queryOptions } from "@tanstack/react-query";

const championshipsQueryOptions = () => {
  const {
    getChampionshipByStatus,
    getChampionshipByIdPublic,
    getChampionshipsPublic,
    getUserRegister,
    getAllParticipantsPublic,
    getChampionshipsTournamentPublic,
  } = championshipService();

  const optionsFetchChampionshipByIdPublic = (id: string) => {
    return queryOptions({
      queryKey: ["championshipByIdPublic", id],
      queryFn: () => getChampionshipByIdPublic(id),
      enabled: !!id,
    });
  };

  const optionsFetchRegisterUserByChampionshipId = (
    id: string,
    isAuthentication: boolean
  ) => {
    return queryOptions({
      queryKey: ["registerUserByChampionshipId", id],
      queryFn: () => getUserRegister(id),
      enabled: !!id && isAuthentication,
    });
  };

  const optionsFetchChampionshipsByStatus = (status: ChampionshipStatus) => {
    return queryOptions({
      queryKey: ["championshipByStatus", status],
      queryFn: () => getChampionshipByStatus(status),
    });
  };

  const optionsFetchChampionshipsPublic = () => {
    return queryOptions({
      queryKey: ["championshipsPublic"],
      queryFn: getChampionshipsPublic,
    });
  };

  const optionsFetchChampionshipParticipantsByIdPublic = (id: string) => {
    return queryOptions({
      queryKey: ["championshipParticipantsByIdPublic", id],
      queryFn: () => getAllParticipantsPublic(id),
      enabled: !!id,
    });
  };

  const optionsFetchChampionshipsTournamentPublic = (id: string) => {
    return queryOptions({
      queryKey: ["championshipsTournament", id],
      queryFn: () => getChampionshipsTournamentPublic(id),
      enabled: !!id,
    });
  };

  return {
    optionsFetchChampionshipByIdPublic,
    optionsFetchChampionshipsByStatus,
    optionsFetchChampionshipsPublic,
    optionsFetchRegisterUserByChampionshipId,
    optionsFetchChampionshipParticipantsByIdPublic,
    optionsFetchChampionshipsTournamentPublic,
  };
};

export const {
  optionsFetchChampionshipByIdPublic,
  optionsFetchChampionshipsByStatus,
  optionsFetchChampionshipsPublic,
  optionsFetchRegisterUserByChampionshipId,
  optionsFetchChampionshipParticipantsByIdPublic,
  optionsFetchChampionshipsTournamentPublic,
} = championshipsQueryOptions();
