import styled from "styled-components";
import { device } from "@utils/index";

export const Container = styled.div<{ backgroundImage: string }>`
  background: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const TeamAvatarWrapper = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  @media ${device.tablet} {
    align-items: flex-start;
    margin-top: -6rem;
    padding-left: 2rem;
    padding-right: 2.5rem;
  }
`;

export const TeamInformationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TeamInviteLink = styled.button`
  align-self: initial;
  font-size: var(--font-size-small);
  font-weight: 400;
  color: var(--text-color);
  padding: 0.5rem 3rem;
  border-radius: 2px;
  background: var(--layout-color);
  text-decoration: none;
  box-shadow: var(--box-shadow-default);
  cursor: pointer;
  outline: 0;
  border: 0;

  @media ${device.tablet} {
    align-self: flex-end;
  }
`;

export const TeamAvatarTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding-left: 2rem;
  padding-top: 1rem;

  @media ${device.tablet} {
    padding-left: 1rem;
    padding-top: 2rem;
  }
`;

export const TeamAvatar = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 16px;
  border: 0;

  @media ${device.tablet} {
    width: 125px;
    height: 125px;
  }
`;

export const TeamName = styled.h1`
  font-size: 36px;
  font-weight: 400;
  color: var(--text-color);

  @media ${device.tablet} {
    font-size: var(--font-size-medium);
  }
`;

export const TeamStatus = styled.h2`
  font-size: var(--font-size-small);
  font-weight: 400;
  color: #ffffff88;

  @media ${device.tablet} {
    font-size: var(--font-size-small);
  }
`;

export const Title = styled.h2`
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  color: var(--layout-color);
  margin-bottom: 2rem;

  @media ${device.tablet} {
    font-size: 28px;
    text-align: center;
  }
`;

export const ConfigImg = styled.img`
  width: 2rem;
  margin-right: 0.5rem;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
  }
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 0.7rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--text-color);
  margin-bottom: 1rem;
  font-size: var(--font-size-base);
  color: var(--text-color);
  background-color: transparent;
`;

export const FormSelect = styled.select`
  width: 100%;
  padding: 0.7rem 1rem;
  outline: 0;
  border-radius: 6px;
  border: 1px solid var(--text-color);
  margin-bottom: 1rem;
  font-size: var(--font-size-base);
  color: var(--text-color);
  background-color: transparent;

  option {
    color: #000;
  }
`;

export const FormLabel = styled.label`
  font-size: var(--font-size-medium);
  font-weight: 600;
  color: var(--text-color);
  text-align: left;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const PrimaryAction = styled.button`
  font-size: var(--font-size-medium);
  letter-spacing: 0px;
  background: var(--layout-color);
  color: var(--text-color);
  opacity: 1;
  text-align: center;
  border-radius: 5rem;
  border: 1px solid var(--layout-color);
  padding: 0.8rem 3rem;
  margin: 1rem 0;
  cursor: pointer;

  box-shadow: 0rem 0rem 1rem var(--layout-color);
  border-radius: 4px;

  @media ${device.tablet} {
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;

  width: 100%;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const FormError = styled.p`
  width: 100%;
  margin-top: -10px;
  color: var(--layout-color);

  font-size: 14px;
  text-align: left;

  @media ${device.mobileLarge} {
    margin-bottom: 1px;
    font-size: 12px;
  }
`;

export const FormTextarea = styled.textarea`
  width: 100%;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  outline: none;
  border-radius: 6px;
  border: 1px solid var(--text-color);

  color: var(--text-color);
  background-color: transparent;
  font-size: var(--font-size-medium);

  @media ${device.mobileLarge} {
    padding: 0.4rem 0.8rem;
    font-size: var(--font-size-small);
  }
`;
