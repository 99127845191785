import {
  Avatar,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  userName: string;
  handlerClose: (wasAccept: boolean) => void;
};

const RegistrationUserModal: React.FC<Props> = ({
  isOpen,
  userName,
  handlerClose,
}) => {
  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => handlerClose(false)}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="#0C1A27CF" />
      <ModalContent border="1px solid #E94560" bg="#010101F0" color="#ffffff">
        <ModalHeader>FAÇA SUA INSCRIÇÃO</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>CONFIRMAR SUA INSCRIÇÃO NO CAMPEONATO?</Text>
          <Flex alignItems="center" gap="10px" margin="25px 0px 10px 0px">
            <Avatar name={userName} />
            <Flex flexDirection="column">
              <Text>{userName}</Text>
            </Flex>
          </Flex>
          <ModalFooter display="flex" justifyContent="space-between">
            <Button
              bg="#E94560"
              borderRadius="5px"
              w="150px"
              h="50px"
              color="#ffffff"
              onClick={() => handlerClose(true)}
              _hover={{ backgroundColor: "#E94560" }}
            >
              SIM
            </Button>
            <Button
              variant="outline"
              borderRadius="5px"
              w="150px"
              h="50px"
              color="#ffffff"
              onClick={() => handlerClose(false)}
              _hover={{ backgroundColor: "" }}
            >
              NÃO
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RegistrationUserModal;
