import { progamersApi } from "@config/axios.config";
import { IFileRequest, IFileResponse } from "@interfaces/file.interface";

export default function fileService() {
  const postSave = async (file: IFileRequest): Promise<IFileResponse> => {
    const { data } = await progamersApi.post("/file/save", file);
    return data as IFileResponse;
  };

  const putUpdate = async (
    fileId: string,
    file: IFileRequest
  ): Promise<IFileResponse> => {
    const { data } = await progamersApi.put(`/file/${fileId}`, file);
    return data as IFileResponse;
  };

  return { postSave, putUpdate };
}
