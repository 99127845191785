import {
  ChampionshipType,
  ParticipantSituation,
} from "@interfaces/championships.interface";
import { Flex, Image, Text } from "@chakra-ui/react";
import { FaUsers } from "react-icons/fa";
import { ITeamMembers } from "@interfaces/team.interface";
import { BaseResponse } from "@interfaces/base";
import { useNavigate } from "react-router-dom";
import { TbClockHour4 } from "react-icons/tb";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";

type Props = {
  name: string;
  id: string;
  background: string;
  logo: string;
  listMembers: BaseResponse<ITeamMembers>[];
  maxUsers: number;
  type: ChampionshipType;
  situation: ParticipantSituation;
};

const CardParticipants: React.FC<Props> = ({
  name,
  id,
  background,
  logo,
  listMembers,
  maxUsers,
  type,
  situation,
}) => {
  const navigate = useNavigate();

  const navigateProfile = () => {
    navigate(`/${type === ChampionshipType.TEAM ? "team" : "profile"}/${id}`);
  };

  const statusParticipant = () => {
    return {
      PENDING: <TbClockHour4 fontSize="25px" color="#FFFF03" />,
      ACCEPTED: <FaRegCheckCircle fontSize="25px" color="#21FF03" />,
      CANCELLED: <MdOutlineCancel fontSize="25px" color="#FF2903" />,
    }[situation];
  };

  return (
    <Flex
      key={id}
      w="317px"
      h="fit-content"
      border="1px solid #fafafa22"
      boxShadow="0px 15px 30px #0000007d"
      borderRadius="7px"
      cursor="pointer"
      transitionDuration="1s"
      flexDir="column"
      _hover={{ transitionDuration: "0.8s", transform: "scale(0.9)" }}
    >
      <Flex
        bgImage={`url('${background || "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"}')`}
        flexDir="column"
        alignItems="center"
        justifyContent="space-between"
        onClick={navigateProfile}
        h="190px"
      >
        <Flex flexDir="column" alignItems="center" gap="10px" h="120px">
          {type === ChampionshipType.TEAM && (
            <Flex
              w="100%"
              paddingLeft="14px"
              paddingTop="10px"
              gap="10px"
              alignItems="center"
            >
              <FaUsers color="#E94560" fontSize="22px" />
              {listMembers.length}/{maxUsers}
            </Flex>
          )}
          <Image
            w="100%"
            h="85%"
            borderRadius="200px"
            src={
              logo ||
              "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"
            }
            alt="Logo do Time"
            marginTop={type === ChampionshipType.TEAM ? "" : "20px"}
          />
        </Flex>
        <Flex
          bgColor="#25272b"
          padding="8px 0px"
          w="100%"
          justifyContent="center"
          align="center"
          gap="10px"
        >
          <Text color="#ffffff" fontSize="18px" align="center">
            {name}
          </Text>
          {statusParticipant()}
        </Flex>
      </Flex>
      {type === ChampionshipType.TEAM && (
        <Flex h="60px" gap="12px" p="6px">
          {listMembers
            ?.slice(0, 5)
            .map((item) => (
              <Image
                src={
                  item?.files[0]?.url ||
                  "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"
                }
                onClick={() => navigate(`/profile/${item?.entity?.user_id}`)}
                alt="Imagem dos participantes"
                borderRadius="50%"
                w="16%"
              />
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default CardParticipants;
