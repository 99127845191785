import * as S from "./styles";
import * as GS from "@components/template/global/style";
import { useParams } from "react-router-dom";
import { FileBussinessType } from "@interfaces/file.interface";
import Layout from "@components/template/layout";
import useChampionship from "@hooks/useChampionship";
import Details from "./tabs/details";
import useUser from "@hooks/useUser";
import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChampionshipStatus,
  ChampionshipType,
  ParticipantSituation,
} from "@interfaces/championships.interface";
import RegistrationModal from "./components/modalRegistration";
import UserNotRegisterModal from "./components/modalUserNotRegister";
import RegistrationUserModal from "./components/modalRegisterUser";
import Regulation from "./tabs/regulation";
import Participants from "./tabs/participants";
import TableChampionships from "./tabs/tableChampionships";

export const ChampionshipDetails = () => {
  const { championshipId } = useParams();
  const {
    championship,
    registerTeamChampionship,
    registerUserChampionship,
    participantsChampionship,
    isLoadingParticipantChampionship,
    championshipsTournament,
    isLoadingTournament,
  } = useChampionship(championshipId!);
  const { userAuth, teams } = useUser();
  const modalNotResgister = useDisclosure();
  const modalResgisterTeam = useDisclosure();
  const modalResgisterIndividual = useDisclosure();

  const registrationOpen =
    championship?.entity.status === ChampionshipStatus.OPEN_FOR_REGISTRATION;

  const textButton = (status: string) => {
    return (
      {
        UPCOMING: "EM BREVE",
        FINISHED: "FINALIZADO",
        CANCELLED: "CANCELADO",
        REGISTRATION_CLOSED: "INSCRIÇÕES ENCERRADAS",
        OPEN_FOR_REGISTRATION: "INSCREVA-SE JÁ",
        ONGOING: "EM ANDAMENTO",
      }[status] || ""
    );
  };
  const handlerClick = async (teamId: string) => {
    modalResgisterTeam.onClose();
    await registerTeamChampionship(championship?.entity.id!, teamId);
    window.location.reload();
  };
  const openModal = () => {
    if (!userAuth) {
      modalNotResgister.onOpen();
      return;
    }
    if (!!userAuth && championship?.entity.type === ChampionshipType.TEAM) {
      modalResgisterTeam.onOpen();
      return;
    }
    if (
      !!userAuth &&
      championship?.entity.type === ChampionshipType.INDIVIDUAL
    ) {
      modalResgisterIndividual.onOpen();
      return;
    }
  };
  const handlerCloseModal = async (wasAccept: boolean) => {
    modalResgisterIndividual.onClose();
    if (!wasAccept) return;
    await registerUserChampionship(championship?.entity.id!);
    window.location.reload(); // TODO => Refatorar
  };
  const myTeamsId = teams.map((item) => item.entity.id);
  const participants = participantsChampionship?.map(
    (item) => item.participant.entity_id
  );
  const participantsChampionshipActive = participantsChampionship?.filter(
    (item) => item.participant.situation !== ParticipantSituation.CANCELLED
  );

  const hasCommonValue = participants?.some(
    (participant) =>
      myTeamsId.includes(participant) || participant === userAuth?.id
  );

  const isRegulations =
    championship !== undefined &&
    Object.keys(championship.entity.regulations).length > 0;

  return (
    <>
      <Layout showSidebar={false}>
        <S.RedLine />
        <Flex flexDirection="column">
          <GS.BannerImg
            src={
              championship?.files.find(
                (item) =>
                  item.business_type === FileBussinessType.CHAMPIONSHIP_BANNER
              )?.url
            }
          />
          <Flex
            flexDirection="column"
            justifyContent="center"
            gap="30"
            align="center"
            marginTop="-100px"
          >
            <Text color="#F0E6D2" fontSize="1.8rem">
              {championship?.entity.name}
            </Text>
            <Button
              bg={!hasCommonValue && registrationOpen ? "#E94560" : "#5C5A68F2"}
              borderRadius="5px"
              minW="220px"
              h="55px"
              color="#ffffff"
              onClick={openModal}
              isDisabled={
                hasCommonValue ||
                !registrationOpen ||
                isLoadingParticipantChampionship
              }
              _hover={{
                backgroundColor:
                  !hasCommonValue && registrationOpen ? "#E94560" : "#5C5A68F2",
              }}
              _disabled={{
                backgroundColor:
                  !hasCommonValue && registrationOpen ? "#E94560" : "#5C5A68F2",
                cursor: "not-allowed",
              }}
            >
              {!hasCommonValue
                ? textButton(championship?.entity.status!)
                : "JÁ INSCRITO"}
            </Button>
          </Flex>
          {/* <Flex
            background="linear-gradient(182.13deg, rgba(13, 23, 38, 0.3) 25.32%, #0D1726 68.22%)"
            w="100%"
            alignItems="center"
            justifyContent="center"
            h="110px"
            gap="2"
          >
            <TbClockHour4 size="25" />
            <Text fontSize="1.1rem" fontWeight="600">
              Inscrições encerram em 15 dias
            </Text>
          </Flex> */}
        </Flex>
        <GS.TabsContainer>
          <Tabs w={"100%"} colorScheme={"#FFFFFF"}>
            <TabList borderBottomColor={"#E94560"}>
              <Tab>Detalhes</Tab>
              <Tab>Participantes</Tab>
              <Tab>Tabelas</Tab>
              <Tab isDisabled={!isRegulations}>Regulamento</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Details championship={championship!} />
              </TabPanel>
              <TabPanel>
                <Participants
                  participantList={participantsChampionshipActive || []}
                  championshipsType={championship?.entity.type!}
                  loading={isLoadingParticipantChampionship}
                />
              </TabPanel>
              <TabPanel>
                <TableChampionships
                  tournaments={championshipsTournament}
                  isLoading={isLoadingTournament}
                />
              </TabPanel>
              <TabPanel>
                <Regulation regulation={championship?.entity.regulations!} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GS.TabsContainer>
      </Layout>
      <UserNotRegisterModal
        isOpen={modalNotResgister.isOpen}
        onClose={modalNotResgister.onClose}
      />
      <RegistrationUserModal
        isOpen={modalResgisterIndividual.isOpen}
        handlerClose={handlerCloseModal}
        userName={userAuth?.name!}
      />
      <RegistrationModal
        isOpen={modalResgisterTeam.isOpen}
        teams={teams.map((item) => ({
          id: item.entity.id,
          name: item.entity.name,
          imgPath: "",
          totalMembers: item.entity.team_members_count,
          isOwner: item.entity.owner_id === userAuth?.id,
          isSelect: false,
        }))}
        championship={{
          name: championship?.entity.name!,
          participantLimit: championship?.entity.participant_limit!,
        }}
        handlerClose={modalResgisterTeam.onClose}
        handlerClick={handlerClick}
      />
    </>
  );
};
