import styled from "styled-components";
import { device } from "../../utils";

export const ProfileAvatarWrapper = styled.div`
  display: flex;
`;

export const ProfileAvatarTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-left: 1rem;

  @media ${device.desktopSmall} {
  }
`;

export const ContainerProfileAvatar = styled.div`
  :hover {
    background-color: red;
  }
`;

export const ProfileAvatar = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 16px;
  @media ${device.desktopSmall} {
  }
`;

export const ProfileNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ProfileName = styled.h1`
  color: var(--text-color);
  font-size: 26px;
  font-weight: 400;
  margin-top: 50px;

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export const BadgesContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const ProfileStatus = styled.h2`
  color: #ffffff88;
  font-size: var(--font-size-small);
  font-weight: 400;
`;

export const LargeBadgesContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;
