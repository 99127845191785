import * as Icons from "lucide-react";
import { InforCard, InforCardItem } from "@components/infoCard";
import { AuthenticatedUserDisplay } from "@components/authenticatedUserDisplay";
import useUser from "@hooks/useUser";
import * as S from "./styles";
import { useParams } from "react-router-dom";
import { Flex, Skeleton, useDisclosure } from "@chakra-ui/react";
import useUserPublic from "@hooks/useUserPublic";
import UpdateProfileModal from "./components/updateProfileModal";
import { useQuery } from "@tanstack/react-query";
import SetupPresentation from "./components/setupPresentation";
import { optionsFetchSetupUserByIdPublic } from "@plugins/reactQuery/user.query-options";
import Badget from "@pages/profile/components/badget/Badget";

const GeneralProfile: React.FC = () => {
  const { userId } = useParams();
  const { userAuth } = useUser();
  const { user } = useUserPublic(userId!);

  const { data: setup } = useQuery(optionsFetchSetupUserByIdPublic(userId!));
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Flex gap={"2rem"} flexDir={"column"}>
        <Flex flexDir={{ base: "column", lg: "row" }} w={"100%"} gap={"26px"}>
          <Flex flex={"1"} w={"100%"}>
            <Badget />
          </Flex>
          <Flex flexDir={"column"}>
            <S.Title>
              Sobre mim
              <AuthenticatedUserDisplay.True rule={userAuth?.id === user?.id}>
                <Icons.PencilLine
                  className="titleIcon"
                  size={22}
                  onClick={onOpen}
                />
              </AuthenticatedUserDisplay.True>
            </S.Title>

            <Skeleton
              isLoaded={!!user}
              style={{ width: "100%", borderRadius: "1rem" }}
              startColor="#E2E8F0"
              endColor="#718096"
            >
              <S.AboutMeWrapper>
                <InforCard>
                  <InforCardItem keyProp="Nome" value={user?.name} />
                  <InforCardItem keyProp="Nick" value={user?.nick} />
                  <AuthenticatedUserDisplay.True rule={user?.id === user?.id}>
                    <InforCardItem keyProp="CPF" value={user?.cpf} />
                    <InforCardItem keyProp="Telefone" value={user?.phone} />
                    <InforCardItem keyProp="E-mail" value={user?.email} />
                  </AuthenticatedUserDisplay.True>
                  <InforCardItem keyProp="Gênero" value={user?.gender} />
                  <AuthenticatedUserDisplay.True rule={user?.id === user?.id}>
                    <InforCardItem keyProp="Steam ID" value={user?.steam_id} />
                  </AuthenticatedUserDisplay.True>
                </InforCard>
              </S.AboutMeWrapper>
            </Skeleton>
          </Flex>
        </Flex>
        <Flex>
          <SetupPresentation setup={setup} />
        </Flex>
      </Flex>
      <UpdateProfileModal isOpen={isOpen} handlerClose={onClose} />
    </>
  );
};

export default GeneralProfile;
