import { useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import { MdOutlineEdit } from "react-icons/md";
import { useDebounceEffect } from "./useDebounceEffect";
import { canvasPreview } from "./canvasPreview";
import { Button, Flex, Img, Input, Text } from "@chakra-ui/react";
import { FileImageCropper } from "@interfaces/file.interface";
import { Spinner } from "@chakra-ui/react";
import useFile from "@hooks/useFile";

type Props = {
  file: FileImageCropper;
  isBanner?: boolean;
  handlerCompleted: () => void;
  crop: {
    width: number;
    height: number;
    isCircularCrop: boolean;
  };
};

const ImageCropper: React.FC<Props> = ({
  file,
  isBanner = false,
  handlerCompleted,
  crop,
}) => {
  const { uploadFile } = useFile();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [imgSrc, setImgSrc] = useState("");
  const [finalImg, setFinalImg] = useState(
    file.url
      ? file.url
      : "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"
  );
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [fileName, setFileName] = useState<string>("");
  const [cropConfig, setCropConfig] = useState<Crop>({
    width: crop.width,
    height: crop.height,
    unit: "px",
    x: 0,
    y: 0,
  });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const scale = 1;
  const rotate = 0;
  const aspect = isBanner ? 16 / 9 : 1;

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFileName(e.target.files?.[0].name);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      const percentCrop = centerAspectCrop(width, height, aspect);

      const pixelCrop: PixelCrop = {
        unit: "px",
        width: (percentCrop.width / 100) * width,
        height: (percentCrop.height / 100) * height,
        x: (percentCrop.x / 100) * width,
        y: (percentCrop.y / 100) * height,
      };
      setCropConfig(percentCrop);
      setCompletedCrop(pixelCrop);
    }
  };

  const handleConfirmClick = async () => {
    try {
      setIsLoading(true);
      await uploadFile(finalImg, file);
    } finally {
      setIsLoading(false);
      handlerCompleted();
    }
  };

  const handleFileClick = () => {
    inputRef.current?.click();
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        const base64 = previewCanvasRef.current.toDataURL("image/png");
        setFinalImg(base64);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      {isLoading ? (
        <Flex justifyContent="center">
          <Spinner color="#E94560" size="xl" thickness="4px" />
        </Flex>
      ) : (
        <>
          <Flex
            flexDirection="column"
            w="100%"
            maxH="600px"
            overflow="auto"
            position="relative"
          >
            {!imgSrc && (
              <Flex flexDir={"column"}>
                <Img
                  alignSelf={"center"}
                  src={finalImg}
                  alt="Final"
                  width={crop.isCircularCrop ? "210px" : "auto"}
                  height={crop.isCircularCrop ? "210px" : "auto"}
                  borderRadius={crop.isCircularCrop ? "50%" : ""}
                />
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                </div>
              </Flex>
            )}
            {!!imgSrc && (
              <ReactCrop
                crop={cropConfig}
                onChange={(_, percentCrop) => setCropConfig(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                circularCrop={crop.isCircularCrop}
                locked
                keepSelection
                style={{}}
              >
                <Img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  onLoad={onImageLoad}
                  h="100%"
                  w="100%"
                  objectFit="contain"
                  display="block"
                  margin="0 auto"
                />
              </ReactCrop>
            )}

            <canvas
              ref={previewCanvasRef}
              style={{
                display: "none",
                width: completedCrop?.width,
                height: completedCrop?.height,
              }}
            />
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginTop="20px"
          >
            <Input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              display="none"
              ref={inputRef}
            />
            <Button
              variant="outline"
              color="#E94560"
              border="1px solid #E94560"
              onClick={handleFileClick}
              maxW="350px"
              h="42px"
              _hover={{
                backgroundColor: "none",
              }}
            >
              <MdOutlineEdit size="30px" />
              <Text padding="6px" overflow="hidden">
                {fileName !== "" ? fileName : "Selecione um Arquivo"}
              </Text>
            </Button>
            {!!completedCrop && imgSrc && (
              <Button
                onClick={handleConfirmClick}
                h="42px"
                bg="#E94560"
                color="#ffffff"
              >
                Confirmar
              </Button>
            )}
          </Flex>
        </>
      )}
    </>
  );
};

export default ImageCropper;
