const TermsUse: React.FC = () => {
  return (
    <>
      {" "}
      <p>
        PRO GAMERS LTDA, com CNPJ nº <strong>52.872.402/0001-80</strong>, de
        nome fantasia “Pro Gamers” é uma empresa que proporciona o gerenciamento
        de campeonatos de esportes e jogos eletrônicos, atuando desde o momento
        de inscrição, com prestação de informações relativas a este, até o
        momento de pagamento de premiação respectiva.
        <br />
        Os seguintes termos de uso regem o uso do serviço de plataforma prestado
        pela Pro Gamers em seus aspectos atinentes ao evento.
        <br />
        Os termos e denominações “Pro Gamers”, “Serviço Pro Gamers”, “Serviço” e
        “Nosso Serviço”, se referem ao serviço de gerenciamento prestado pela
        empresa Pro Gamers nos moldes supracitados, relativos ao que permite o
        acesso ao site da Web e a interface do usuário, assim como todo o
        conteúdo e software associado ao serviço.
        <br />
        Estes termos fazem relação anexa à política de privacidade bem como aos
        termos de conduta, devendo ser lidos e aceitos em conjunto.
        <br />
        Para utilizar os serviços prestados pela Pro Gamers, o usuário deverá
        possuir 18 anos ou ser maior de idade em seu estado, território ou país.
        Menores de 18 anos ou da respectiva idade de maioridade aplicável em seu
        país de origem, somente poderão utilizar o nosso serviço com o
        consentimento de um dos pais ou responsáveis, mediante uso da conta com
        titularidade destes, mediante aceitação dos presentes Termos de Uso.
      </p>
      <p>
        <strong>DA ACEITAÇÃO DOS TERMOS DE USO</strong>
      </p>
      <p>
        Ao acessar, usar ou navegar pela plataforma de web Pro Gamers, você
        aceita e concorda com os presentes Termos de Uso, com a nossa Política
        de Privacidade e com as nossas Regras de Conduta. Se você não concordar
        com quaisquer desses documentos, não utilize a plataforma e o serviço.
      </p>
      <p>
        <strong>DOS DADOS NECESSÁRIOS</strong>
      </p>
      <p>
        Ao se inscrever para utilizar os serviços prestados pela Pro Gamers,
        você terá de fornecer alguns de seus dados pessoais e/ou financeiros.
        Você deve estar ciente de que:
      </p>
      <ul>
        <li>
          São requisitados apenas os dados estritamente necessários para a
          formalização de seu cadastro, viabilização da cobrança dos serviços
          (caso você contrate serviços pagos) e para a prestação dos serviços,
          incluindo-se a sua localização e dados eletrônicos (IP e tempo de
          utilização da plataforma, por exemplo), a fim de fazer valer as regras
          de conduta da Pro Gamers e as punições em caso de descumprimento;{" "}
        </li>
        <li>
          Poderão ser requisitados no momento do cadastro e/ou da inscrição em
          campeonato os seguintes dados: (I) e-mail; (II) endereço completo;
          (III) dados do titular da cobrança; (IV) CPF; (V) RG; (VI) dados de
          cartão de crédito necessários para cobrança.{" "}
        </li>
        <li>
          Além dos supradescritos, alguns outros dados poderão ser coletados
          automaticamente, conforme o item seguinte. Ao visitar o site da Pro
          Gamers, você estará autorizando o tratamento interno desses dados,
          expressamente.{" "}
        </li>
        <li>
          Ao assinar os serviços e fornecer os seus dados, você estará
          autorizando expressamente o tratamento dos seus dados pessoais por
          parte dos colaboradores da Pro Gamers. Se você estiver assinando em
          favor de um menor de 18 anos do qual seja responsável, estará
          autorizando o tratamento dos seus dados e dos dados do menor.{" "}
        </li>
        <li>
          Os dados fornecidos não serão fornecidos a terceiros, salvo nos casos
          em que: (I) obtenhamos sua autorização expressa, (II) os dados sejam
          fornecidos de forma anônima, (III) os seus dados sejam requisitados
          por autoridades públicas, (IV) por qualquer outro motivo obrigatório
          previsto na Lei Geral de Proteção de Dados (Lei 13.709/2018). Para as
          hipóteses (II) a (IV) a efetivação de seu cadastro corresponde a
          autorização expressa para tanto;{" "}
        </li>
        <li>
          Qualquer informação sobre o tratamento de seus dados pessoais, ou
          correção e atualização dos mesmos, poderá ser obtida por você através
          dos canais de relacionamento com a Pro Gamers disponíveis no site,
          como e-mail e redes sociais.{" "}
        </li>
        <li>
          Os colaboradores da Pro Gamers que farão o tratamento de seus dados
          pessoais trabalham sob regras de sigilo absoluto e utilizando
          procedimentos de segurança eletrônica especializada. Faremos, ainda,
          todos os registros de tratamento dos dados pessoais de nossos
          clientes. Seus dados, assim, estão sob os melhores mecanismos de
          proteção. No entanto, não é possível atestar que qualquer sistema seja
          completamente seguro contra invasões. A Pro Gamers se exime de
          qualquer responsabilidade por eventuais danos e/ou prejuízos
          decorrentes de falhas, vírus ou invasões do banco de dados e/ou
          servidores do site, salvo os casos em que houver comprovação de dolo
          ou culpa do nosso serviço.{" "}
        </li>
        <li>
          Tendo em vista que a Pro Gamers utiliza softwares anticheat de
          terceiros que hospedam a partida. Logo, tendo em vista tal uso nos
          casos de campeonatos de jogos como Counter-Strike: Global Offensive, o
          usuário fica sujeito a disponibilidade de dados relativos ao
          anti-cheat de terceiros, nos moldes dos termos de uso do site,
          plataforma ou software em questão.
        </li>
      </ul>
      <p>
        <strong>DA COBRANÇA</strong>
      </p>
      <p>
        Do momento em que o usuário se inscrever em qualquer campeonato ou
        qualquer oferta de serviço prestado pela Pro Gamers, seja individual ou
        coletivamente, a este será emitido um valor relativo a termo de
        inscrição a ser pago para a devida efetivação do cadastro no respectivo
        campeonato ou serviço, de maneira que o não pagamento do mesmo sujeita
        este a ter sua inscrição cancelada por falta de pagamento dentro do
        prazo estipulado no regulamento do serviço prestado, seja campeonato ou
        outrem.
      </p>
      <p>
        <strong>DAS FORMAS DE PAGAMENTO</strong>
      </p>
      <p>
        As formas de pagamento de inscrição em campeonatos, aulas ou serviços
        prestados pela Pro Gamers serão realizados no momento efetivo do
        cadastro nesse serviço, ficando inclusive sujeitos ao pagamento para sua
        devida efetivação. Das formas de pagamento, serão oferecidos modais
        diferentes, como boleto bancário e cartão, utilizando sistema
        terceirizado para efetivação da criação do boleto ou do devido pagamento
        via cartão de crédito. As políticas referentes ao pagamento via cartão
        serão por intermédio da terceira que definirá o modelo de pagamento com
        base nos dados disponibilizados.
      </p>
      <p>
        <strong>DO REEMBOLSO</strong>
      </p>
      <p>
        Tendo em vista a natureza imediata do serviço, os pagamentos não serão
        reembolsáveis, tendo em vista a utilização imediata da disponibilização
        do serviço, como disponibilidade de conteúdo específico e/ou
        contabilização de inscrição no campeonato, tendo em vista sua natureza
        extinguível e não retroativa. A qualquer momento e por qualquer motivo,
        a Pro Gamers poderá reembolsar, dar descontos ou outras formas de
        consideração a algum ou a todos os contratantes. O valor e forma de tais
        créditos, assim como a decisão de emiti-los, ficam a critério exclusivo
        e absoluto da Pro Gamers, que inclusive poderá tratar e regulamentar por
        via da normatização específica do serviço, como o regulamento do
        campeonato específico.
      </p>
      <p>
        <strong>DO CANCELAMENTO</strong>
      </p>
      <p>
        O cancelamento de qualquer serviço prestado pela Pro Gamers será por via
        do contato por intermédio de comunicação oficial via e-mail
        contato@progamers.com.br e poderá ser complementada via comunicação
        pelos meios de mídias sociais. Além disso, o cancelamento do serviço
        ficará sujeito ao regulamento do serviço em específico, que ficará a
        disposição no momento da realização deste. Além disso, ressaltamos que o
        cancelamento do serviço não gerará reembolso caso este não tenha sido
        usado ou tenha sido usado parcialmente, ou seja, um campeonato já
        utilizado ou outro serviço utilizado pela metade, tendo o consumidor já
        usufruído do serviço.
      </p>
      <p>
        <strong>DO SERVIÇO Pro Gamers</strong>
      </p>
      <ul>
        <li>
          O serviço prestado pela Pro Gamers e todo conteúdo visualizado por
          intermédio da plataforma e serviço destinam-se exclusivamente ao uso
          pessoal e não comercial.{" "}
        </li>
        <li>
          A Pro Gamers atualiza a plataforma e serviço continuadamente. Além
          disso, diferentes aspectos do serviço são testados continuadamente,
          incluindo o site, interfaces de usuário, e novos eventos. A Pro Gamers
          reserva o direito de conduzir testes, e ao utilizar o serviço você
          concorda que a nós possamos incluir ou excluir você destes testes sem
          aviso prévio.{" "}
        </li>
        <li>
          Você concorda em usar o serviço Pro Gamers, incluindo todos os
          recursos e funcionalidades do mesmo, segundo todas as leis,
          regulamentos e códigos aplicáveis, inclusive respeitando as restrições
          neles previstas.{" "}
        </li>
        <li>
          Você concorda em não arquivar, baixar (exceto o cache necessário para
          o uso pessoal), reproduzir, distribuir, modificar, exibir, publicar,
          licenciar ou criar trabalhos derivados, colocar à venda ou utilizar
          (exceto das formas expressamente autorizadas por esses Termos de Uso)
          o conteúdo e as informações contidas ou obtidas do ou por intermédio
          do nosso serviço sem a autorização expressa e por escrito da Pro
          Gamers.{" "}
        </li>
        <li>
          Você se responsabiliza por todas as tarifas de acesso à Internet.
          Consulte seu provedor de internet para obter informações sobre os
          custos de utilização de dados. A Pro Gamers não faz afirmações ou
          garantias com respeito à qualidade dos servidores. O tempo de resposta
          dos servidores poderá variar segundo uma série de fatores, incluindo
          sua localização, a largura de banda então disponível e as
          configurações do aparelho utilizado e sua compatibilidade com a
          plataforma.
        </li>
      </ul>
      <p>
        <strong>DAS INFRAÇÕES E PENALIDADES</strong>
      </p>
      <p>
        Para mais detalhes sobre infrações e penalidades, por favor, consulte
        nossas Regras de Conduta. De antemão, contudo, você deve estar bem
        ciente de que, para utilizar os serviços da Pro Gamers você concorda a:
      </p>
      <ul>
        <li>
          Não evadir, remover, alterar, desativar, degradar ou adulterar
          quaisquer das proteções de conteúdo do serviço Pro Gamers, usar
          qualquer robô, spider, scraper ou outras formas automatizadas para
          acessar o nosso serviço, descompilar, executar engenharia reversa ou
          desmembrar qualquer software ou outros produtos ou processos
          acessíveis pelo serviço, inserir qualquer código ou produto, manipular
          o conteúdo do serviço Pro Gamers de qualquer forma ou usar métodos de
          data mining, coleta de dados ou extração de dados. Você deve estar
          ciente de que, se a Pro Gamers detectar quaisquer das práticas acima,
          poderá, unilateralmente, banir e bloquear a conta do usuário, sem
          direito a qualquer indenização ao titular da conta.{" "}
        </li>
        <li>
          Não fazer upload, publicar, enviar por e-mail, comunicar ou transmitir
          de qualquer forma qualquer material designado para interromper,
          destruir ou limitar a funcionalidade de qualquer software, hardware ou
          equipamento de telecomunicações associado ao serviço, incluindo vírus
          de software, código, arquivos ou programas. Você deve estar ciente de
          que, se a Pro Gamers detectar quaisquer das práticas acima, poderá,
          unilateralmente, banir e bloquear a conta do usuário, sem direito a
          qualquer indenização ao titular da conta.{" "}
        </li>
        <li>
          Não utilizar quaisquer programas de terceiros não autorizados,
          incluindo, mas não limitado a "mods", "hacks", "cheats" "scripts,"
          "bots", "trainers" e programas de automação que possam alterar sua
          performance. Você deve estar ciente de que, se a Pro Gamers detectar
          quaisquer das práticas acima, poderá, unilateralmente, banir e
          bloquear a conta do usuário, sem direito a qualquer indenização ao
          titular da conta.{" "}
        </li>
        <li>
          Além das condutas acima explicitadas, você concorda que a Pro Gamers,
          visando uma melhor experiência de jogo aos seus usuários, aplique
          punições automáticas aos usuários em razão de determinados
          comportamentos durante o uso da plataforma, ainda que tais
          comportamentos decorram de fatores externos e/ou alheios à vontade do
          próprio usuário.{" "}
        </li>
        <li>
          Você fica ciente de que, caso haja a suspensão ou o banimento
          temporário de acesso aos serviços prestados pela Pro Gamers, por
          quaisquer dos motivos listados nesta seção, ou por infração às regras
          de conduta, o usuário fica sujeito a desligamento de quaisquer
          serviços a que esteja atrelado, como de campeonato.
        </li>
      </ul>
      <p>
        <strong>DAS SENHAS E ACESSO A CONTAS</strong>
      </p>
      <ul>
        <li>
          O assinante que criou a conta na plataforma Pro Gamers, ou o seu
          responsável legal quando for o caso, é designado como o titular da
          conta nos presentes Termos de Uso. O titular da conta tem acesso e
          controle sobre a conta na plataforma. Você é responsável por atualizar
          e manter a veracidade e acuidade das informações fornecidas à Pro
          Gamers relativas à sua conta.{" "}
        </li>
        <li>
          Para simplificar o acesso à sua conta e ajudar a administrar o
          serviço, a Pro Gamers emprega tecnologias que nos permitem reconhecer
          você como o titular da conta e lhe dar acesso direto à sua conta sem
          exigir que você digite sua senha ou qualquer outra informação de
          identificação pessoal quando você volta a acessar o nosso serviço, o
          que inclui acessar a Pro Gamers por meio de aplicativos de terceiros
          que interagem com a plataforma ou do site.{" "}
        </li>
        <li>
          Exerça cautela com respeito a comunicados que solicitem o envio de
          dados de cartão de crédito ou outras informações sobre sua conta.
          Responder a comunicados dessa natureza com informações pessoais poderá
          resultar no roubo de seus dados pessoais. Sempre acesse informações
          pessoais sobre sua conta diretamente no site da Pro Gamers e não
          clicando em links em e-mails ou outros tipos de comunicados
          eletrônicos, mesmo se o comunicado lhe parecer oficial. A Pro Gamers
          reserva para si o direito de suspender qualquer conta a qualquer
          momento, com ou sem aviso prévio ao titular da conta, para proteger a
          si mesma e empresas associadas de atividades suspeitas de serem
          fraudulentas. A Pro Gamers não será obrigada a reembolsar ou dar
          descontos a assinantes cujas contas foram suspensas por um
          representante da mesma ou por processos automatizados implementados
          pela Pro Gamers em tais circunstâncias.Você concorda em usar o serviço
          Pro Gamers, incluindo todos os recursos e funcionalidades do mesmo,
          segundo todas as leis, regulamentos e códigos aplicáveis, inclusive
          respeitando as restrições neles previstas.
        </li>
      </ul>
      <p>
        <strong>
          DOS TERMOS DE ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE
        </strong>
      </p>
      <ul>
        <li>
          O serviço Pro Gamers e todos os conteúdos e softwares associados,
          assim como quaisquer outros recursos ou funcionalidades associadas ao
          serviço, são fornecidos "no seu estado atual" e "conforme
          disponibilidade", com todas as suas eventuais falhas e sem garantias
          de qualquer espécie. A Pro Gamers não faz afirmações ou dá garantias
          de qualquer tipo de que seu uso do serviço será contínuo ou livre de
          erros. A Pro Gamers isenta-se especificamente de qualquer
          responsabilidade pelo uso de aplicativos, aparelhos compatíveis com a
          plataforma e softwares (incluindo a compatibilidade contínua com nosso
          serviço).{" "}
        </li>
        <li>
          Até a máxima extensão permitida por lei, em nenhum evento a Pro
          Gamers, suas subsidiárias ou seus acionistas, diretores, executivos,
          funcionários ou licenciadores deverão ser responsabilizados
          (subsidiariamente ou solidariamente) em relação a você por danos de
          qualquer natureza, inclusive morais, especiais, incidentais, indiretos
          ou consequenciais.{" "}
        </li>
        <li>
          Determinadas jurisdições não permitem a exclusão de determinadas
          garantias ou da limitação ou exclusão de determinados tipos de danos.
          Portanto, determinadas limitações desta seção podem não se aplicar a
          você.{" "}
        </li>
        <li>
          Nenhuma disposição nesses termos deverá afetar qualquer direito legal
          que lhe assista. Se qualquer disposição ou disposições desses Termos
          de Uso forem consideradas inválidas, ilegais ou não aplicáveis, a
          validade, legalidade e aplicabilidade das demais disposições devem
          permanecer em pleno vigor.
        </li>
      </ul>
      <p>
        <strong>DA PROPRIEDADE INTELECTUAL</strong>
      </p>
      <ul>
        <li>
          Direitos autorais: O serviço prestado pela Pro Gamers é protegido por
          direitos autorais, segredos comerciais e outras Leis e Tratados de
          propriedade intelectual.{" "}
        </li>
        <li>
          Marcas registradas: A Pro Gamers é uma marca registrada por seus
          proprietários, não podendo, de qualquer maneira ser utilizada, sob
          quaisquer formas ou condições, sem autorização expressa da mesma.{" "}
        </li>
        <li>
          Alegação de infração de direitos autorais: Se você acredita que o seu
          trabalho foi reproduzido ou distribuído de forma que constitui
          infração de direitos autorais ou se você tem conhecimento de material
          que infrinja as leis de direitos autorais por meio do serviço prestado
          pela Pro Gamers, notifique-nos pelo e-mail contato@progamers.com.br.
        </li>
      </ul>
      <p>
        <strong>DA LEGISLAÇÃO VIGENTE</strong>
      </p>
      <ul>
        <li>
          Se você é residente do Brasil, esses Termos de Uso devem ser regidos
          por e interpretados de acordo com a legislação do Brasil.{" "}
        </li>
        <li>
          Você também pode ter direito a determinados direitos de proteção ao
          consumidor sob a legislação de sua jurisdição local.
        </li>
      </ul>
      <p>
        <strong>DOS APLICATIVOS</strong>
      </p>
      <ul>
        <li>
          Você poderá encontrar aplicativos de terceiros (incluindo, mas não
          limitando-se a websites, widgets, software ou outros utilitários de
          software) que interagem com o serviço prestado pela Pro Gamers. Esses
          aplicativos podem importar dados relacionados à sua conta na
          plataforma e atividade e obter seus dados pessoais e ou de navegação,
          mediante autorização específica, providenciada pelo próprio fabricante
          do aplicativo. Certifique-se, em caso de uso desses aplicativos, de
          ter lido a sua política de privacidade e de só utilizá-los se estiver
          de acordo com tal política.{" "}
        </li>
        <li>
          Esses aplicativos são fornecidos somente para sua conveniência. A Pro
          Gamers não é responsável por eles. Esses aplicativos são de
          propriedade e operados por terceiros não relacionados nem patrocinados
          pela Pro Gamers e podem não estar autorizados para uso com o serviço
          prestado pela Pro Gamers em todos os países. A utilização de um
          aplicativo terceirizado fica a seu critério e risco.
        </li>
      </ul>
      <p>
        <strong>DO USO DAS INFORMAÇÕES FORNECIDAS</strong>
      </p>
      <p>
        A Pro Gamers terá liberdade de usar quaisquer comentários, informações,
        ideias, conceitos, opiniões, técnicas ou quaisquer outros materiais
        contidos em qualquer comunicação que você envie para nós ("feedback"),
        incluindo respostas a questionários por meio do nosso serviço, incluindo
        o site da Pro Gamers e suas interfaces de usuário, em caráter global e
        perpétuo, sem remuneração, reconhecimento ou pagamento a você por
        qualquer motivo, incluindo mas não limitando-se a desenvolvimento,
        fabricação e comercialização de produtos e criação, modificação ou
        aprimoramento do serviço prestado. Além disso, você concorda em não
        reivindicar quaisquer "direitos morais" ao feedback, até a extensão
        permitida pelas leis aplicáveis. Se você enviar quaisquer materiais ou
        ideias não solicitados, faça-o com o entendimento de que não será devido
        qualquer pagamento de qualquer natureza a você e de que você está
        concordando em isentar a Pro Gamers e suas afiliadas de qualquer
        reclamação ou processo relativo ao uso de tais materiais e ideias, mesmo
        que o material ou ideia seja usado e seja substancialmente semelhante ao
        material ou ideia enviado por você.
      </p>
      <p>
        <strong>DO ATENDIMENTO AO CLIENTE</strong>
      </p>
      <p>
        Para obter mais informações sobre o serviço prestado pela Pro Gamers e
        seus recursos, ou se precisar de ajuda com sua conta, entre em contato
        com a administração via e-mail oficial contato@progamers.com.br , Em
        situações específicas, a melhor opção para o atendimento ao cliente é
        utilizar uma ferramenta de acesso remoto que dá acesso total ao seu
        computador. Ao requisitar suporte e atendimento, se esta for a melhor
        opção, você estará concordando integralmente com a medida.
      </p>
      <p>
        <strong>DA VALIDADE DAS CLÁUSULAS</strong>
      </p>
      <p>
        Se qualquer disposição ou disposições desses Termos de Uso forem
        consideradas inválidas, ilegais ou não aplicáveis, a validade,
        legalidade e aplicabilidade das demais disposições devem permanecer em
        pleno vigor.
      </p>
    </>
  );
};

export default TermsUse;
