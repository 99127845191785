import { IFile } from "./file.interface";
import { Genders, IUser } from "./user.interface";

export interface IUserContext {
  user: IUser | undefined;
  files: IFile[];
  isAuthenticated: boolean;
  isLoadingAuth: boolean;
  updateToken: (token: string | null) => void;
  isOpenUserProfileFinishingModal: boolean;
  onCloseUserProfileFinishingModal: () => void;
  onOpenUserProfileFinishingModal: () => void;
}

export interface ILoginSocial {
  token: string;
  providerType: SocialLoginProvierType;
}

export enum SocialLoginProvierType {
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  STEAM = "STEAM",
}

export interface ILoginResponse {
  id: string;
  token: string;
}

export interface INormalLogin {
  email: string;
  password: string;
}

export interface IRegister {
  email: string;
  password: string;
  name: string;
  nick: string;
  provider: string;
  cpf: string;
  phone: string;
  gender: Genders;
  passwordConfirm: string;
}

export interface IChangePassword extends IChangePasswordParams {
  password: string;
  passwordConfirm: string;
}

export interface IChangePasswordParams {
  email?: string;
  token?: string;
}

export interface IChangePasswordRequest extends IChangePasswordParams {
  password: string;
}

export interface IResetPassword {
  email: string;
}

export type IRegisterRequest = Omit<IRegister, "passwordConfirm">;
