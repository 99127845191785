import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @font-face {
    font-family: 'Quinn';
    src: local('Quinn'), url('../../../assets/fonts/quinn/Quinn-Bold.otf') format('otf');
  }
  @font-face {
    font-family: 'Serotiva';
    src: local('Serotiva'), url('../../../assets/fonts/serotiva/Serotiva-Light.otf') format('otf');
  }

  :root{

    /* borders */
    
    --box-shadow-default: 0rem 0rem 1rem var(--layout-color);
    --box-shadow-container-parceiros:0rem 0rem 4rem #0000008a;

    /* colors */

    --layout-color: #E94560;
    --text-color: #ffffff;
    --background-color: #0A1428;
    --background-container-parceiros-color:#102131 0% 0% no-repeat padding-box;
    --placeholder-form-color:#747474;
    --option-form-color:#000;
    --button-hover-color:#D43E56;
    --containers-background-color: #0c1a27;

    /* font */

    --body-font: 'Quinn', 'Serotiva';

    --font-light: 300;
    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;
    --font-extra-bold: 800;
    --font-black: 900;

    --font-size-small: 12px;
    --font-size-medium: 14px;
    --font-size-base: 16px;
    --font-size-large: 18px;
    --font-size-xlarge: 20px;
  }

  *, body, html, #root{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    object-fit: cover;
    font-family: var(--body-font);
  }
  
  #root {
    background-color: var(--background-color);
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }
  
  body{
    background-color: var(--background-color);
  }

  .glow{
    box-shadow: 0rem 0rem 40rem var(--layout-color);
    background: var(--layout-color);
    color: var(--text-color) !important;
    border-radius: 1rem;
     
    text-align: center;
    font: normal normal normal 20px/30rem Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border: 0rem;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: var(--layout-color);
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--layout-color);
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: var(--layout-color);
  }

  button:hover{
    cursor:pointer;
  }

  input {
    outline: 0;
  }
  
  .no-scroll{
    overflow: hidden;
  }
`;
