import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  margin: 2rem 0;

  @media (max-width: 440px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.p`
  font-size: var(--font-size-large);
  font-weight: 700;
  color: var(--text-color);
  margin-bottom: 1rem;
`;

export const CardTeamsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
`;

export const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 220px;
  cursor: pointer;
`;

export const TeamImg = styled.img`
  width: 180px;
  height: 100%;
`;

export const TeamName = styled.p`
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--text-color);
  margin-top: 0.5rem;
`;

export const TeamMembers = styled.p`
  font-size: var(--font-size-small);
  font-weight: 300;
  color: var(--text-color);
  margin-top: 0rem;
`;

export const NewTeamLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 3px dotted var(--layout-color);
  border-radius: 8px;
  width: 180px;
  height: 180px;

  color: var(--layout-color);
  text-decoration: none;
  font-size: var(--font-size-base);
`;
