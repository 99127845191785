import styled from "styled-components";

import { device } from "../../../../utils";
import Carousel from "react-multi-carousel";

export const Container = styled.div`
  margin: 2rem 0;
`;

export const ProfileWrapper = styled.div`
  display: flex;

  width: 100%;
  gap: 5rem;

  @media (max-width: 1060px) {
    flex-direction: column-reverse;
    gap: 0;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StatisticsWrapper = styled.div`
  display: flex;
  gap: 0.8rem;

  border-radius: 7px;
  margin-bottom: 2rem;
  padding: 1.5rem;

  opacity: 1;
  background: var(--containers-background-color) 0% 0% no-repeat padding-box;

  @media ${device.tablet} {
    flex-direction: column;
    text-align: center;
  }

  @media (max-width: 1305px) {
    gap: 3rem;
  }
`;

export const StatisticsItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const StatisticsImage = styled.img`
  width: 40px;
  height: 40px;
`;

export const StatisticsText = styled.p`
  color: var(--text-color);
  font-size: var(--font-size-large);
  font-weight: 500;
  width: 200px;

  @media (max-width: 1305px) {
    width: auto;
  }
`;

export const StatisticsDescription = styled.p`
  color: var(--text-color);
  font-size: var(--font-size-small);
  font-weight: 300;
`;

export const StatisticsTextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Title = styled.p`
  display: flex;
  align-items: center;

  gap: 0.5rem;
  margin-bottom: 0.8rem;

  color: var(--text-color);
  font-size: var(--font-size-large);
  font-weight: 700;
`;

export const AboutMeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  background: var(--containers-background-color) 0% 0% no-repeat padding-box;
  border-radius: 1rem;

  margin-bottom: 2rem;
  padding: 1rem 2rem;

  @media (max-width: 1060px) {
    width: 400px;
  }
  @media (max-width: 550px) {
    width: 300px;
  }
  @media ${device.mobileLarge} {
    width: 100%;
  }
`;

export const MobileCarouselWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  margin-bottom: 2rem;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const MobileVideosWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const EditProfileImg = styled.img`
  padding-left: 1rem;
`;

export const VideosWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: 1320px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1060px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;

export const NewVideoLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 120px;
  border: 3px dotted var(--layout-color);
  border-radius: 8px;

  color: var(--layout-color);
  text-decoration: none;
  font-size: var(--font-size-large);
`;

export const CardCarouselWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

export const ContainerCarousel = styled(Carousel)`
  .react-multi-carousel-track {
    display: flex;
    gap: 20px;
  }
`;
