import { IRegulation } from "@interfaces/regulations.interface";
import DOMPurify from "dompurify";
import ShadowRoot from "react-shadow";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";

type Props = {
  regulation: IRegulation;
};

const Regulation: React.FC<Props> = ({ regulation }) => {
  const sanitizedContent = DOMPurify.sanitize(regulation?.description);
  return (
    <Flex flexDir="column" gap="60px">
      <Text align="center" fontWeight="400" fontSize="1.6rem" color="#ffffff">
        {regulation?.name}
      </Text>
      <Accordion allowToggle w="100%" gap="10px" reduceMotion={false}>
        <AccordionItem
          border="1px solid #e94560"
          borderRadius="8px"
          bg="#102131"
        >
          <Text fontSize="35px" color="#ffffff">
            <AccordionButton
              borderRadius="8px"
              _hover={{ backgroundColor: "#e94560" }}
              _expanded={{ backgroundColor: "#e94560" }}
            >
              <Box
                as="span"
                flex="1"
                textAlign="left"
                fontWeight="400"
                fontSize="1.1rem"
              >
                Detalhes do Regulamento
              </Box>
              <AccordionIcon fontSize="2rem" />
            </AccordionButton>
          </Text>
          <AccordionPanel
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          ></AccordionPanel>
        </AccordionItem>
        {regulation?.regulation_section?.map((item) => {
          const sanitizedContent = DOMPurify.sanitize(item.description);
          return (
            <AccordionItem
              id={item.id}
              border="1px solid #e94560"
              borderRadius="8px"
              marginTop="1.3rem"
              bg="#102131"
            >
              <Text fontSize="35px" color="#ffffff">
                <AccordionButton
                  borderRadius="8px"
                  _hover={{ backgroundColor: "#e94560" }}
                  _expanded={{ backgroundColor: "#e94560" }}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="400"
                    fontSize="1.1rem"
                  >
                    {item.order}.{item.name}
                  </Box>
                  <AccordionIcon fontSize="2rem" />
                </AccordionButton>
              </Text>
              <AccordionPanel padding="15px 30px">
                <ShadowRoot.div
                  className="ScrollBar"
                  style={{ overflowY: "auto", maxHeight: "460px" }}
                >
                  <Box
                    dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                  ></Box>
                </ShadowRoot.div>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Flex>
  );
};

export default Regulation;
