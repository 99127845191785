import styled from "styled-components";
import { Tabs as ReactTabs } from "react-tabs";
import { device } from "@utils/index";

const BannerImg = styled.img`
  width: 99vw;
  max-width: 99vw;
  height: 430px;
  margin-bottom: -6rem;

  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );

  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

  @media ${device.tablet} {
    height: 280px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`;

export { TabsContainer, BannerImg };
