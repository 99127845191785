import { useQuery } from "@tanstack/react-query";
import { FileBussinessType } from "@interfaces/file.interface";
import { optionsFetchUserByIdPublic } from "@plugins/reactQuery/user.query-options";

const useUserPublic = (userId: string) => {
  const { data: user } = useQuery(optionsFetchUserByIdPublic(userId!));

  const files = user?.files || [];
  const teams = user?.entity.teams || [];

  const avatarFile = files.find(
    (item) => item.business_type === FileBussinessType.USER_PROFILE_PHOTO
  );

  const bannerFile = files.find(
    (item) => item.business_type === FileBussinessType.USER_PROFILE_BANNER
  );

  return {
    user: user?.entity,
    teams,
    files,
    avatarFile,
    bannerFile,
  };
};
export default useUserPublic;
