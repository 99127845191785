import { device } from "@utils/index";
import styled from "styled-components";

export const RodapeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktopSmall} {
    align-items: center;

    padding: 5rem 0;
  }
`;

export const RodapeMainContent = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-start;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const RodapeLinkMenusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;

  > div {
    width: 200px;
  }

  @media ${device.desktopSmall} {
    flex-direction: column;
  }
`;

export const RodapeCopyrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 2rem 0;
`;

export const RodapeCopyright = styled.p`
  font-size: 10pt;
  text-align: center;
  color: var(--text-color);
  text-decoration: none;
`;
