import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoginSchema } from "@validations/auth.schema";
import Layout from "@components/template/layout";
import useAuth from "@hooks/useAuth";
import * as S from "./styles";
import { ModalResetPassword } from "@components/modalResetPassword";
import { GoogleLogin } from "@react-oauth/google";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { loginSocialSteam, normalLogin, loginSocialGoogle, isAuthenticated } =
    useAuth();

  if (isAuthenticated) {
    navigate("/");
  }

  useEffect(() => {
    const { steamId } = location.state || {};
    if (steamId === undefined) return;

    loginSocialSteam(steamId);
  }, [location]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const {
    register,
    handleSubmit: handleSubmitCreate,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  return (
    <Layout>
      <S.FormContainer>
        <S.Title>Entrar</S.Title>
        <GoogleLogin
          size="large"
          theme="outline"
          onSuccess={(response) => loginSocialGoogle(response.credential!)}
        />
        {/* <S.GoogleButton onClick={() => loginSocialGoogle()}>
          <GoogleSVG />
          Login com o Google
        </S.GoogleButton> */}
        {/* <FacebookLogin
          appId={appFacebookKey}
          autoLoad={false}
          fields="name,email,picture"
          callback={loginSocialFacebook}
          render={(renderProps) => (
            <S.FacebookButton onClick={renderProps.onClick}>
              <S.FacebookIcon
                aria-hidden="true"
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path d="M22.675 0H1.325C.594 0 0 .594 0 1.325v21.35C0 23.406.594 24 1.325 24h11.52V14.706H9.558v-3.645h3.287V8.309c0-3.256 1.987-5.024 4.89-5.024 1.39 0 2.584.103 2.934.15v3.406l-2.014.001c-1.576 0-1.881.749-1.881 1.846v2.418h3.763l-.491 3.645h-3.272V24h6.414c.73 0 1.325-.594 1.325-1.325V1.325C24 .594 23.406 0 22.675 0z" />
              </S.FacebookIcon>
              Login com Facebook
            </S.FacebookButton>
          )}
        /> */}
        {/* <S.SteamButton onClick={handleLoginSteam}>
          <S.SteamIcon src={Steam.toString()} alt="Steam" />
          <S.SteamTitle>Entrar com Steam</S.SteamTitle>
          <MdKeyboardArrowRight size={42} />
        </S.SteamButton> */}
        <S.LoginForm onSubmit={handleSubmitCreate(normalLogin)}>
          <S.FormLabel htmlFor="loginEmail">E-mail</S.FormLabel>
          <S.FormInput
            id="loginEmail"
            type="email"
            placeholder="Seu e-mail"
            {...register("email")}
          />
          {isSubmitted && errors.email && (
            <S.FormError>{errors.email.message}</S.FormError>
          )}

          <S.FormLabel htmlFor="loginPassword">Senha</S.FormLabel>
          <S.FormInput
            id="loginPassword"
            type="password"
            placeholder="Sua senha"
            {...register("password")}
          />
          {isSubmitted && errors.password && (
            <S.FormError>{errors.password.message}</S.FormError>
          )}

          <S.PrimaryAction>Entrar</S.PrimaryAction>
        </S.LoginForm>
        <S.ForgotPassword onClick={openModal}>
          Esqueceu sua senha?
        </S.ForgotPassword>
        <ModalResetPassword isOpen={modalIsOpen} onRequestClose={closeModal} />
        <S.RegisterLink>
          Não tem uma conta?{" "}
          <S.RegisterSpan href="/register">Crie uma agora!</S.RegisterSpan>
        </S.RegisterLink>
      </S.FormContainer>
    </Layout>
  );
};

export default Login;
