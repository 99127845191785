import React from "react";
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  IRoundProps,
  IRenderSeedProps,
} from "@sportsgram/brackets";
import { dateFormat2 } from "@utils/date";

interface LosingProps {
  rounds: IRoundProps[];
}

const WiningBracket: React.FC<LosingProps> = ({ rounds: wining }) => {
  const RenderSeed = ({ seed, breakpoint }: IRenderSeedProps) => {
    return (
      <Seed
        style={
          {
            // opacity: seed.bye_match ? 0.5 : 1,
            // background: "red",
          }
        }
        className="custom-border"
        mobileBreakpoint={breakpoint}
      >
        <p>{dateFormat2(seed.date || "")}</p>
        <SeedItem
          style={{
            width: "315px",
            background: "none",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <SeedTeam
            style={{
              height: "52px",
              backgroundColor: "#162230",
              padding: "0px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0.3rem 0rem 0.3rem 0.5rem",
                flex: "1",
              }}
            >
              <img
                src={seed.teams?.[0]?.imageUrl}
                alt=""
                style={{ width: "45px", height: "45px", borderRadius: "150px" }}
              />
              <span
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "200px",
                }}
              >
                {seed.teams?.[0]?.name || "TBD"}
              </span>
            </div>
            <div
              style={{
                width: "63px",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "14px",

                background: `linear-gradient(57deg, #162230 34%, ${seed.teams?.[0]?.result === "WIN" ? "#3981E6" : "#152D43"} 32%)`,
              }}
            >
              <span style={{ fontSize: "20px" }}>
                {seed.teams?.[0]?.score || "0"}
              </span>
            </div>
          </SeedTeam>
          <SeedTeam
            style={{
              height: "52px",
              backgroundColor: "#162230",
              padding: "0px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0.3rem 0rem 0.3rem 0.5rem",
                flex: "1",
              }}
            >
              <img
                src={seed.teams?.[1]?.imageUrl}
                alt=""
                style={{ width: "45px", height: "45px", borderRadius: "150px" }}
              />
              <span
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "200px",
                }}
              >
                {seed.teams?.[1]?.name || "TBD"}
              </span>
            </div>
            <div
              style={{
                width: "63px",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "14px",
                background: `linear-gradient(123deg, #162230 34%, ${seed.teams?.[1]?.result === "WIN" ? "#3981E6" : "#152D43"} 32%)`,
              }}
            >
              <span style={{ fontSize: "20px" }}>
                {seed.teams?.[1]?.score || "0"}
              </span>
            </div>
          </SeedTeam>
        </SeedItem>
      </Seed>
    );
  };

  return (
    <>
      <h1
        style={{ fontSize: "2rem", fontWeight: "bold", marginBottom: "15px" }}
      >
        Winner
      </h1>
      <Bracket
        rounds={wining}
        renderSeedComponent={RenderSeed}
        roundTitleComponent={(title: React.ReactNode, roundIndex: number) => {
          return (
            <div
              style={{
                textAlign: "center",
                color: "#ffffff",
                padding: "18px",
                backgroundColor: "#162230",
                marginRight: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>{title}</p>
            </div>
          );
        }}
        swipeableProps={{
          enableMouseEvents: true,
          animateHeight: true,
          style: {
            padding: "0 50px 0 0",
          },
        }}
      />
    </>
  );
};

export default WiningBracket;
