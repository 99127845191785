import Layout from "@components/template/layout";
import InputMask from "react-input-mask";
import useAuth from "@hooks/useAuth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { ModalTermsOfUse } from "@components/modalTermsOfUse";
import { Genders } from "@interfaces/user.interface";
import { IRegister } from "@interfaces/auth.interface";
import { RegistrationScheme } from "@validations/auth.schema";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";

const Register: React.FC = () => {
  const [modalIsOpenTermsUse, setModalIsOpenTermsUse] = useState(false);
  const [modalIsOpenPrivacyPolicyTerms, setModalIsOpenPrivacyPolicyTerms] =
    useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { submiteRegister, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleCheckboxChange = () => setIsChecked(!isChecked);
  const handleCheckboxTrue = () => setIsChecked(true);
  const handleCheckboxFalse = () => setIsChecked(false);
  const closeModal = () => {
    setModalIsOpenTermsUse(false);
    setModalIsOpenPrivacyPolicyTerms(false);
  };

  if (isAuthenticated) {
    navigate("/");
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<IRegister>({
    resolver: yupResolver(RegistrationScheme),
  });

  return (
    <Layout
      styleContainer={{ overflow: "visible" }}
      styleBodyContainer={{ overflow: "visible" }}
    >
      <S.LineTop />
      <S.RegisterFormContainer>
        <S.FormContainer>
          <S.Title>Crie sua conta!</S.Title>
          <S.RegisterForm onSubmit={handleSubmit(submiteRegister)}>
            <S.FormLabel htmlFor="registerName">Nome</S.FormLabel>
            <S.FormInput
              id="registerName"
              type="text"
              placeholder="Seu nome"
              {...register("name")}
            />
            {isSubmitted && errors.name && (
              <S.FormError>{errors.name.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="registerNick">Nick</S.FormLabel>
            <S.FormInput
              id="registerNick"
              type="text"
              placeholder="Seu apelido"
              {...register("nick")}
            />
            {isSubmitted && errors.nick && (
              <S.FormError>{errors.nick.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="registerCPF">CPF</S.FormLabel>
            <InputMask
              mask="999.999.999-99"
              id="registerCPF"
              type="text"
              placeholder="Seu CPF"
              {...register("cpf")}
            >
              {(inputProps) => <S.FormInput {...inputProps} />}
            </InputMask>
            {isSubmitted && errors.cpf && (
              <S.FormError>{errors.cpf.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="registerPhone">Telefone</S.FormLabel>
            <InputMask
              mask="(99) 99999-9999"
              id="registerPhone"
              type="text"
              placeholder="Seu número"
              {...register("phone")}
            >
              {(inputProps) => <S.FormInput {...inputProps} />}
            </InputMask>
            {isSubmitted && errors.phone && (
              <S.FormError>{errors.phone.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="registerEmail">E-mail</S.FormLabel>
            <S.FormInput
              id="registerEmail"
              type="text"
              placeholder="Seu email"
              {...register("email")}
            />
            {isSubmitted && errors.email && (
              <S.FormError>{errors.email.message}</S.FormError>
            )}
            <S.FormLabel htmlFor="registerGender">Gênero</S.FormLabel>
            <div className="inputFormSelect">
              <S.FormSelect id="registerGender" {...register("gender")}>
                {isSubmitted && errors.gender && (
                  <S.FormError>{errors.gender.message}</S.FormError>
                )}
                <option
                  hidden
                  value=""
                  style={{ color: "var(--placeholder-form-color)" }}
                >
                  Escolha seu gênero
                </option>
                <option value={Genders.MALE}>Masculino</option>
                <option value={Genders.FEMALE}>Feminino</option>
                <option value={Genders.OTHER}>Outro</option>
                <option value={Genders.NOT_INFORMED}>Não Informar</option>
              </S.FormSelect>
            </div>
            <S.FormLabel htmlFor="registerPassword">Senha</S.FormLabel>
            <S.FormInput
              id="registerPassword"
              type="password"
              placeholder="Sua senha"
              {...register("password")}
            />
            {isSubmitted &&
              errors.password &&
              errors.password
                .message!.split("\n")
                .map((item: string, index: number) => (
                  <S.FormError key={index} style={{ padding: "8px 0px" }}>
                    {item}
                  </S.FormError>
                ))}

            <S.FormLabel htmlFor="registerPasswordConfirm">
              Confirmar senha
            </S.FormLabel>
            <S.FormInput
              id="registerPasswordConfirm"
              type="password"
              placeholder="Confirme sua senha"
              {...register("passwordConfirm")}
            />
            {isSubmitted && errors.passwordConfirm && (
              <S.FormError>{errors.passwordConfirm.message}</S.FormError>
            )}
            <input
              type="hidden"
              {...register("provider", { value: "NORMAL" })}
            />
            <S.RegisterCheckboxWrapper>
              <S.RegisterInputCheckbox
                id="registerAccept"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <S.FormLabel
                htmlFor="registerAccept"
                onClick={(e) => e.preventDefault()}
              >
                Declaro que li e concordo com os
                <S.RegisterCheckboxLabel
                  onClick={() => setModalIsOpenTermsUse(true)}
                >
                  Termos de Uso
                </S.RegisterCheckboxLabel>
                e
                <S.RegisterCheckboxLabel
                  onClick={() => setModalIsOpenPrivacyPolicyTerms(true)}
                >
                  Política de Privacidade
                </S.RegisterCheckboxLabel>
              </S.FormLabel>
              <ModalTermsOfUse
                isCheckedTrue={handleCheckboxTrue}
                isCheckedFalse={handleCheckboxFalse}
                isOpen={modalIsOpenPrivacyPolicyTerms}
                onRequestClose={closeModal}
                type="PrivacyPolicy"
              />
              <ModalTermsOfUse
                isCheckedTrue={handleCheckboxTrue}
                isCheckedFalse={handleCheckboxFalse}
                isOpen={modalIsOpenTermsUse}
                onRequestClose={closeModal}
                type="TermsUse"
              />
            </S.RegisterCheckboxWrapper>
            <S.RegisterLink>
              Já tem uma conta?
              <S.RegisterSpan to="/login">Entrar</S.RegisterSpan>
            </S.RegisterLink>
            <S.PrimaryAction type="submit">Criar conta</S.PrimaryAction>
          </S.RegisterForm>
        </S.FormContainer>
      </S.RegisterFormContainer>
    </Layout>
  );
};

export default Register;
