import styled from "styled-components";

export const QuemSomosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 2rem 0;
`;

export const QuemSomosTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: var(--layout-color);
  margin-bottom: 2rem;
`;

export const QuemSomosContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QuemSomosText = styled.p`
  font-size: 14px;
  text-align: center;
  color: var(--text-color);
  text-decoration: none;
`;

export const QuemSomosAction = styled.a`
  font-size: 14px;
  text-align: center;
  color: var(--text-color);

  padding: 1rem 2rem;
  margin: 1rem 0;
  border: 1px solid var(--text-color);

  cursor: pointer;

  transition: all 0.2s ease-in;

  &:hover {
    transition: all 0.2s ease-in;
    transform: scale(1.02);
  }
`;
