import { progamersApi } from "@config/axios.config";
import { IRegisterRequest, ILoginResponse, IResetPasswordRequest, IResetPassword } from "@interfaces/auth.interface";
import { ILoginSocial, INormalLogin } from "@interfaces/auth.interface";

export default function authService() {
  const postLoginSocial = async (
    data: ILoginSocial
  ): Promise<ILoginResponse> => {
    const result = await progamersApi.post("/auth/social-login", data);
    return result.data as ILoginResponse;
  };

  const postLogin = async (dataSend: INormalLogin): Promise<ILoginResponse> => {
    const result = await progamersApi.post("/auth/login", dataSend);
    return result.data as ILoginResponse;
  };

  const postRegister = async (
    data: IRegisterRequest
  ): Promise<ILoginResponse> => {
    const result = await progamersApi.post("/auth/register", data);
    return result.data as ILoginResponse;
  };

  const postChangePassword = async (
    data: IResetPasswordRequest
  ): Promise<void> => {
    await progamersApi.post("/auth/change-password", data);
  };

  const postResetPassword = async (data: IResetPassword): Promise<void> => {
    await progamersApi.post("/auth/reset-password", data);
  };

  return { postLoginSocial, postLogin, postRegister, postChangePassword, postResetPassword};
}
