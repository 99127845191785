import {
  ParceirosContainer,
  LogoParceiros,
  ParceirosTitle,
  LogoContainer,
  StyledLink,
  ParceirosSubtitle,
} from "./styles";
import logooPovo from "@assets/midia/logo_o_povo.png";
import logoreportCeara from "@assets/midia/logo-reoirter-ceara.webp";
import logoInvestCeara from "@assets/midia/logo-retina.png";

const AreaMidia = () => {
  const urlOpovo =
    "https://mais.opovo.com.br/colunistas/wanderson-trindade/2024/11/01/liga-cearense-de-games-tem-premios-de-rs-20-mil-em-cs2-valorant-lol-e-outros.html";
  const urlReportCeara =
    "https://reporterceara.com.br/2024/11/05/liga-cearense-de-games-organizada-pela-pro-gamers-startup-investida-pelo-instituto-atlantico-vai-movimentar-os-competidores-no-riomar/";
  const urlInvestCeara =
    "https://investece.com.br/liga-cearense-de-games-realiza-evento-gratuito-com-espaco-de-games-e-arenas-de-competicoes-no-riomar-fortaleza-neste-fim-de-semana/";

  return (
    <ParceirosContainer>
      <ParceirosTitle>
        Pro Gamers na <span className="highlight">mídia!</span>
      </ParceirosTitle>
      <ParceirosSubtitle>
        Vejam o que estão falando da gente nas redes de nossos eventos.
      </ParceirosSubtitle>
      <LogoContainer>
        <StyledLink href={urlOpovo} target="_blank" rel="noopener noreferrer">
          <LogoParceiros src={logooPovo} />
          <p>
            Liga Cearense de Games, organizada pela Pro Gamers, vai movimentar
            os competidores no RioMar
          </p>
        </StyledLink>
        <StyledLink
          href={urlReportCeara}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoParceiros src={logoreportCeara} />
          <p>
            Liga Cearense de Games realiza evento gratuito com espaço de games e
            arenas de competições no RioMar Fortaleza neste fim de semana
          </p>
        </StyledLink>
        <StyledLink
          href={urlInvestCeara}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoParceiros src={logoInvestCeara} />
          <p>
            Liga Cearense de Games tem prêmio de R$ 20 mil em CS2, Valorant, LoL
            e outros
          </p>
        </StyledLink>
      </LogoContainer>
    </ParceirosContainer>
  );
};

export default AreaMidia;
