import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IUserContext } from "@interfaces/auth.interface";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import userService from "@services/user.service";
import { QUERY_KEY_USER } from "../constants/queryKeys.constants";
import { useDisclosure } from "@chakra-ui/react";

export const UserContext = createContext({} as IUserContext);

type UserContextProviderProps = {
  children: ReactNode;
};

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();
  const { getMe } = userService();

  const [userToken, setUserToken] = useState<string | null>(
    localStorage.getItem("programers.token")
  );

  const { data: user, isLoading: isLoadingAuth } = useQuery({
    queryKey: [QUERY_KEY_USER.PROFILE],
    queryFn: getMe,
    enabled: !!userToken,
  });

  const isAuthenticated = !!user;

  const updateToken = useCallback(
    (token: string | null) => {
      setUserToken(token);
      localStorage.setItem("programers.token", token || "");
      if (!token) {
        queryClient.removeQueries({ queryKey: [QUERY_KEY_USER.PROFILE] });
      }
    },
    [queryClient]
  );

  useEffect(() => {
    const token = localStorage.getItem("programers.token");
    if (token && token !== userToken) {
      updateToken(token);
    }
  }, [updateToken, userToken]);

  return (
    <UserContext.Provider
      value={{
        user: user?.entity,
        files: user?.files || [],
        isAuthenticated,
        isLoadingAuth,
        isOpenUserProfileFinishingModal: isOpen,
        onCloseUserProfileFinishingModal: onClose,
        onOpenUserProfileFinishingModal: onOpen,
        updateToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
