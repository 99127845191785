import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import ImageCropper from "@components/imageCropper";
import ImageHover from "@components/imageHover";
import Modal from "@components/template/modal";
import plusIcons from "@assets/icons/Plus Math.png";
import {
  FileBussinessType,
  FileEntityType,
  IFile,
} from "@interfaces/file.interface";
import { optionsFetchSetupUserByIdPublic } from "@plugins/reactQuery/user.query-options";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import useUser from "@hooks/useUser";
import useUserPublic from "@hooks/useUserPublic";

type Props = {
  entityId: string;
  files: IFile[];
};

const Gallery: React.FC<Props> = ({ entityId, files }) => {
  const { userId } = useParams();
  const queryClient = useQueryClient();
  const modalPhoto = useDisclosure();
  const [imageUpload, setImageUpload] = useState(0);
  const { user } = useUserPublic(userId!);
  const { isAuthenticated } = useAuth();
  const { userAuth } = useUser();

  const fileList =
    files?.length >= 5
      ? files.slice(0, 5)
      : files?.concat(Array(5 - files.length).fill(null));

  const openModalUpdate = (index: number) => {
    setImageUpload(index);
    modalPhoto.onOpen();
  };

  const closeModal = () => {
    queryClient.invalidateQueries(optionsFetchSetupUserByIdPublic(userId!));
    modalPhoto.onClose();
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap={"40px"}
        flex={"1"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Flex
          justifyContent={"center"}
          flexDir={"column"}
          alignContent={"center"}
        >
          <Text fontSize={"2rem"} align={"center"} fontWeight={"bold"}>
            Galeria
          </Text>
          <Text
            color={"#687480"}
            align={"center"}
            fontWeight={"bold"}
            fontSize={"1.2rem"}
          >
            Adicione até 5 fotos do seu setup(Desktop)
          </Text>
        </Flex>
        <Flex
          justifyContent={"center"}
          flexDir={{ base: "column", lg: "row" }}
          flexWrap={"wrap"}
          gap={"30px"}
        >
          {fileList === undefined && (
            <Text fontSize={"2rem"} color={"#e94560"}>
              Cadastrei um Setup Primeiro.
            </Text>
          )}
          {fileList?.map((item, index) => (
            <ImageHover
              key={item?.id}
              altImage={`user_galery_photo_${index}`}
              imgPath={item?.url || plusIcons}
              handlerClick={() => openModalUpdate(index)}
              hoverText="Adicionar Imagem"
              isEdit={isAuthenticated && user?.id === userAuth?.id}
              styleContainer={{
                width: "350px",
                height: "260px",
                backgroundColor: "#D9D9D9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              styleImage={{
                width: "auto",
                height: "auto",
                objectFit: "contain",
              }}
            />
          ))}
        </Flex>
      </Flex>
      <Modal
        title="Foto do Setup"
        isOpen={modalPhoto.isOpen}
        handlerCancel={modalPhoto.onClose}
        isFooter={false}
        size="6xl"
      >
        <ImageCropper
          handlerCompleted={closeModal}
          crop={{ height: 800, width: 1280, isCircularCrop: false }}
          file={{
            id: files?.[imageUpload]?.id,
            url: files?.[imageUpload]?.url || "",
            entity_id: entityId,
            business_type: FileBussinessType.SETUP_PHOTO,
            entity_type: FileEntityType.SETUP,
          }}
        />
      </Modal>
    </>
  );
};

export default Gallery;
