import { useState } from "react";
import { TbUsers } from "react-icons/tb";
import { FaCrown } from "react-icons/fa";
import {
  Avatar,
  Button,
  Flex,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type TeamsModal = {
  id: string;
  name: string;
  imgPath: string;
  totalMembers: number;
  isOwner: boolean;
  isSelect: boolean;
};

type ChampionshipModal = {
  name: string;
  participantLimit: number;
};

type Props = {
  isOpen: boolean;
  teams: TeamsModal[];
  championship: ChampionshipModal;
  handlerClose: () => void;
  handlerClick: (teamId: string) => void;
};

const RegistrationModal: React.FC<Props> = ({
  isOpen,
  teams,
  championship,
  handlerClose,
  handlerClick,
}) => {
  if (!isOpen) return null;

  const [teamList, setTeamList] = useState<TeamsModal[]>(teams);
  const navegate = useNavigate();

  const selectTeam = (teamId: string) => {
    setTeamList((state) => {
      return state.map((item) => {
        return item.id === teamId
          ? { ...item, isSelect: true }
          : { ...item, isSelect: false };
      });
    });
  };

  const handleCreateNewTeam = () => {
    navegate("/team/create");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handlerClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg="#0C1A27CF" />
      <ModalContent border="1px solid #E94560" bg="#010101F0" color="#ffffff">
        <ModalHeader>FAÇA SUA INSCRIÇÃO</ModalHeader>
        <ModalCloseButton />
        {/* MODO EQUIPE => CASO ESTEJA LOGADO E NAO TEM NENHUM TIME */}
        {/* {myTeams.length === 0 && B()} */}
        {/* MODO EQUIPE => CASO ESTEJA LOGADO E NAO E OWNER DE NENHUM */}
        {/* {myTeams.length > 0 && T(teamSelect)} */}
        <ModalBody>
          <Text>Clique na equipe que deseja selecionar:</Text>
          <List spacing={3}>
            {teamList.map((item) => {
              console.log({ item });
              return (
                <ListItem
                  key={item.id}
                  display="flex"
                  gap="28px"
                  h="80px"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => selectTeam(item.id)}
                  _hover={{ backgroundColor: "#6340FF" }}
                  bg={item.isSelect ? "#6340FF" : ""}
                >
                  <Avatar name={item.name} />
                  <Flex flexDirection="column">
                    <Text>{item.name}</Text>
                    <Flex gap="2" alignItems="center">
                      <TbUsers color="#E94560" size="22" />
                      <Text>
                        {item.totalMembers}/{championship.participantLimit}
                      </Text>
                      {item.isOwner && <FaCrown color="#E94560" size="20" />}
                    </Flex>
                  </Flex>
                </ListItem>
              );
            })}
          </List>
          <ModalFooter display="flex" flexDirection="column">
            <Link
              borderRadius="5px"
              w="220px"
              h="55px"
              color="#ffffff"
              textAlign="center"
              onClick={handleCreateNewTeam}
            >
              CRIAR NOVO TIME
            </Link>
            <Button
              bg= {teamList.find((item) => item.isSelect)?.id ? "#E94560":"#5C5A68F2"}
              borderRadius="5px"
              w="220px"
              h="55px"
              color="#ffffff"
              onClick={() =>
                handlerClick(teamList.find((item) => item.isSelect)?.id!)
              }
              disabled={teamList.find((item) => item.isSelect)?.id ? false : true}
              _hover={{ backgroundColor: teamList.find((item) => item.isSelect)?.id ? "#E94560":"#5C5A68F2"}}
            >
              ENVIAR INSCRIÇÃO
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RegistrationModal;
