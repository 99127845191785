import { AuthenticatedUserDisplay } from "@components/authenticatedUserDisplay";
import * as S from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import useUserPublic from "@hooks/useUserPublic";
import { FileBussinessType } from "@interfaces/file.interface";
import useUser from "@hooks/useUser";

const MyTeams: React.FC = () => {
  const { userId } = useParams();
  const { teams, user } = useUserPublic(userId!);
  const { userAuth } = useUser();

  const navigate = useNavigate();

  return (
    <S.Container>
      <S.Title>Times que participo</S.Title>
      <S.CardTeamsWrapper>
        <AuthenticatedUserDisplay.True rule={userAuth?.id === user?.id}>
          <S.NewTeamLink to="/team/create">Criar novo time</S.NewTeamLink>
        </AuthenticatedUserDisplay.True>
        {teams?.map((team) => {
          const teamFile = team.files.find(
            (item) =>
              item.business_type === FileBussinessType.TEAM_PROFILE_PHOTO
          );

          return (
            <S.TeamWrapper
              key={`team-${team.entity.id}`}
              onClick={() => navigate(`/team/${team.entity.id}`)}
            >
              <S.TeamImg
                src={
                  teamFile
                    ? teamFile.url
                    : "https://admin.progamers.com.br/assets/img/ic_no_banner.jpg"
                }
              />
              <S.TeamName>{team.entity.name}</S.TeamName>
              <S.TeamMembers>
                {team.entity.team_members_count} membros
              </S.TeamMembers>
            </S.TeamWrapper>
          );
        })}
      </S.CardTeamsWrapper>
    </S.Container>
  );
};

export default MyTeams;
