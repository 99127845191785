import AcessoRapido from "./acessoRapido";
import Contato from "./contato";
import Logo from "./logo";
import QuemSomos from "./quemSomos";
import Suporte from "./suporte";

import {
  RodapeContainer,
  RodapeMainContent,
  RodapeLinkMenusContainer,
  RodapeCopyrightContainer,
  RodapeCopyright,
} from "./styles";

function Rodape() {
  return (
    <RodapeContainer>
      <RodapeMainContent>
        <RodapeLinkMenusContainer>
          <Logo />
          <Contato />
          <Suporte />
          <AcessoRapido />
          <QuemSomos />
        </RodapeLinkMenusContainer>
      </RodapeMainContent>

      <RodapeCopyrightContainer>
        <RodapeCopyright>
          © All Rights Reserved | Designed By ProGamers
        </RodapeCopyright>
      </RodapeCopyrightContainer>
    </RodapeContainer>
  );
}

export default Rodape;
