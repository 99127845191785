import Providers from "@components/template/Providers";
import Toast from "./components/toast";
import AppRoutes from "./routes";

export default function App() {
  return (
    <Providers>
      <Toast />
      <AppRoutes />
    </Providers>
  );
}
